import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import styles from "./Orders.module.scss";
import { useEffect, useState } from "react";

import {
  formatPrice,
  getClientTastingLabel,
  getDefaultColour,
  modifyHomeFiltersForDate,
} from "utils/utils";
import { useTranslation } from "react-i18next";
import {
  deleteAddedWineBegin,
  getAddedWineProductDetailsBegin,
  getJournalDataBegin,
  requestToSupplyWineBegin,
} from "../actions";
import {
  DeleteIcon,
  ExpandIcon,
  FifthRateWineEmoji,
  FirstRateWineEmoji,
  FourthRateWineEmoji,
  SecondRateWineEmoji,
  ShoppingCartAddIcon,
  ThirdRateWineEmoji,
  ValueForMoneyIlustration,
} from "assets/icons/icons";
import dayjs from "dayjs";
import { openToaster, scrollToTop } from "App/actions";
import ProductDetails from "pages/Purchasing/ProductDetails/ProductDetails.page";
import {
  fetchProductDetailsBegin,
  setCartItems,
} from "pages/Purchasing/actions";
import RateAndTaste from "./RateAndTastePage/RateAndTastePage.page";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FETCH_PRODUCT_DETAILS_SUCCESS } from "pages/Purchasing/constants";
import PurchasedQuantity from "./PurchasedQuantity/PurchasedQuantity.page";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import MobileMyWineOrders from "./MobileMyOrders/MobileMyOrders.page";
import WineBluredImage from "assets/images/wine.png";
import LoaderWrapper from "components/Loader/LoaderWrapper/LoaderWrapper";
import NoVinfinityWines from "./NoTastePreferencesItems/NoTastePreferencesItems.page";
import StorageService from "services/storage.service";
import AddedWineProductDetails from "../AddedWineProductDetails/AddedWineProductDetails.page";
import { Box, Modal } from "@mui/material";
import EvaluateProduct from "./EvaluateProduct/EvaluateProduct.page";

function Orders() {
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const {
    action: { isLoading },
    journalWines,
    filters,
  } = useAppSelector((state: GlobalIState) => state.PersonalLedgerReducer);
  const {
    action: { actionName },
    productDetails,
  } = useAppSelector((state: GlobalIState) => state.PurchasingReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isMobile = useCheckMobileScreen();
  const [searchParams] = useSearchParams();
  const { t: translations } = useTranslation();
  const [openedCard, setOpenedCard] = useState({
    isExpanded: false,
    item: null,
    category: "",
  });
  const [showProductDetails, setShowProductDetails] = useState({
    id: null,
    show: false,
    isAddedWine: false,
  });
  const [shouldAddToCart, setShouldAddToCart] = useState(false);
  const [data, setData] = useState([]);
  let activeTab = searchParams.get("tab");
  const [isVFMPopupOpen, setIsVFMPopupOpen] = useState(false);
  const [evaluateWineId, setEvaluateWineId] = useState<number>(null);
  const [item, setItem] = useState(null);

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#fff",
    boxShadow: 24,
    outline: "none",
    borderRadius: 12,
  };

  const handleVFMPopup = (item) => {
    setEvaluateWineId(item?.product?.id);
    setItem(item);
    setIsVFMPopupOpen(!isVFMPopupOpen);
  };

  const journalSearchCookie = () =>
    StorageService.getDataByKey("journalSearch");

  const getLanguage = () => {
    return localStorage.getItem("lang");
  };

  const getSelectedRating = (item) => {
    if (item?.product?.ratings?.length !== 0) {
      switch (item?.product?.ratings[0].rating) {
        case 1:
          return <FirstRateWineEmoji />;
        case 2:
          return <SecondRateWineEmoji />;
        case 3:
          return <ThirdRateWineEmoji />;
        case 4:
          return <FourthRateWineEmoji />;
        case 5:
          return <FifthRateWineEmoji />;
        default:
          break;
      }
    }
  };

  const handleProposeToSupply = (id) => {
    dispatch(requestToSupplyWineBegin(id));
  };

  const getAngle = (selected: number) => {
    switch (selected) {
      case 1:
        return -80;
        break;
      case 2:
        return -40;
        break;
      case 3:
        return 0;
        break;
      case 4:
        return 40;
        break;
      case 5:
        return 80;
        break;
      default:
        return 0;
        break;
    }
  };

  const rowItem = (item, index) => {
    let itemUpdated = data?.filter(
      (el) => el?.product?.id === item?.product?.id
    )[0];
    const tastingLabels = getClientTastingLabel(
      itemUpdated?.product?.clientTastings
    )?.map((label) => translations(label));

    const showRateAndTasteBtn =
      itemUpdated?.product?.ratings?.length === 0 &&
      itemUpdated?.product?.clientTastings?.length === 0;

    return (
      <div
        key={index}
        className={styles.row}
        style={{
          backgroundColor: itemUpdated?.product?.inputted_data
            ? "#97979715"
            : "#fff",
        }}
      >
        <div className={styles.firstCol}>
          <div className={styles.imagePart}>
            <img
              src={
                itemUpdated?.product?.product_image
                  ? itemUpdated?.product?.product_image
                  : WineBluredImage
              }
              style={
                !itemUpdated?.product?.product_image ? { opacity: 0.5 } : {}
              }
            />
          </div>
          <div className={styles.rightPart}>
            <div>
              <div className={styles.title}>
                <span>
                  {itemUpdated?.product?.wine_name?.length > 25
                    ? itemUpdated?.product?.wine_name?.substring(0, 25) + "..."
                    : itemUpdated?.product?.wine_name}
                </span>
              </div>
              <div>
                {item?.product?.inputted_data
                  ? item?.product?.year
                  : item?.product?.vintage}
              </div>
            </div>
            <div>
              <div className={styles.title}>
                {itemUpdated?.product?.bottle_size ? (
                  <span>{translations("label.bottle_size")}</span>
                ) : null}
              </div>
              <div className={styles.bottleSizeLabel}>
                <span>{itemUpdated?.product?.bottle_size}</span>
              </div>
            </div>
          </div>

          {/* {!item?.product?.inputted_data ? ( */}
          <div
            className={styles.expandIcon}
            onClick={() => handleCardExpand(itemUpdated, "product-details")}
          >
            <ExpandIcon />
          </div>
          {/* ) : null} */}
        </div>
        <div className={styles.secondCol}>
          {showRateAndTasteBtn ? (
            <div
              className={styles.noRatingsBox}
              onClick={() => handleCardExpand(itemUpdated, "rate/taste")}
            >
              <span>
                {translations("label.rate") +
                  " & " +
                  translations("label.taste")}
              </span>
            </div>
          ) : (
            <div className={styles.productFeedback}>
              {tastingLabels?.length > 0 ? (
                <div className={styles.headerLabel}>
                  <span>{translations("label.my_tasting")}</span>
                  <div className={styles.tastingLabel}>
                    <span>{tastingLabels?.join(", ")}</span>
                  </div>
                </div>
              ) : null}
              <div className={styles.ratings}>
                {getSelectedRating(itemUpdated) ? (
                  <div className={styles.headerLabel}>
                    <span>{translations("label.my_rating")}</span>
                  </div>
                ) : null}
                <div className={styles.rateEmojis}>
                  <div
                    className={
                      itemUpdated?.product?.ratings[0]?.rating === 1
                        ? styles.activeIcon
                        : styles.disabled
                    }
                  >
                    <FirstRateWineEmoji />
                  </div>
                  <div
                    className={
                      itemUpdated?.product?.ratings[0]?.rating === 2
                        ? styles.activeIcon
                        : styles.disabled
                    }
                  >
                    <SecondRateWineEmoji />
                  </div>
                  <div
                    className={
                      itemUpdated?.product?.ratings[0]?.rating === 3
                        ? styles.activeIcon
                        : styles.disabled
                    }
                  >
                    <ThirdRateWineEmoji />
                  </div>
                  <div
                    className={
                      itemUpdated?.product?.ratings[0]?.rating === 4
                        ? styles.activeIcon
                        : styles.disabled
                    }
                  >
                    <FourthRateWineEmoji />
                  </div>
                  <div
                    className={
                      itemUpdated?.product?.ratings[0]?.rating === 5
                        ? styles.activeIcon
                        : styles.disabled
                    }
                  >
                    <FifthRateWineEmoji />
                  </div>
                </div>
              </div>
            </div>
          )}
          <div
            className={styles.expandIcon}
            onClick={() => handleCardExpand(itemUpdated, "rate/taste")}
          >
            <ExpandIcon />
          </div>
        </div>
        <div className={styles.thirdCol}>
          <div>
            <div className={styles.title}>
              {!item?.flaskSentOn && !item?.product?.inputted_data ? (
                <span>{translations("label.purchased_quantity")}</span>
              ) : null}
              {item?.product?.inputted_data ? (
                <span>
                  {translations("label.bottle_location_and_inventory")}
                </span>
              ) : null}
            </div>
            <div>
              <span>
                {itemUpdated?.product?.inputted_data
                  ? `${itemUpdated?.product?.inputted_data?.location} ${
                      itemUpdated?.product?.inputted_data?.inventory?.toString()
                        ?.length > 0
                        ? (itemUpdated?.product?.inputted_data?.location
                            ?.length > 0
                            ? " | "
                            : "") +
                          itemUpdated?.product?.inputted_data?.inventory
                        : ""
                    }`
                  : itemUpdated?.totalQuantity}
              </span>
            </div>
          </div>
          <div>
            <div className={styles.title}>
              <span>
                {translations(
                  itemUpdated?.flaskSentOn
                    ? "label.flask_sent_on"
                    : itemUpdated?.product?.inputted_data
                    ? "label.bottle_added_on"
                    : "label.last_order"
                )}
              </span>
            </div>
            <div>
              <span>
                {dayjs(
                  itemUpdated?.flaSskSentOn
                    ? itemUpdated?.flaskSentOn
                    : itemUpdated?.product?.inputted_data
                    ? itemUpdated?.product?.created_at
                    : itemUpdated?.lastOrderDate
                ).format("DD.MM.YYYY")}
              </span>
            </div>
          </div>
          {!itemUpdated?.product?.inputted_data ? (
            <div
              className={styles.expandIcon}
              onClick={() => handleCardExpand(itemUpdated, "pqty")}
            >
              <ExpandIcon />
            </div>
          ) : null}
        </div>
        <div className={styles.lastColumn}>
          <div>
            <div className={styles.title}>
              <span>{translations("label.bottle_price")}</span>
            </div>

            <div className={styles.price}>
              {itemUpdated?.product?.old_price ? (
                <>
                  <span className={styles.discount}>
                    CHF {itemUpdated?.product?.old_price}
                  </span>
                  <br />
                </>
              ) : null}
              {itemUpdated?.product?.price ? (
                <span>
                  CHF {formatPrice(itemUpdated?.product?.price?.toString())}
                </span>
              ) : (
                <span>{translations("label.not_known")}</span>
              )}
            </div>
          </div>
          {item?.product?.ratings &&
          item?.product?.ratings?.length !== 0 &&
          item?.product?.ratings[0]?.value_for_money !== 0 ? (
            <div
              className={styles.vfmIlustration}
              onClick={() => handleVFMPopup(item)}
            >
              <ValueForMoneyIlustration
                angle={getAngle(item?.product?.ratings[0]?.value_for_money)}
              />
            </div>
          ) : (
            <div
              className={styles.addPriceValueBtn}
              onClick={() => handleVFMPopup(item)}
            >
              <span>{translations("label.price_value")}</span>
            </div>
          )}

          <div className={styles.actionsContainer}>
            {itemUpdated?.product?.price ? (
              <div
                className={styles.mainBtn}
                onClick={() => handleAddToCart(itemUpdated?.product?.id)}
                style={
                  !itemUpdated?.product?.price ||
                  itemUpdated?.product?.inventory_count === 0
                    ? { pointerEvents: "none", opacity: 0.6 }
                    : {}
                }
              >
                <ShoppingCartAddIcon />
              </div>
            ) : item?.product?.supplyRequest ? (
              <div className={styles.requestedToSupplyLabel}>
                <span>{translations("label.request_received_to_supply")}</span>
              </div>
            ) : (
              <div
                className={styles.mainBtn}
                style={{
                  opacity: item?.product?.supplyRequest ? 0.5 : 1,
                  cursor: item?.product?.supplyRequest ? "default" : "pointer",
                }}
                onClick={() => handleProposeToSupply(itemUpdated?.product?.id)}
              >
                <span>{translations("label.propose_to_supply")}</span>
              </div>
            )}
            {item?.product?.inputted_data ? (
              <div
                className={styles.deleteWineIcon}
                onClick={() => handleDeleteAddedWine(itemUpdated?.product?.id)}
              >
                <DeleteIcon color="#000" />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  const handleAddToCart = (id: number) => {
    setShouldAddToCart(true);
    dispatch(fetchProductDetailsBegin(id, getLanguage()));
  };

  const handleCardExpand = (item, card) => {
    if (card === "product-details") {
      setShowProductDetails({
        ...showProductDetails,
        id: item.product.id,
        show: true,
        isAddedWine: item?.product?.inputted_data ? true : false,
      });
    }

    setOpenedCard({ isExpanded: true, item, category: card });
    dispatch(scrollToTop());
  };

  const handleDeleteAddedWine = (id: number) => {
    dispatch(deleteAddedWineBegin(id));
    setOpenedCard({ isExpanded: false, item: null, category: "null" });
    dispatch(
      getJournalDataBegin({
        search: journalSearchCookie() ? journalSearchCookie() : "",
        filters:
          filters?.filter((item) => item?.value?.length >= 1)?.length === 0
            ? []
            : modifyHomeFiltersForDate(
                filters?.filter((item) => item?.value?.length >= 1)
              )?.filters,
      })
    );
  };

  useEffect(() => {
    setOpenedCard({ isExpanded: false, item: null, category: "null" });
  }, [activeTab]);

  useEffect(() => {
    if (showProductDetails.id) {
      if (showProductDetails?.isAddedWine) {
        dispatch(getAddedWineProductDetailsBegin(showProductDetails?.id));
      } else
        dispatch(
          fetchProductDetailsBegin(showProductDetails.id, getLanguage())
        );
    }

    if (!showProductDetails.show) {
      setOpenedCard({ isExpanded: false, item: null, category: "" });
    }
  }, [showProductDetails]);

  useEffect(() => {
    if (shouldAddToCart && actionName === FETCH_PRODUCT_DETAILS_SUCCESS) {
      dispatch(setCartItems(productDetails));
      dispatch(
        openToaster("SUCCESS", translations("label.item_added_to_cart"))
      );
      setShouldAddToCart(false);
    }
  }, [shouldAddToCart, actionName]);

  useEffect(() => {
    if (journalWines?.length !== 0) {
      setData([...(journalWines ?? [])]);
    } else if (!journalWines) {
      setData(null);
    }

    if (!journalSearchCookie()) {
      StorageService.setKeyValue("journalSearch", "");
    }
  }, [journalWines, journalSearchCookie()]);

  useEffect(() => {
    if (!evaluateWineId) {
      setIsVFMPopupOpen(false);
    }
  }, [evaluateWineId]);

  return isMobile ? (
    <div>
      <MobileMyWineOrders />
    </div>
  ) : (
    <div
      className={styles.wineOrdersWrapper}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      {openedCard?.isExpanded ? (
        <div className={styles.expandedItem}>
          {rowItem(openedCard.item, 1)}
          <div className={styles.biggerContainer}>
            {openedCard.category === "product-details" ? (
              openedCard?.item?.product?.inputted_data ? (
                <AddedWineProductDetails
                  setShowProductDetails={setShowProductDetails}
                />
              ) : (
                <ProductDetails setShowProductDetails={setShowProductDetails} />
              )
            ) : openedCard.category === "rate/taste" ? (
              <RateAndTaste
                setOpenedCard={setOpenedCard}
                product_id={openedCard.item?.product?.id}
              />
            ) : openedCard.category === "pqty" ? (
              <PurchasedQuantity
                setOpenedCard={setOpenedCard}
                product_id={openedCard.item?.product?.id}
              />
            ) : (
              openedCard?.category
            )}
          </div>
        </div>
      ) : null}
      <LoaderWrapper isLoading={isLoading}>
        {!isLoading && data?.length === 0 ? (
          filters?.filter((item) => item?.value?.length >= 1)?.length !== 0 ||
          journalSearchCookie()?.length !== 0 ? (
            <div className={styles.emptyStream}>
              <span>{translations("label.empty_feed")}</span>
            </div>
          ) : (
            <NoVinfinityWines />
          )
        ) : (
          data?.map((item, index) => {
            return rowItem(item, index);
          })
        )}
        <Modal
          open={isVFMPopupOpen}
          onClose={() => setIsVFMPopupOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <EvaluateProduct
              id={item?.product?.id}
              isAddedWine={item?.product?.inputted_data ? true : false}
              value={
                item?.product?.ratings &&
                item?.product?.ratings?.length !== 0 &&
                item?.product?.ratings[0]?.value_for_money
                  ? item?.product?.ratings[0]?.value_for_money
                  : null
              }
              setEvaluateWineId={setEvaluateWineId}
            />
          </Box>
        </Modal>
      </LoaderWrapper>
    </div>
  );
}

export default Orders;
