import { useTranslation } from "react-i18next";
import styles from "./GuestAfterPurchase.module.scss";
import { useAppDispatch, useAppSelector } from "hooks";
import { useEffect, useState } from "react";
import StorageService from "services/storage.service";
import ButtonComponent from "components/Button/Button.component";
import { GlobalIState } from "types/RootState";
import { useNavigate, useSearchParams } from "react-router-dom";
import { registerBegin } from "pages/Authentication/actions";
import { LOG_IN_SUCCESS } from "pages/Authentication/constants";
import { openToaster, scrollToTop } from "App/actions";

import {
  getDefaultColour,
  hasEightCharacters,
  hasLowercaseLetter,
  hasNumbers,
  hasSymbols,
  hasUppercaseLetter,
} from "utils/utils";
import PasswordInput from "components/PasswordInput/PasswordInput.component";
import { LOGIN_DEVICE } from "utils/enums";

function GuestAfterPurchase() {
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const {
    action: { actionName },
  } = useAppSelector((state: GlobalIState) => state.authenticationReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const guestEmailParam = searchParams.get("email");
  const [password, setPassword] = useState("");
  const guestUserInfoCookie = StorageService.getDataByKey("guest_user");
  const disableButton =
    !hasUppercaseLetter(password) ||
    !hasLowercaseLetter(password) ||
    !hasNumbers(password) ||
    !hasSymbols(password) ||
    !hasEightCharacters(password);

  const getLanguage = () => {
    return localStorage.getItem("lang");
  };

  const handleSignup = () => {
    if (password?.length === 0 || disableButton) {
      dispatch(openToaster("ERROR", translations("label.password.hints")));
      return;
    }

    const data = {
      email: guestEmailParam,
      password: password,
      country_phone: "",
      phone: "",
      sign_up_method: "email",
      language: getLanguage(),
      referral_code: "",
      is_passcode: false,
      signup_device: LOGIN_DEVICE.WEB_APP,
    };

    dispatch(registerBegin(data));
  };

  const handleSkip = () => {
    StorageService.deleteCookie("guest_user");
    StorageService.deleteCookie("guestUserDeliveryInfo");
    navigate("/home");
  };

  useEffect(() => {
    if (actionName === LOG_IN_SUCCESS) {
      StorageService.deleteCookie("guest_user");
      StorageService.deleteCookie("guestUserDeliveryInfo");
      navigate("/home");
    }
  }, [actionName]);

  useEffect(() => {
    dispatch(scrollToTop());
  }, []);

  return (
    <div
      className={styles.contentWrapper}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      <h1>{translations("title.create_account_after_purchase?")}</h1>
      <span className={styles.subtitle}>
        {translations("subtitle_1.create_account_after_purchase")}
      </span>
      <span className={styles.subtitle}>
        {translations("subtitle_2.create_account_after_purchase")}
      </span>
      <span className={styles.subtitle}>
        {translations("subtitle_3.create_account_after_purchase")}
      </span>
      <span className={styles.coloredLabel} style={{ marginTop: 30 }}>
        {translations("label.guest_signup_hint")}
      </span>
      <span className={styles.coloredLabel}>
        {translations("label.create_account_after_purchase_hint")}
      </span>

      <div className={styles.inputs}>
        <input
          name="guest_email"
          autoComplete="disabled"
          value={guestUserInfoCookie?.email}
          maxLength={50}
        />

        <h3>{translations("label.enter_your_password")}</h3>

        <PasswordInput
          name="newPassword"
          className="secondInput"
          placeholder={translations("label.new_password")}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <div className={styles.passwordValidations}>
          <span>{translations("label.password_validations")}</span>
        </div>
        <div className={styles.hint}>
          <span>
            {translations("label.this_password_will_be_used_for_future")}
          </span>
        </div>
      </div>

      <div className={styles.buttons}>
        <ButtonComponent
          label={translations("button.sign_up")}
          class={"btn btn-primary"}
          handleClick={() => handleSignup()}
        />
        <div className={styles.skipBtn} onClick={() => handleSkip()}>
          <span>{translations("button.skip")}</span>
        </div>
      </div>
    </div>
  );
}

export default GuestAfterPurchase;
