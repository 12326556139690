import { useAppDispatch, useAppSelector } from "hooks";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { GlobalIState } from "types/RootState";
import styles from "./AddedWinePlace.module.scss";
import { getDefaultColour } from "utils/utils";
import { CamIcon } from "assets/icons/icons";
import { useEffect, useRef, useState } from "react";
import {
  editAddedWineBegin,
  getAddedWineProductDetailsBegin,
} from "pages/PersonalLedger/actions";

function AddedWinePlace() {
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const { addedWineProductDetails } = useAppSelector(
    (state: GlobalIState) => state.PersonalLedgerReducer
  );
  const dispatch = useAppDispatch();
  const { t: translations } = useTranslation();
  const imageInputFileRef = useRef(null);
  const [imgPreview, setImgPreview] = useState(null);
  const [searchParams] = useSearchParams();
  const productIdParam = searchParams.get("id");

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    const reader = new FileReader();
    reader.onloadend = () => {
      setImgPreview(reader.result);

      let data = {
        id: addedWineProductDetails?.id?.toString(),
        wine_name: addedWineProductDetails?.wine_name,
        producer: addedWineProductDetails?.producer,
        bottle_size: addedWineProductDetails?.bottle_size,
        type: addedWineProductDetails?.type,
        country: addedWineProductDetails?.country,
        region: addedWineProductDetails?.region,
        year:
          addedWineProductDetails?.year?.toString() === "0"
            ? 0
            : Number(addedWineProductDetails?.year),
        price:
          addedWineProductDetails?.price?.toString() === "0"
            ? ""
            : addedWineProductDetails?.price,
        description: addedWineProductDetails?.description,
        location: addedWineProductDetails.location,
        inventory: addedWineProductDetails?.inventory,
        custom_field: JSON.stringify(addedWineProductDetails?.custom_field),
        image: false,
        image_file: false,
        location_image: true,
        location_image_file: file,
      };

      dispatch(editAddedWineBegin(data));
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleUpdateImageClick = () => {
    imageInputFileRef?.current?.click();
  };

  useEffect(() => {
    if (productIdParam) {
      dispatch(getAddedWineProductDetailsBegin(Number(productIdParam)));
    }
  }, [productIdParam]);

  return (
    <div
      className={styles.wrapper}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      <div className={styles.content}>
        <div className={styles.card}>
          <div>
            <span>{translations("label.experience_location")}</span>
            <span>{addedWineProductDetails?.location}</span>
          </div>
          <div>
            <span>{translations("label.inventory")}</span>
            <span>{addedWineProductDetails?.inventory}</span>
          </div>
        </div>
        <input
          type="file"
          id="locationImageUploadInput"
          ref={imageInputFileRef}
          style={{ display: "none" }}
          accept="image/*"
          onChange={handleImageChange}
        />
        <div
          style={
            addedWineProductDetails?.location_image?.length > 0
              ? {
                  backgroundImage: `url(${
                    imgPreview
                      ? imgPreview
                      : addedWineProductDetails?.location_image
                  })`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }
              : { backgroundColor: getDefaultColour(defaultColour) }
          }
          className={styles.locationImageCard}
          onClick={handleUpdateImageClick}
        >
          {!addedWineProductDetails?.location_image ? (
            <>
              <span>{translations("label.image_of_location")}</span>
              <CamIcon />
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default AddedWinePlace;
