import { useTranslation } from "react-i18next";
import styles from "./Purchasing.module.scss";
import productGridStyles from "./ProductGrid.module.scss";

import { useAppDispatch, useAppSelector } from "hooks";
import { useEffect, useState } from "react";

import { GlobalIState } from "types/RootState";
import {
  fetchProductBookmarksBegin,
  fetchProductDetailsBegin,
  fetchProductsBegin,
  getOneHourDeliveryProductsBegin,
  getPurchasingFiltersBegin,
  setCartItems,
  setFilters,
  setProductBookmarksCookie,
  toggleBookmarkProductBegin,
} from "./actions";
import { openToaster } from "App/actions";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import ProductDetails from "./ProductDetails/ProductDetails.page";
import {
  formatPrice,
  getDefaultColour,
  getFilterOptions,
  isProductBookmarked,
  reorderProducts,
  updateFiltersArray,
} from "utils/utils";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  ArrowRightTableIcon,
  BookmarksIcon,
  ClearIcon,
  DeliveryTruckIcon,
  ListStreamMap,
  LocationMapIcon,
  RatingIcon,
  SearchIcon,
  ShoppingCartAddIcon,
  ViewDetailsArrowRight,
} from "assets/icons/icons";
import BottleShadow from "assets/images/bottle-shadow.png";
import SpecialBottleShadow from "assets/images/special-bottle-shadow.png";

import StorageService from "services/storage.service";
import {
  FETCH_PRODUCT_DETAILS_SUCCESS,
  TOGGLE_BOOKMARK_PRODUCT_SUCCESS,
} from "./constants";
import MapStream from "./WineMap/MapStream.page";
import LoaderWrapper from "components/Loader/LoaderWrapper/LoaderWrapper";

export default function Purchasing() {
  const { defaultColour, client } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );

  const {
    action: { actionName, isLoading },
    products,
    filters,
    filterOptions,
    productDetails,
    productBookmarks,
    oneHourDeliveryProducts,
  } = useAppSelector((state: GlobalIState) => state.PurchasingReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();
  const isMobile = useCheckMobileScreen();
  const [showProductDetails, setShowProductDetails] = useState({
    id: null,
    show: false,
  });
  const [shouldAddToCart, setShouldAddToCart] = useState(false);
  const [hasSpecialProducts, setHasSpecialProducts] = useState(
    products?.some((item) => item?.is_special === true)
  );
  const [shownProducts, setShownProducts] = useState([]);
  const [rawCSS, setRawCSS] = useState("");
  const [applyTypeFilter, setApplyTypeFilter] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const streamParam = searchParams.get("stream");
  const newsletterParam = searchParams.get("newsletter");
  const idParam = searchParams.get("id");
  const refetchParam = searchParams.get("refetch");
  const isLoggedIn = () => StorageService.getDataByKey("token");
  const typeFilterSelectedValues = filters?.filter(
    (item) => item.filter === "Type"
  );
  const typeFilterOptions = getFilterOptions(filterOptions?.types, "name");
  const searchValueCookie = StorageService.getDataByKey("search");
  const productFiltersCookie = () => StorageService.getDataByKey("filters");
  const [searchValue, setSearchValue] = useState("");
  const searchParam = searchParams.get("search");
  const isSearching = searchParam && searchParam === "true";

  const productBookmarksCookie = () => {
    return JSON.parse(localStorage.getItem("product_bookmarks"));
  };

  const getLanguageToken = () => {
    return localStorage.getItem("lang");
  };

  const checkIfIsBookmarked = (id: number) => {
    return isProductBookmarked(productBookmarks, id);
  };

  const handleProductClick = (id: number) => {
    if (isMobile) {
      navigate(`/purchasing/product?id=${id}`);
    } else {
      if (id === showProductDetails?.id) {
        setShowProductDetails({ id: null, show: false });
      } else {
        setShowProductDetails({ id: id, show: true });
      }
    }
  };

  const handleAddToCart = (id: number) => {
    setShouldAddToCart(true);
    dispatch(fetchProductDetailsBegin(id, getLanguageToken()));
  };

  const handleStreamTabChange = (type: string) => {
    StorageService.setKeyValue("stream", type);
    searchParams.set("stream", type);
    setSearchParams(searchParams);

    if (streamParam === type) {
      searchParams.set("refresh", "true");
      setSearchParams(searchParams);
    }

    dispatch(
      fetchProductsBegin(
        type,
        1,
        searchValueCookie ? searchValueCookie : "",
        updateFiltersArray(filters.filter((item) => item.value.length >= 1)),
        getLanguageToken()
      )
    );
  };

  const handleAddToBookmarks = (product) => {
    if (isLoggedIn()) {
      dispatch(toggleBookmarkProductBegin(product?.id?.toString()));
      dispatch(fetchProductBookmarksBegin(getLanguageToken()));
    } else {
      if (!productBookmarksCookie()) {
        localStorage.setItem("product_bookmarks", JSON.stringify([]));
      }
      setTimeout(() => {
        dispatch(setProductBookmarksCookie(product));
      }, 0);
    }
  };

  const handleTypeFiltersChangeMap = (value: string) => {
    dispatch(
      setFilters({
        filter: "Type",
        value: typeFilterSelectedValues[0]?.value?.includes(value)
          ? typeFilterSelectedValues[0]?.value?.filter((item) => item !== value)
          : [...typeFilterSelectedValues[0]?.value, value],
      })
    );

    setApplyTypeFilter(true);
  };

  const handleSearchValue = (value) => {
    if (value.includes("://")) {
      dispatch(
        openToaster("ERROR", translations("error.search.url_value_not_allowed"))
      );
    } else {
      StorageService.setKeyValue("search", value);
      setSearchValue(value);
      setTimeout(() => {
        dispatch(
          fetchProductsBegin(
            streamParam,
            1,
            value,
            updateFiltersArray(
              filters.filter((item) => item.value.length >= 1)
            ),
            getLanguageToken()
          )
        );
      }, 300);
    }
  };

  useEffect(() => {
    if (shouldAddToCart && actionName === FETCH_PRODUCT_DETAILS_SUCCESS) {
      dispatch(setCartItems(productDetails));
      dispatch(
        openToaster("SUCCESS", translations("label.item_added_to_cart"))
      );
      setShouldAddToCart(false);
    }

    if (actionName === TOGGLE_BOOKMARK_PRODUCT_SUCCESS) {
      dispatch(fetchProductBookmarksBegin(getLanguageToken()));
    }
  }, [shouldAddToCart, actionName]);

  useEffect(() => {
    if (products?.length === 0) {
      let storedFilters = productFiltersCookie()
        ? productFiltersCookie()?.filter((item) => item?.value?.length >= 1)
        : [];

      dispatch(
        fetchProductsBegin(
          streamParam ? streamParam : "list",
          1,
          "",
          storedFilters?.length === 0
            ? [{}]
            : updateFiltersArray(storedFilters),
          getLanguageToken()
        )
      );
    }

    // if (oneHourDeliveryProducts?.length === 0) {
    //   dispatch(getOneHourDeliveryProductsBegin(getLanguageToken()));
    // }
    dispatch(getPurchasingFiltersBegin(getLanguageToken()));

    return () => {
      StorageService.setKeyValue("products_page", 1);
      StorageService.deleteCookie("search");
    };
  }, [getLanguageToken()]);

  useEffect(() => {
    if (showProductDetails.id) {
      dispatch(
        fetchProductDetailsBegin(showProductDetails.id, getLanguageToken())
      );
    }

    if (isLoggedIn()) {
      dispatch(fetchProductBookmarksBegin(getLanguageToken()));
    }
  }, [showProductDetails.id, isLoggedIn()]);

  useEffect(() => {
    setHasSpecialProducts(products?.some((item) => item?.is_special === true));

    if (products?.some((item) => item?.is_special === true)) {
      setShownProducts(
        isMobile
          ? reorderProducts(products, true)
          : reorderProducts(
              [...products]?.sort(
                (a, b) => Number(b.is_1h_delivery) - Number(a.is_1h_delivery)
              ),
              false
            )
      );
    } else {
      setShownProducts(
        [...products]?.sort(
          (a, b) => Number(b.is_1h_delivery) - Number(a.is_1h_delivery)
        )
      );
    }

    if (products?.length) {
      const areasString = products.reduce((acc, item, index) => {
        acc += `
          .${productGridStyles.gridContainer} > div:nth-of-type(${index + 1}) {
            grid-area: i${index + 1};
          }
        `;

        return acc;
      }, "");

      const rawCSS = `
      //   @media screen and (max-width: 1800px){
      //   .${productGridStyles.gridContainer} {
      //     grid-auto-rows: auto;
      //   }
      // }
      ${areasString}

    `;
      setRawCSS(rawCSS);
    }
  }, [products, filters]);

  useEffect(() => {
    if (!streamParam) {
      searchParams.set("stream", "list");
      setSearchParams(searchParams);
    }

    if (newsletterParam === "true" && idParam) {
      if (isMobile) navigate(`/purchasing/product?id=${idParam}`);
      else setShowProductDetails({ id: idParam, show: true });
    }
  }, [streamParam, newsletterParam, idParam]);

  useEffect(() => {
    if (applyTypeFilter || refetchParam === "1") {
      if (applyTypeFilter) {
        setApplyTypeFilter(false);
      }
      if (refetchParam === "1") {
        searchParams.delete("refetch");
        setSearchParams(searchParams);
      }
      dispatch(
        fetchProductsBegin(
          streamParam,
          1,
          "",
          updateFiltersArray(filters.filter((item) => item.value.length >= 1)),
          getLanguageToken()
        )
      );
    }
  }, [applyTypeFilter, refetchParam]);

  return (
    <div
      className={` ${
        showProductDetails?.show
          ? styles.containerWithDetails
          : styles.container
      }`}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      {isSearching && isMobile ? (
        <div className={styles.listTabs} style={{ marginBottom: "10px" }}>
          <div className={styles.searchBox}>
            <div className={styles.searchInput}>
              <SearchIcon color="#000" />
              <input
                type="text"
                value={
                  !isSearching
                    ? searchValue.length > 8
                      ? searchValue.substring(0, 8) + "..."
                      : searchValue
                    : searchValue
                }
                onChange={(e) => handleSearchValue(e.target.value)}
              />
            </div>
            <div
              onClick={() => {
                searchParams.delete("search");
                setSearchParams(searchParams);
              }}
            >
              <ClearIcon />
            </div>
          </div>
        </div>
      ) : isMobile ? (
        <div className={styles.listTabs}>
          <div
            style={{
              backgroundColor: streamParam === "map" ? "#000" : "#E6EBEF",
            }}
            onClick={() => handleStreamTabChange("map")}
          >
            <LocationMapIcon color={streamParam === "map" ? "#fff" : "#000"} />
          </div>
          <div
            style={{
              backgroundColor: streamParam === "list" ? "#000" : "#E6EBEF",
            }}
            onClick={() => handleStreamTabChange("list")}
          >
            <ListStreamMap color={streamParam === "list" ? "#fff" : "#000"} />
          </div>
        </div>
      ) : null}

      {/* {isMobile &&
      streamParam === "list" &&
      oneHourDeliveryProducts?.length !== 0 ? (
        <div className={styles.oneHourDPcontainer}>
          <div className={styles.scrollableProducts}>
            <div className={styles.orangeCard}>
              <div>
                <DeliveryTruckIcon />
                <h3>{translations("label.check_one_hour_delivery_wines")}</h3>
              </div>
              <div>
                <span>{translations("label.scroll")}</span>
                <ArrowRightTableIcon color="#fff" />
              </div>
            </div>

            {oneHourDeliveryProducts?.map((item, index) => {
              return (
                <div
                  key={index}
                  className={`${styles.mobileProduct}`}
                  onClick={() => handleProductClick(item?.id)}
                >
                  <div className={styles.leftContent}>
                    <div className={styles.header}>
                      <div className={styles.region}>
                        <span>{item?.region}</span>
                      </div>
                      <div className={styles.productRating}>
                        <RatingIcon color={getDefaultColour(defaultColour)} />
                        {item?.scores?.length >= 1 ? (
                          <span>
                            {item?.scores[0]?.score} /{" "}
                            {item?.scores[0]?.max_score}
                          </span>
                        ) : (
                          <span>96 / 100</span>
                        )}
                      </div>
                    </div>
                    <div className={styles.category}>
                      <span>{item?.producer}</span>
                    </div>
                    <div>
                      <div className={styles.bottleInfo}>
                        <span>
                          {item?.vintage} | {item?.bottle_size}
                        </span>
                      </div>
                      <div className={styles.price}>
                        {item?.old_price ? (
                          <>
                            <span className={styles.discount}>
                              CHF
                              {formatPrice(item.old_price?.toString())}
                            </span>
                            <br />
                          </>
                        ) : null}
                        <span>CHF {formatPrice(item?.price?.toString())}</span>
                      </div>
                      <div className={styles.name}>
                        <span>
                          {item?.wine_name?.length > 25
                            ? item?.wine_name?.substring(0, 25) + "..."
                            : item?.wine_name}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className={styles.rightContent}>
                    <img
                      className={
                        item?.is_special
                          ? styles.specialProductImage
                          : styles.productImage
                      }
                      src={item?.product_image}
                      alt={item?.producer}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null} */}

      <div
        className={`${styles.productsWrapper} ${
          showProductDetails?.show && styles.pWrapperWithDetails
        } ${
          !isMobile &&
          !showProductDetails?.show &&
          products?.length >= 24 &&
          hasSpecialProducts &&
          streamParam !== "map"
            ? productGridStyles.gridContainer
            : ""
        }`}
      >
        <style>{rawCSS}</style>

        {shownProducts?.length === 0 ? (
          <div className={styles.noProductsLabel}>
            <span>{translations("error.no_products_fetched")}</span>
          </div>
        ) : isMobile ? (
          <>
            {/* {isMobile ? (
              <div
                className={styles.mobileProduct}
                style={{ display: "none" }}
              ></div>
            ) : null} */}
            {streamParam === "map" ? (
              <div className={styles.mapContainer}>
                <div className={styles.wrapper}>
                  <div className={styles.mapFilters}>
                    <div
                      style={{
                        backgroundColor:
                          typeFilterSelectedValues[0]?.value.includes(
                            typeFilterOptions?.length > 0
                              ? typeFilterOptions[0]
                              : ""
                          )
                            ? "#000"
                            : "#fff",
                        color: typeFilterSelectedValues[0]?.value.includes(
                          typeFilterOptions?.length > 0
                            ? typeFilterOptions[0]
                            : ""
                        )
                          ? "#fff"
                          : "#000",
                      }}
                      onClick={() =>
                        handleTypeFiltersChangeMap(
                          typeFilterOptions?.length > 0
                            ? typeFilterOptions[0]
                            : ""
                        )
                      }
                    >
                      <span>
                        {typeFilterOptions?.length > 0
                          ? typeFilterOptions[0]
                          : translations("label.red")}
                      </span>
                    </div>
                    <div
                      style={{
                        backgroundColor:
                          typeFilterSelectedValues[0]?.value.includes(
                            typeFilterOptions?.length > 0
                              ? typeFilterOptions[1]
                              : ""
                          )
                            ? "#000"
                            : "#fff",
                        color: typeFilterSelectedValues[0]?.value.includes(
                          typeFilterOptions?.length > 0
                            ? typeFilterOptions[1]
                            : ""
                        )
                          ? "#fff"
                          : "#000",
                      }}
                      onClick={() =>
                        handleTypeFiltersChangeMap(
                          typeFilterOptions?.length > 0
                            ? typeFilterOptions[1]
                            : ""
                        )
                      }
                    >
                      <span>
                        {typeFilterOptions?.length > 0
                          ? typeFilterOptions[1]
                          : translations("label.white")}
                      </span>
                    </div>
                    <div
                      style={{
                        backgroundColor:
                          typeFilterSelectedValues[0]?.value.includes(
                            typeFilterOptions?.length > 0
                              ? typeFilterOptions[3]
                              : ""
                          )
                            ? "#000"
                            : "#fff",
                        color: typeFilterSelectedValues[0]?.value.includes(
                          typeFilterOptions?.length > 0
                            ? typeFilterOptions[3]
                            : ""
                        )
                          ? "#fff"
                          : "#000",
                      }}
                      onClick={() =>
                        handleTypeFiltersChangeMap(
                          typeFilterOptions?.length > 0
                            ? typeFilterOptions[3]
                            : ""
                        )
                      }
                    >
                      <span>
                        {typeFilterOptions?.length > 0
                          ? typeFilterOptions[3]
                          : translations("label.rose")}
                      </span>
                    </div>
                    <div
                      style={{
                        backgroundColor:
                          typeFilterSelectedValues[0]?.value.includes(
                            typeFilterOptions?.length > 0
                              ? typeFilterOptions[2]
                              : ""
                          )
                            ? "#000"
                            : "#fff",
                        color: typeFilterSelectedValues[0]?.value.includes(
                          typeFilterOptions?.length > 0
                            ? typeFilterOptions[2]
                            : ""
                        )
                          ? "#fff"
                          : "#000",
                      }}
                      onClick={() =>
                        handleTypeFiltersChangeMap(
                          typeFilterOptions?.length > 0
                            ? typeFilterOptions[2]
                            : ""
                        )
                      }
                    >
                      <span>
                        {typeFilterOptions?.length > 0
                          ? typeFilterOptions[2]
                          : translations("label.champagne")}
                      </span>
                    </div>
                  </div>
                </div>
                <MapStream />
              </div>
            ) : (
              <>
                {shownProducts?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={`${styles.mobileProduct}`}
                      style={
                        item?.is_special
                          ? {
                              width: "95%",
                              height: 400,
                              margin: "0 auto",
                              border: `1px solid ${getDefaultColour(
                                defaultColour
                              )}`,
                            }
                          : {}
                      }
                      onClick={() => handleProductClick(item?.id)}
                    >
                      <div className={styles.leftContent}>
                        <div className={styles.header}>
                          <div className={styles.region}>
                            <span>{item?.region}</span>
                          </div>
                          <div className={styles.productRating}>
                            <RatingIcon
                              color={getDefaultColour(defaultColour)}
                            />
                            {item?.scores?.length >= 1 ? (
                              <span>
                                {item?.scores[0]?.score} /{" "}
                                {item?.scores[0]?.max_score}
                              </span>
                            ) : (
                              <span>96 / 100</span>
                            )}
                          </div>
                        </div>
                        <div className={styles.category}>
                          <span>{item?.producer}</span>
                        </div>
                        <div>
                          <div className={styles.bottleInfo}>
                            <span>
                              {item?.vintage} | {item?.bottle_size}
                            </span>
                          </div>
                          <div className={styles.price}>
                            {item?.old_price ? (
                              <>
                                <span className={styles.discount}>
                                  CHF
                                  {formatPrice(item.old_price?.toString())}
                                </span>
                                <br />
                              </>
                            ) : null}
                            <span>
                              CHF {formatPrice(item?.price?.toString())}
                            </span>
                          </div>
                          <div className={styles.name}>
                            <span>
                              {item?.wine_name?.length > 25
                                ? item?.wine_name?.substring(0, 25) + "..."
                                : item?.wine_name}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className={styles.rightContent}>
                        <img
                          className={
                            item?.is_special
                              ? styles.specialProductImage
                              : styles.productImage
                          }
                          src={item?.product_image}
                          alt={item?.producer}
                        />
                      </div>
                    </div>
                  );
                })}
                {isLoading ? (
                  <div className={styles.loader}>
                    <LoaderWrapper isLoading={isLoading} />
                  </div>
                ) : null}
              </>
            )}
          </>
        ) : (
          <>
            {streamParam === "map" ? (
              <div className={styles.mapContainer}>
                <MapStream setShowProductDetails={setShowProductDetails} />
              </div>
            ) : (
              shownProducts?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`${
                      item?.is_special && !showProductDetails?.show
                        ? styles.specialProduct
                        : styles.product
                    }`}
                    style={
                      showProductDetails?.show ||
                      (!hasSpecialProducts &&
                        filters?.filter((item) => item?.value?.length >= 1)
                          ?.length !== 0)
                        ? { margin: 5 }
                        : !hasSpecialProducts &&
                          filters?.filter((item) => item?.value?.length === 0)
                            ?.length !== 0
                        ? { margin: 5 }
                        : {}
                    }
                  >
                    <div className={styles.infoParts}>
                      {item?.is_special ? (
                        <div className={styles.specialInfo}>
                          <div
                            className={styles.productRating}
                            onClick={() => handleProductClick(item?.id)}
                          >
                            <RatingIcon />
                            {item?.scores?.length >= 1 ? (
                              <span>
                                {item?.scores[0]?.score} /{" "}
                                {item?.scores[0]?.max_score}
                              </span>
                            ) : (
                              <span>96 / 100</span>
                            )}
                          </div>
                          <div>
                            <div
                              className={styles.name}
                              onClick={() => handleProductClick(item?.id)}
                            >
                              <div>
                                <span>
                                  {showProductDetails.show &&
                                  item?.wine_name?.length > 15
                                    ? item?.wine_name?.substring(0, 15) + "..."
                                    : item?.wine_name}
                                </span>
                              </div>
                              <br />
                              <div
                                className={styles.vintageLabel}
                                onClick={() => handleProductClick(item?.id)}
                              >
                                <span>{item?.vintage}</span>
                              </div>
                            </div>

                            <div
                              className={styles.bottleSizeLabel}
                              onClick={() => handleProductClick(item?.id)}
                            >
                              <span>{item?.bottle_size}</span>
                            </div>
                            <div
                              className={styles.regionLabel}
                              onClick={() => handleProductClick(item?.id)}
                            >
                              <span>{item?.region}</span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div
                            className={styles.productRating}
                            onClick={() => handleProductClick(item?.id)}
                          >
                            <RatingIcon />
                            {item?.scores?.length >= 1 ? (
                              <span>
                                {item?.scores[0]?.score} /{" "}
                                {item?.scores[0]?.max_score}
                              </span>
                            ) : (
                              <span>96 / 100</span>
                            )}
                          </div>

                          <div>
                            <div
                              className={styles.name}
                              onClick={() => handleProductClick(item?.id)}
                            >
                              <div>
                                <span>
                                  {showProductDetails.show &&
                                  item?.wine_name?.length > 15
                                    ? item?.wine_name?.substring(0, 15) + "..."
                                    : item?.wine_name}
                                </span>
                              </div>
                              <br />
                              <div
                                className={styles.vintageLabel}
                                onClick={() => handleProductClick(item?.id)}
                              >
                                <span>{item?.vintage}</span>
                              </div>
                            </div>

                            <div
                              className={styles.bottleSizeLabel}
                              onClick={() => handleProductClick(item?.id)}
                            >
                              <span>{item?.bottle_size}</span>
                            </div>
                            <div
                              className={styles.regionLabel}
                              onClick={() => handleProductClick(item?.id)}
                            >
                              <span>{item?.region}</span>
                            </div>
                          </div>
                        </>
                      )}

                      <div className={styles.bottoms}>
                        <div
                          className={styles.price}
                          onClick={() => handleProductClick(item?.id)}
                        >
                          {item?.old_price ? (
                            <>
                              <span className={styles.discount}>
                                CHF
                                {formatPrice(item.old_price?.toString())}
                              </span>
                              <br />
                            </>
                          ) : null}
                          <span>CHF {item?.price}</span>
                        </div>

                        <div className={styles.productActions}>
                          <div
                            className={styles.addToCartButton}
                            onClick={() => handleAddToCart(item.id)}
                          >
                            <ShoppingCartAddIcon />
                          </div>
                          <div
                            className={styles.bookmarksIcon}
                            onClick={() => handleAddToBookmarks(item)}
                          >
                            <BookmarksIcon
                              color={"#000"}
                              fill={
                                checkIfIsBookmarked(item?.id) ? "#000" : null
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={styles.imagePart}
                      onClick={() => handleProductClick(item?.id)}
                    >
                      <img
                        className={styles.productImage}
                        style={{
                          height: 260,
                        }}
                        src={item?.product_image}
                        alt={item?.producer}
                      />
                      <img
                        src={
                          item?.is_special ? SpecialBottleShadow : BottleShadow
                        }
                        className={
                          item?.is_special
                            ? styles.specialBottleShadow
                            : styles.bottleShadow
                        }
                        alt="Shadow"
                      />
                    </div>

                    <div className={styles.productInfo}>
                      <div className={styles.boldText}>
                        <span>{item?.hover_text}</span>
                      </div>
                      <div>
                        <span>{item?.pairing}</span>
                      </div>
                      <div
                        className={`pointer ${styles.boldText}`}
                        onClick={() => handleProductClick(item?.id)}
                      >
                        <ViewDetailsArrowRight
                          color={getDefaultColour(defaultColour)}
                        />
                        <span>{translations("label.details")}</span>
                      </div>
                    </div>
                  </div>
                );
              })
            )}

            {streamParam !== "map" && shownProducts?.length > 0 ? (
              <div className={styles.loader}>
                <LoaderWrapper isLoading={isLoading} />
              </div>
            ) : null}
          </>
        )}
      </div>

      {showProductDetails.show ? (
        <div className={styles.productDetailsWrapper}>
          <ProductDetails setShowProductDetails={setShowProductDetails} />
        </div>
      ) : null}
    </div>
  );
}
