import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import styles from "./EvaluateProduct.module.scss";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getDefaultColour, modifyHomeFiltersForDate } from "utils/utils";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { CloseIconWhite, ValueForMoneyIlustration } from "assets/icons/icons";
import ButtonComponent from "components/Button/Button.component";
import {
  evaluateJournalWineBegin,
  getJournalDataBegin,
} from "pages/PersonalLedger/actions";
import { EVALUATE_JOURNAL_WINE_SUCCESS } from "pages/PersonalLedger/constants";
import StorageService from "services/storage.service";

interface Props {
  id: number;
  isAddedWine: boolean;
  value: number;
  setEvaluateWineId: Dispatch<SetStateAction<number>>;
}

function EvaluateProduct(props: Props) {
  const { id, isAddedWine, value, setEvaluateWineId } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const {
    action: { actionName },
    filters,
  } = useAppSelector((state: GlobalIState) => state.PersonalLedgerReducer);
  const [selected, setSelected] = useState(value);

  const handleSelect = (index: number) => {
    setSelected(index);
  };

  const journalSearchCookie = () =>
    StorageService.getDataByKey("journalSearch");

  const getAngle = () => {
    switch (selected) {
      case 1:
        return -80;
        break;
      case 2:
        return -40;
        break;
      case 3:
        return 0;
        break;
      case 4:
        return 40;
        break;
      case 5:
        return 80;
        break;
      default:
        return 0;
        break;
    }
  };

  const handleSubmit = () => {
    dispatch(
      evaluateJournalWineBegin({
        product_id: id,
        value_for_money: selected,
        isAddedWine: isAddedWine,
      })
    );
  };

  useEffect(() => {
    if (actionName === EVALUATE_JOURNAL_WINE_SUCCESS) {
      dispatch(
        getJournalDataBegin({
          search: journalSearchCookie() ? journalSearchCookie() : "",
          filters:
            filters?.filter((item) => item?.value?.length >= 1)?.length === 0
              ? []
              : modifyHomeFiltersForDate(
                  filters?.filter((item) => item?.value?.length >= 1)
                )?.filters,
        })
      );
      setEvaluateWineId(null);
    }
  }, [actionName]);

  return (
    <div
      className={styles.wrapper}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      <div className={styles.header} onClick={() => setEvaluateWineId(null)}>
        <CloseIconWhite color="#000" />
      </div>

      <h4>{translations("title.value_for_money")}</h4>

      <div className={styles.content}>
        <div className={styles.valueSelector}>
          <ValueForMoneyIlustration angle={getAngle()} />

          <div
            className={`${styles.firstOption} ${
              selected === 1 && styles.selectedOption
            }`}
            onClick={() => handleSelect(1)}
          >
            <span>{translations("label.bad_value")}</span>
          </div>
          <div
            className={`${styles.secondOption} ${
              selected === 2 && styles.selectedOption
            }`}
            onClick={() => handleSelect(2)}
          >
            <span>{translations("label.fair_value")}</span>
          </div>
          <div
            className={`${styles.thirdOption} ${
              selected === 3 && styles.selectedOption
            }`}
            onClick={() => handleSelect(3)}
          >
            <span>{translations("label.good_value")}</span>
          </div>
          <div
            className={`${styles.fourthOption} ${
              selected === 4 && styles.selectedOption
            }`}
            onClick={() => handleSelect(4)}
          >
            <span>{translations("label.great_value")}</span>
          </div>
          <div
            className={`${styles.fifthOption} ${
              selected === 5 && styles.selectedOption
            }`}
            onClick={() => handleSelect(5)}
          >
            <span>{translations("label.superb_value")}</span>
          </div>
        </div>
      </div>
      <ButtonComponent
        label={translations("button.save")}
        class={"btn btn-primary"}
        disabled={!selected}
        handleClick={() => handleSubmit()}
      />
    </div>
  );
}

export default EvaluateProduct;
