import { useAppSelector } from "hooks";
import { useTranslation } from "react-i18next";
import { GlobalIState } from "types/RootState";
import styles from "./PlayedGame.module.scss";
import { getDefaultColour } from "utils/utils";
import { GoldCoinIcon } from "assets/icons/icons";
import CoinImage from "assets/images/Coin.png";
import { Navigate, useNavigate } from "react-router-dom";
import StorageService from "services/storage.service";

interface Props {}

function PlayedGame(props: Props) {
  const { client, defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const { t: translations } = useTranslation();
  const navigate = useNavigate();
  const isLoggedIn = () => StorageService.getDataByKey("token");

  return (
    <div
      className={styles.container}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      {client?.last_answer === 15 ? (
        <GoldCoinIcon />
      ) : (
        <img src={CoinImage} alt={"Coin"} />
      )}

      <span
        className={styles.coinsLabel}
        style={{ color: client?.last_answer === 15 ? "#F8BE01" : "#CDCDCD" }}
      >
        {translations("label.collected_medals", {
          medals_count: client?.last_answer,
        })}
      </span>

      <h1 style={{ color: client?.last_answer === 15 ? "#F8BE01" : "#fff" }}>
        {translations("label.thats_all_for_today")}
      </h1>

      <div className={styles.sublabels}>
        <div>
          <span>{translations("label.game_played.1")}</span>
        </div>
        <span>{translations("label.game_played.2")}</span>
      </div>

      <div className={styles.button} onClick={() => navigate("/membership")}>
        <span>{translations("label.check_out_league_table")}</span>
      </div>

      <div
        className={styles.inviteReferralsButton}
        onClick={() => {
          if (isLoggedIn()) {
            navigate("/invite-referral");
          } else {
            StorageService.setKeyValue("lastRouteCookie", "/invite-referral");
            navigate("/login");
          }
        }}
      >
        <span>{translations("label.invite_your_friends")}</span>
      </div>
    </div>
  );
}

export default PlayedGame;
