import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import { getDefaultColour } from "utils/utils";

import styles from "./DinoAIChatHistory.module.scss";
import {
  AnswerTouchLineIcon,
  ChatIcon,
  CloseNoBgIcon,
  DislikedIcon,
  DislikeIcon,
  DownloadIcon,
  InfinityIcon,
  LikedIcon,
  LikeIcon,
  RegenerateIcon,
  ReplyTouchLine,
  ShareArrowIcon,
} from "assets/icons/icons";

import StorageService from "services/storage.service";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  callAIQuestionBegin,
  getCardByIdBegin,
  likeOrDislikeChatBegin,
  saveAsisstantChatToStreamBegin,
  voteUnsavedChatBegin,
} from "../actions";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  CALL_AI_ASISSTANT_SUCCESS,
  LIKE_OR_DISLIKE_CHAT_BEGIN,
  SAVE_ASISSTANT_CHAT_TO_STREAM_SUCCESS,
} from "../constants";
import { openToaster } from "App/actions";
import LoaderWrapper from "components/Loader/LoaderWrapper/LoaderWrapper";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";

function DinoAIChatHistory() {
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const {
    action: { actionName, isLoading },
    card,
    asisstantAnswer,
  } = useAppSelector((state: GlobalIState) => state.HomeFeedReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();
  const [reply, setReply] = useState("");
  const [vote, setVote] = useState("");
  const [isRating, setIsRating] = useState(false);
  const [isReplying, setIsReplying] = useState(false);
  const [searchParams] = useSearchParams();
  const idParam = searchParams.get("id");
  const isMobile = useCheckMobileScreen();
  const dinoChatThreadCookie = () =>
    StorageService.getDataByKey("dinoChatThread");
  const dinoChatVoteCookie = () => StorageService.getDataByKey("chatVote");
  const [question, setQuestion] = useState(
    idParam ? card?.question : dinoChatThreadCookie()?.question
  );
  const [answer, setAnswer] = useState(
    idParam ? card?.answer : dinoChatThreadCookie()?.answer
  );

  const handleAskDinoAI = () => {
    if (!isReplying) setIsReplying(true);
    else console.log("ASK ASSISTANT");
  };

  const handleLikeDislikeAnswer = (type: string | null) => {
    let savedQuestionData = { vote: type, chat_id: card?.id };
    let unsavedQuestionData = {
      vote: type,
      question: question,
      answer: answer,
    };

    setIsRating(true);

    if (card?.id) {
      dispatch(likeOrDislikeChatBegin(savedQuestionData));
    } else {
      setVote(type);
      StorageService.setKeyValue("chatVote", type);
      dispatch(voteUnsavedChatBegin(unsavedQuestionData));
    }
  };

  const handleSaveAndClose = () => {
    dispatch(
      saveAsisstantChatToStreamBegin({
        question: question,
        answer: answer,
      })
    );
  };

  const formatLine = (line: string) => {
    const boldPattern = /\*\*(.*?)\*\*/g;
    const italicPattern = /\*(.*?)\*/g;
    const headerPattern = /^###\s*(.*)$/;

    if (headerPattern.test(line)) {
      const headerText = line.replace(headerPattern, "$1");
      return (
        <h3
          key={line}
          style={{
            color: "#FF6333",
            marginBottom: "12px",
            fontWeight: "bold",
          }}
        >
          {headerText}
        </h3>
      );
    }

    const formattedLine = line
      .replace(boldPattern, (_, boldText) => `<strong>${boldText}</strong>`)
      .replace(italicPattern, (_, italicText) => `<em>${italicText}</em>`);

    return (
      <span
        key={line}
        dangerouslySetInnerHTML={{ __html: formattedLine }}
        style={{ display: "block", marginBottom: "8px" }}
      />
    );
  };

  const handleShare = (value: string) => {
    navigator.clipboard.writeText(value);
    dispatch(openToaster("SUCCESS", translations("label.copied")));
  };

  const formatResponse = (text: string) => {
    if (!text) return null;

    const lines = text?.split("\n").filter((line) => line.trim() !== "");

    return lines.map((line, index) => {
      return <p key={index}>{formatLine(line)}</p>;
    });
  };

  const handleRegenerateAnswer = () => {
    dispatch(
      callAIQuestionBegin(
        `Dear Dino I don't like the answer you provided, can you please create another one in the language as previously. Question: ${question}`
      )
    );
  };

  useEffect(() => {
    if (idParam && !card) {
      dispatch(getCardByIdBegin(Number(idParam)));
    }
    if (card) {
      setQuestion(card?.question);
      setAnswer(card?.answer);
    }

    if (dinoChatVoteCookie()) {
      setVote(dinoChatVoteCookie());
    }
  }, [idParam, card, dinoChatVoteCookie()]);

  useEffect(() => {
    if (actionName === SAVE_ASISSTANT_CHAT_TO_STREAM_SUCCESS) {
      navigate("/home");
    }

    if (actionName === CALL_AI_ASISSTANT_SUCCESS) {
      setAnswer(asisstantAnswer);
    }

    if (actionName === LIKE_OR_DISLIKE_CHAT_BEGIN) {
      setTimeout(() => {
        if (idParam) dispatch(getCardByIdBegin(card?.id));
      }, 100);
    }
  }, [actionName]);

  useEffect(() => {
    if (isRating) {
      setIsRating(false);
    }
  }, [isRating]);

  return (
    <div
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
      className={styles.wrapper}
    >
      {isMobile ? (
        <div className={styles.header}>
          <div onClick={() => navigate("/home")}>
            <CloseNoBgIcon />
          </div>
        </div>
      ) : null}
      <InfinityIcon />

      <div className={styles.chatTimeline}>
        <div className={styles.question}>
          <div className={styles.qCard}>
            <span>{question}</span>
          </div>
        </div>
        <div className={styles.answer}>
          <div className={styles.answerTouchLine}>
            <AnswerTouchLineIcon color={getDefaultColour(defaultColour)} />
          </div>

          {isLoading ? (
            <div className={styles.loader}>
              <LoaderWrapper isLoading={true} />
            </div>
          ) : (
            <div className={styles.formattedAnswer}>
              {formatResponse(answer)}
            </div>
          )}

          {isReplying ? (
            <div className={styles.replyTouchLine}>
              <ReplyTouchLine color={getDefaultColour(defaultColour)} />
            </div>
          ) : null}

          <div className={styles.footer}>
            <div>
              <div onClick={() => handleLikeDislikeAnswer("up")}>
                {[card?.vote, vote].includes("up") ? (
                  <LikedIcon />
                ) : (
                  <LikeIcon />
                )}
              </div>
              <div onClick={() => handleLikeDislikeAnswer("down")}>
                {[card?.vote, vote].includes("down") ? (
                  <DislikedIcon />
                ) : (
                  <DislikeIcon />
                )}
              </div>
              <div onClick={() => handleRegenerateAnswer()}>
                <RegenerateIcon />
              </div>
            </div>
            <div className={styles.rightSideButtons}>
              <div onClick={() => handleShare(answer)}>
                <ShareArrowIcon />
              </div>
            </div>
          </div>
        </div>
      </div>

      {isReplying ? (
        <div className={styles.replyBox}>
          <textarea
            value={reply}
            placeholder={translations("label.type_here")}
            style={{
              width: "90%",
            }}
            // onBlur={() => (reply?.length === 0 ? setIsTyping(false) : null)}
            // onFocus={(e) => setIsTyping(true)}
            onChange={(e) => setReply(e.target.value)}
          />
          <div
            className={styles.closeReply}
            onClick={() => {
              // setIsTyping(false);
              setReply("");
              setIsReplying(false);
            }}
          >
            <span>-</span>
          </div>
        </div>
      ) : null}

      <div className={styles.chatActionButtons}>
        <div
          className={styles.replyToDinoBtn}
          onClick={() => handleAskDinoAI()}
        >
          <ChatIcon />
          <span>
            {translations(isReplying ? "button.send" : "label.reply")}
          </span>
        </div>
        <div
          className={styles.saveAndCloseBtn}
          onClick={() => handleSaveAndClose()}
        >
          <DownloadIcon />
          <span>{translations("label.save")}</span>
        </div>
      </div>
    </div>
  );
}

export default DinoAIChatHistory;
