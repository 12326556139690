import React from "react";
import styles from "./NP1.module.scss";
import { getDefaultColour } from "utils/utils";
import { useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import { IStreamFeedItem } from "types/reducers/homefeed.reducer.type";

interface NP1CardProps {
  item: IStreamFeedItem;
}

const NP1Card: React.FC<NP1CardProps> = ({ item }) => {
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );

  const {
    title,
    content,
    cardColours: {
      text_hex,
      background_hex,
      is_background_gradient,
      background_gradient,
    },
  } = item;

  return (
    <div
      className={styles.cardContainer}
      style={
        {
          "--color": getDefaultColour(defaultColour),
          background: is_background_gradient
            ? background_gradient
            : background_hex,
          color: text_hex,
        } as React.CSSProperties
      }
    >
      <div className={styles.header}>
        <div>
          <div className={styles.orangeLabel}></div>
          <div>
            <span>{title}</span>
          </div>
        </div>
      </div>
      <div className={styles.descriptionLabel}>
        <span>{content}</span>
      </div>
    </div>
  );
};

export default NP1Card;
