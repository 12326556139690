import { setMiddlepartItem, setRightPartItem } from "App/actions";
import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  fetchCountriesBegin,
  logInByToken,
  logOut,
  setIsLoggedIn,
} from "pages/Authentication/actions";
import { populateHomeFilters } from "pages/Home/actions";
import { clearGraphDataLoggedInState } from "pages/Investments/actions";
import { populateJournalFilters } from "pages/PersonalLedger/actions";

import {
  getClientBegin,
  getTermsAndConditionsBegin,
  getPrivacyPolicyBegin,
  clearClient,
  getPendingDeliveryBalanceBegin,
  getWalletTotalAmountBegin,
} from "pages/Profile/actions";
import {
  clearCartItems,
  fetchProductBookmarksBegin,
  populateCartItems,
  populateFilters,
  populateProductBookmarks,
  submitProductBookmarksStoredBegin,
} from "pages/Purchasing/actions";

import { useEffect, useMemo } from "react";
import { Outlet, useSearchParams } from "react-router-dom";
import StorageService from "services/storage.service";
import { GlobalIState } from "types/RootState";

export default function EstablishConnectionMiddleware() {
  const dispatch = useAppDispatch();
  const { client } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const { filters } = useAppSelector(
    (state: GlobalIState) => state.PurchasingReducer
  );
  const { HomeFeedReducer, PersonalLedgerReducer } = useAppSelector(
    (state: GlobalIState) => state
  );
  const isLoggedIn = () => StorageService.getDataByKey("token");

  let cartItemsStored = localStorage.getItem("cartItems");

  const expirationTime = () => StorageService.getDataByKey("expiresIn");
  const productsPageNumberCookie = StorageService.getDataByKey("products_page");
  const [searchParams] = useSearchParams();
  const stepParam = searchParams.get("step");
  const authParam = searchParams.get("auth");
  const tokenParam = searchParams.get("token");

  const productBookmarksCookie = () => {
    return JSON.parse(localStorage.getItem("product_bookmarks"));
  };
  const productBookmarksCookieFiltered = productBookmarksCookie()?.map(
    (item) => ({ products: item.id })
  );
  const shouldRefreshTokenCookie = () => {
    return StorageService.getDataByKey("shouldRefreshToken");
  };
  const getTokenCookie = () => {
    return StorageService.getDataByKey("token");
  };
  const getLanguageToken = () => {
    return localStorage.getItem("lang");
  };
  const guestUserInfoCookie = () => StorageService.getDataByKey("guest_user");
  const guestUserDeliveryInfoCookie = () =>
    StorageService.getDataByKey("guestUserDeliveryInfo");
  const productFiltersCookie = () => StorageService.getDataByKey("filters");
  const homeFiltersCookie = () => StorageService.getDataByKey("homeFilters");
  const journalFiltersCookie = () =>
    StorageService.getDataByKey("journalFilters");

  const handleTokenExpiration = (navigate) => {
    navigate("/login");
    dispatch(clearClient());
    dispatch(logOut());
    dispatch(setMiddlepartItem(null));
    dispatch(setRightPartItem(null));
    dispatch(clearCartItems());
  };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     // Converts datenow to ms with +
  //     const dateNow = +new Date();

  //     // Should call refresh token
  //     if (isLoggedIn() && dateNow > expirationTime()) {
  //       StorageService.setKeyValue("shouldRefreshToken", true);
  //     } else {
  //       StorageService.setKeyValue("shouldRefreshToken", false);
  //     }

  //     if (
  //       shouldRefreshTokenCookie() &&
  //       isLoggedIn() &&
  //       dateNow > expirationTime() + 300000
  //     ) {
  //       handleTokenExpiration(navigate);
  //     }
  //   }, 30000);
  //   return () => clearInterval(interval);
  // });

  useEffect(() => {
    if ((isLoggedIn() && client?.has_purchased) || !isLoggedIn()) return;

    const isClosedThousandMedalsPopup = StorageService.getDataByKey(
      "1kmedals_popup_closed"
    );
    const isTodayRegistered = dayjs().isSame(dayjs(client?.created_at), "day");

    if (isClosedThousandMedalsPopup) return;

    const checkPopupTime = () => {
      if (client && !client?.has_purchased && !isTodayRegistered) {
        StorageService.setKeyValue("show_1k_medals_popup", true);
      }
    };

    const timer = setInterval(checkPopupTime, 5000);

    return () => clearInterval(timer);
  }, [client]);

  useEffect(() => {
    if (isLoggedIn()) {
      dispatch(setIsLoggedIn());
      dispatch(getClientBegin());
      dispatch(fetchCountriesBegin("withAuth"));
      dispatch(getPendingDeliveryBalanceBegin());
      dispatch(getWalletTotalAmountBegin());
    } else {
      dispatch(fetchCountriesBegin("withoutAuth"));
      dispatch(clearGraphDataLoggedInState());
    }

    dispatch(getTermsAndConditionsBegin());
    dispatch(getPrivacyPolicyBegin());
    // dispatch(getEventsBegin());

    if (!isLoggedIn()) {
      dispatch(logOut());

      if (!guestUserInfoCookie() || !guestUserDeliveryInfoCookie()) {
        dispatch(clearCartItems());
      }
    }
  }, [dispatch, isLoggedIn()]);

  useEffect(() => {
    if (cartItemsStored) {
      dispatch(populateCartItems(JSON.parse(cartItemsStored)));
    }
  }, [cartItemsStored]);

  useEffect(() => {
    if (stepParam === "email-confirm" && authParam && !isLoggedIn()) {
      StorageService.setKeyValue("shouldLoginByToken", true);
    }

    const bookmarks = productBookmarksCookie();
    const languageToken = getLanguageToken();

    if (!productsPageNumberCookie) {
      StorageService.setKeyValue("products_page", 1);
    }

    if (!languageToken) {
      localStorage.setItem("lang", "en");
    }

    if (bookmarks && bookmarks.length !== 0 && isLoggedIn()) {
      dispatch(
        submitProductBookmarksStoredBegin(productBookmarksCookieFiltered)
      );
      dispatch(fetchProductBookmarksBegin(languageToken));
    }
  }, []);

  useEffect(() => {
    if (
      tokenParam &&
      !["/reset-password", "/reset-passcode"].includes(window.location.pathname)
    ) {
      dispatch(logInByToken(tokenParam));
    }
  }, [tokenParam]);

  const storedFilters = useMemo(
    () => productFiltersCookie()?.filter((item) => item?.value?.length >= 1),
    []
  );

  useEffect(() => {
    if (
      storedFilters &&
      storedFilters.length !==
        filters?.filter((item) => item?.value?.length >= 1).length
    ) {
      dispatch(populateFilters(storedFilters));
    }
  }, [storedFilters, filters, dispatch]);

  let storedHomeFilters = useMemo(
    () => homeFiltersCookie()?.filter((item) => item?.value?.length >= 1),
    [HomeFeedReducer.filters]
  );

  let storedJournalFilters = useMemo(
    () => journalFiltersCookie()?.filter((item) => item?.value?.length >= 1),
    [PersonalLedgerReducer.filters]
  );

  useEffect(() => {
    if (
      storedHomeFilters &&
      storedHomeFilters.length !==
        HomeFeedReducer?.filters?.filter((item) => item?.value?.length >= 1)
          .length
    ) {
      dispatch(populateHomeFilters(storedHomeFilters));
    }

    if (
      storedJournalFilters &&
      storedJournalFilters.length !==
        PersonalLedgerReducer?.filters?.filter(
          (item) => item?.value?.length >= 1
        ).length
    ) {
      dispatch(populateJournalFilters(storedJournalFilters));
    }
  }, [HomeFeedReducer?.filters, PersonalLedgerReducer?.filters]);

  // return ifTrue ? <Navigate to={`default`} /> : <Outlet />;
  return <Outlet />;
}
