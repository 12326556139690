const scope = "vinfinity/home";

export const CALL_AI_ASISSTANT_BEGIN = `${scope}/CALL_AI_ASISSTANT_BEGIN`;
export const CALL_AI_ASISSTANT_SUCCESS = `${scope}/CALL_AI_ASISSTANT_SUCCESS`;
export const CALL_AI_ASISSTANT_FAILED = `${scope}/CALL_AI_ASISSTANT_FAILED`;

export const GET_STREAM_FEED_BEGIN = `${scope}/GET_STREAM_FEED_BEGIN`;
export const GET_STREAM_FEED_SUCCESS = `${scope}/GET_STREAM_FEED_SUCCESS`;
export const GET_STREAM_FEED_FAILED = `${scope}/GET_STREAM_FEED_FAILED`;

export const SUBMIT_STREAM_POLL_BEGIN = `${scope}/SUBMIT_STREAM_POLL_BEGIN`;
export const SUBMIT_STREAM_POLL_SUCCESS = `${scope}/SUBMIT_STREAM_POLL_SUCCESS`;
export const SUBMIT_STREAM_POLL_FAILED = `${scope}/SUBMIT_STREAM_POLL_FAILED`;

export const CLEAR_DINO_AI_RESPONSE = `${scope}/CLEAR_DINO_AI_RESPONSE`;

export const SAVE_ASISSTANT_CHAT_TO_STREAM_BEGIN = `${scope}/SAVE_ASISSTANT_CHAT_TO_STREAM_BEGIN`;
export const SAVE_ASISSTANT_CHAT_TO_STREAM_SUCCESS = `${scope}/SAVE_ASISSTANT_CHAT_TO_STREAM_SUCCESS`;
export const SAVE_ASISSTANT_CHAT_TO_STREAM_FAILED = `${scope}/SAVE_ASISSTANT_CHAT_TO_STREAM_FAILED`;

export const SAVE_MOMENTS_PROFILE_BEGIN = `${scope}/SAVE_MOMENTS_PROFILE_BEGIN`;
export const SAVE_MOMENTS_PROFILE_SUCCESS = `${scope}/SAVE_MOMENTS_PROFILE_SUCCESS`;
export const SAVE_MOMENTS_PROFILE_FAILED = `${scope}/SAVE_MOMENTS_PROFILE_FAILED`;

export const SAVE_GENERAL_TASTE_BEGIN = `${scope}/SAVE_GENERAL_TASTE_BEGIN`;
export const SAVE_GENERAL_TASTE_SUCCESS = `${scope}/SAVE_GENERAL_TASTE_SUCCESS`;
export const SAVE_GENERAL_TASTE_FAILED = `${scope}/SAVE_GENERAL_TASTE_FAILED`;

export const CLEAR_ACTION = `${scope}/CLEAR_ACTION`;

export const GET_TASTE_PREFERENCES_BEGIN = `${scope}/GET_TASTE_PREFERENCES_BEGIN`;
export const GET_TASTE_PREFERENCES_SUCCESS = `${scope}/GET_TASTE_PREFERENCES_SUCCESS`;
export const GET_TASTE_PREFERENCES_FAILED = `${scope}/GET_TASTE_PREFERENCES_FAILED`;

export const GET_CARD_CATEGORIES_BEGIN = `${scope}/GET_CARD_CATEGORIES_BEGIN`;
export const GET_CARD_CATEGORIES_SUCCESS = `${scope}/GET_CARD_CATEGORIES_SUCCESS`;
export const GET_CARD_CATEGORIES_FAILED = `${scope}/GET_CARD_CATEGORIES_FAILED`;

export const SET_HOME_FILTERS = `${scope}/SET_HOME_FILTERS`;
export const POPULATE_HOME_FILTERS = `${scope}/POPULATE_HOME_FILTERS`;
export const CLEAR_HOME_FILTERS = `${scope}/CLEAR_HOME_FILTERS`;

export const LIKE_OR_DISLIKE_CHAT_BEGIN = `${scope}/LIKE_OR_DISLIKE_CHAT_BEGIN`;
export const LIKE_OR_DISLIKE_CHAT_SUCCESS = `${scope}/LIKE_OR_DISLIKE_CHAT_SUCCESS`;
export const LIKE_OR_DISLIKE_CHAT_FAILED = `${scope}/LIKE_OR_DISLIKE_CHAT_FAILED`;

export const VOTE_UNSAVED_CHAT_BEGIN = `${scope}/VOTE_UNSAVED_CHAT_BEGIN`;
export const VOTE_UNSAVED_CHAT_SUCCESS = `${scope}/VOTE_UNSAVED_CHAT_SUCCESS`;
export const VOTE_UNSAVED_CHAT_FAILED = `${scope}/VOTE_UNSAVED_CHAT_FAILEDs`;

export const GET_CARD_BY_ID_BEGIN = `${scope}/GET_CARD_BY_ID_BEGIN`;
export const GET_CARD_BY_ID_SUCCESS = `${scope}/GET_CARD_BY_ID_SUCCESS`;
export const GET_CARD_BY_ID_FAILED = `${scope}/GET_CARD_BY_ID_FAILED`;

export const SUBMIT_CLIENT_PROS_AND_CONS_BEGIN = `${scope}/SUBMIT_CLIENT_PROS_AND_CONS_BEGIN`;
export const SUBMIT_CLIENT_PROS_AND_CONS_SUCCCESS = `${scope}/SUBMIT_CLIENT_PROS_AND_CONS_SUCCCESS`;
export const SUBMIT_CLIENT_PROS_AND_CONS_FAILED = `${scope}/SUBMIT_CLIENT_PROS_AND_CONS_FAILED`;
