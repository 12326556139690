import {
  BackButtonIcon,
  FifthRateWineEmoji,
  FirstRateWineEmoji,
  FourthRateWineEmoji,
  SecondRateWineEmoji,
  SelectedOptionArrow,
  ThirdRateWineEmoji,
} from "assets/icons/icons";
import styles from "./RateWine.module.scss";
import { useTranslation } from "react-i18next";
import ButtonComponent from "components/Button/Button.component";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  getJournalDataBegin,
  rateAddedWineBegin,
  rateWineBegin,
} from "pages/PersonalLedger/actions";
import { GlobalIState } from "types/RootState";
import {
  RATE_ADDED_WINE_SUCCESS,
  RATE_WINE_SUCCESS,
} from "pages/PersonalLedger/constants";
import { openToaster, scrollToTop } from "App/actions";
import StorageService from "services/storage.service";
import { modifyHomeFiltersForDate } from "utils/utils";

interface Props {
  setActiveTab?: any;
  product_id: string;
}

function RateWine(props: Props) {
  const {
    action: { actionName, isLoading },
    journalWines,
    filters,
  } = useAppSelector((state: GlobalIState) => state.PersonalLedgerReducer);
  const dispatch = useAppDispatch();
  const { t: translations } = useTranslation();
  const { setActiveTab, product_id } = props;
  const [selectedOption, setSelectedOption] = useState("");

  const item: any = journalWines?.filter(
    (item) => item.product?.id === Number(product_id)
  );
  const journalSearchCookie = () =>
    StorageService.getDataByKey("journalSearch");

  const rateOptions = [
    { id: "1", icon: <FirstRateWineEmoji /> },
    { id: "2", icon: <SecondRateWineEmoji /> },
    { id: "3", icon: <ThirdRateWineEmoji /> },
    { id: "4", icon: <FourthRateWineEmoji /> },
    { id: "5", icon: <FifthRateWineEmoji /> },
  ];

  const handleChange = (id: string) => {
    setSelectedOption(id);
  };

  const handleSubmit = () => {
    if (selectedOption !== "") {
      if (item && item[0]?.product?.inputted_data) {
        dispatch(
          rateAddedWineBegin({
            product_id: product_id,
            rating: Number(selectedOption),
          })
        );
      } else {
        dispatch(
          rateWineBegin({
            product_id: product_id,
            rating: Number(selectedOption),
          })
        );
      }
    } else
      dispatch(openToaster(translations("label.please_select_one_option")));
  };

  useEffect(() => {
    if ([RATE_WINE_SUCCESS, RATE_ADDED_WINE_SUCCESS].includes(actionName)) {
      setActiveTab("tasting");

      dispatch(scrollToTop());
    }
  }, [actionName]);

  useEffect(() => {
    if ([RATE_ADDED_WINE_SUCCESS, RATE_WINE_SUCCESS].includes(actionName)) {
      dispatch(
        getJournalDataBegin({
          search: journalSearchCookie() ? journalSearchCookie() : "",
          filters:
            filters?.filter((item) => item?.value?.length >= 1)?.length === 0
              ? []
              : modifyHomeFiltersForDate(
                  filters?.filter((item) => item?.value?.length >= 1)
                )?.filters,
        })
      );
    }
  }, [actionName]);

  useEffect(() => {
    if (item?.length > 0) {
      const rating =
        item[0]?.product?.ratings && item[0]?.product?.ratings.length > 0
          ? item[0].product.ratings[0].rating
          : null;

      setSelectedOption(rating?.toString());
    }
  }, [product_id]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.rateContent}>
        <div className={styles.title}>
          <h1>{translations("label.rate_the_wine")}</h1>
        </div>
        <div className={styles.options}>
          {rateOptions?.map((item) => (
            <div
              key={item.id}
              className={`${styles.option} ${
                selectedOption === item.id ? styles.activeOption : ""
              }`}
              onClick={() => handleChange(item.id)}
            >
              {selectedOption === item.id ? (
                <div className={styles.icon}>
                  <SelectedOptionArrow />
                </div>
              ) : null}
              {item?.icon}
            </div>
          ))}
        </div>
        <div>
          <ButtonComponent
            label={translations("button.submit")}
            class={"btn btn-primary"}
            isLoading={isLoading}
            disabled={!selectedOption}
            handleClick={() => handleSubmit()}
          />
        </div>
      </div>
    </div>
  );
}

export default RateWine;
