import { useTranslation } from "react-i18next";
import styles from "./AlternativeAuthMethods.module.scss";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks";

import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import { GlobalIState } from "types/RootState";

import { AuthPhoneIcon, GmailIcon, PreviousIcon } from "assets/icons/icons";
import { getDefaultColour, getGoogleUrl } from "utils/utils";
import { useEffect } from "react";
import { logInBegin } from "../actions";
import StorageService from "services/storage.service";
import { LOGIN_DEVICE } from "utils/enums";

export default function AlternativeAuthMethods() {
  const {
    action: { actionName, isLoading, error },
  } = useAppSelector((state: GlobalIState) => state.authenticationReducer);
  const { client, defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();
  let isMobile = useCheckMobileScreen();
  const [searchParams] = useSearchParams();
  const typeParam = searchParams.get("type");
  const location = useLocation();
  let from = ((location.state as any)?.from?.pathname as string) || "/";
  const googleAuthCodeParam = searchParams.get("code");

  const isLoggedIn = () => StorageService.getDataByKey("token");

  const handleLoginByGoogle = () => {
    return window.open(getGoogleUrl(from), "_self");
  };

  useEffect(() => {
    if (googleAuthCodeParam) {
      const data = {
        user: "",
        password: "",
        country_phone: "",
        phone: "",
        google_auth_code: googleAuthCodeParam,
        login_method: "google",
        two_factor_code: "",
        sms_code: "0",
        last_login_device: LOGIN_DEVICE.WEB_APP,
      };

      dispatch(logInBegin(data));
    }
  }, [googleAuthCodeParam]);

  useEffect(() => {
    if (isLoggedIn()) {
      navigate("/");
    }
  }, [isLoggedIn()]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div
          className={styles.previousIcon}
          onClick={() => {
            navigate(-1);
          }}
        >
          <PreviousIcon color={getDefaultColour(defaultColour)} />
        </div>
      </div>

      <div className={styles.content}>
        <h1>
          {translations(
            typeParam === "signup"
              ? "label.select_alternative_signup_method"
              : "label.select_alternative_login_method"
          )}
        </h1>
        {typeParam === "signup" ? (
          <div
            className={styles.card}
            onClick={() => navigate("/signup/phone")}
          >
            <span>{translations("label.signup_with_phone")}</span>
            <AuthPhoneIcon />
          </div>
        ) : (
          <div className={styles.card} onClick={() => navigate("/login/phone")}>
            <span>{translations("label.login_with_phone")}</span>
            <AuthPhoneIcon />
          </div>
        )}
        {typeParam === "signup" ? (
          <div className={styles.card} onClick={() => handleLoginByGoogle()}>
            <span>{translations("label.signup_with_gmail")}</span>
            <GmailIcon />
          </div>
        ) : (
          <div className={styles.card} onClick={() => handleLoginByGoogle()}>
            <span>{translations("label.login_with_gmail")}</span>
            <GmailIcon />
          </div>
        )}
      </div>
    </div>
  );
}
