import { useAppDispatch, useAppSelector } from "hooks";
import { useState, useRef, useEffect } from "react"; // Import useRef and useEffect
import { GlobalIState } from "types/RootState";

import styles from "./StreamMultiSelectDropdown.module.scss";
import { ArrowDownIcon } from "assets/icons/icons";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";

import StorageService from "services/storage.service";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getDefaultColour, getFilterValue } from "utils/utils";
import { setHomeFilters } from "pages/Home/actions";

interface Props {
  label: string;
  options: string[];
  type: string;
}

export default function StreamMultiSelectDropdown(props: Props) {
  const { label, options, type } = props;
  const { defaultColour, client } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const { filters } = useAppSelector(
    (state: GlobalIState) => state.HomeFeedReducer
  );

  const dispatch = useAppDispatch();
  const { t: translations } = useTranslation();
  const isMobile = useCheckMobileScreen();

  const [selectedOptions, setSelectedOptions] = useState<any[]>([
    ...(getFilterValue("Categories", filters) ?? []),
  ]);
  const dropdownRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const searchValueCookie = StorageService.getDataByKey("search");
  const optionsString = selectedOptions?.join(", ") || "";

  const getLanguageToken = () => {
    return localStorage.getItem("lang");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleToggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleCheckboxChange = (option) => {
    if (selectedOptions?.includes(option)) {
      setSelectedOptions(selectedOptions?.filter((item) => item !== option));
    } else {
      setSelectedOptions([...(selectedOptions ?? []), option]);
    }

    dispatch(
      setHomeFilters({
        filter: "Categories",
        value: selectedOptions?.includes(option)
          ? selectedOptions?.filter((item) => item !== option)
          : [...(selectedOptions ?? []), option],
      })
    );
  };

  useEffect(() => {
    setSelectedOptions(getFilterValue("Categories", filters));
  }, [filters]);

  return (
    <div
      ref={dropdownRef}
      style={
        {
          "--color": getDefaultColour(defaultColour),
          height: isMobile
            ? !isDropdownOpen && selectedOptions?.length > 0
              ? 72
              : 40
            : 30,
          width:
            window.location.pathname.includes("/home") && !isMobile
              ? 190
              : isMobile
              ? "100%"
              : 120,
        } as React.CSSProperties
      }
      className={styles.multiSelectDropdownContainer}
    >
      <div className={styles.dropdownLabel} onClick={handleToggleDropdown}>
        <span>{label}</span>
        <ArrowDownIcon color={"#000"} />
      </div>
      {!isDropdownOpen && isMobile && selectedOptions?.length > 0 ? (
        <div className={styles.selectedLabels}>
          {optionsString.length >= 35
            ? optionsString.slice(0, 35 - 3) + "..."
            : optionsString}
        </div>
      ) : null}

      {isDropdownOpen && (
        <div className={styles.dropdownContent}>
          <div className={styles.filters}>
            {options?.map((option) => (
              <Tooltip
                title={option?.length >= (isMobile ? 25 : 15) ? option : ""}
                key={option}
              >
                <label key={option}>
                  <input
                    type="checkbox"
                    value={option}
                    checked={selectedOptions?.includes(option)}
                    onChange={() => handleCheckboxChange(option)}
                  />
                  {option?.length >= (isMobile ? 25 : 15) && type !== "Price"
                    ? option.substring(0, isMobile ? 25 : 15) + "..."
                    : option}
                </label>
              </Tooltip>
            ))}
          </div>
          <div
            className={styles.closeFilterBtn}
            onClick={() => handleToggleDropdown()}
          >
            <span>{translations("button.close")}</span>
          </div>
        </div>
      )}
    </div>
  );
}
