import { useTranslation } from "react-i18next";

import styles from "./FirstPurchaseBonusPage.module.scss";

import { useAppDispatch, useAppSelector } from "hooks";

import { GlobalIState } from "types/RootState";

import { useNavigate } from "react-router-dom";
import LoaderWrapper from "components/Loader/LoaderWrapper/LoaderWrapper";
import ButtonComponent from "components/Button/Button.component";
import { EarnedBonusIcon } from "assets/icons/icons";
import { getDefaultColour } from "utils/utils";
import StorageService from "services/storage.service";

interface Props {}

export default function FirstPurchaseBonusPage(props: Props) {
  const {
    action: { isLoading },
    defaultColour,
  } = useAppSelector((state: GlobalIState) => state.ProfilePageReducer);
  const navigate = useNavigate();
  const { t: translations } = useTranslation();
  const lastRouteCookie = StorageService.getDataByKey("lastRouteCookie");

  const handleNext = () => {
    localStorage.removeItem("orderData");
    navigate("/home");
  };

  return (
    <div
      className={styles.bonusPageWrapper}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      <LoaderWrapper isLoading={isLoading}>
        <div className={styles.content}>
          <div>
            <h1>{translations("label.first_purchase.title")}</h1>
          </div>

          <div className={styles.icon}>
            <EarnedBonusIcon />
          </div>
          <div className={styles.label}>
            <span>{translations("label.first_purchase.subtitle")}</span>
          </div>
          <div className={styles.button}>
            <ButtonComponent
              label={translations("button.lets_go")}
              class={"btn btn-primary"}
              handleClick={() => handleNext()}
            />
          </div>
        </div>
      </LoaderWrapper>
    </div>
  );
}
