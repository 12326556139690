import {
  ISendOrderInvoiceAsGuest,
  OrderAsGuestInterface,
  OrderInterface,
  UpdateDeliveryAndBillingAddressInterface,
} from "types/pages/purchasing.page.type";
import axiosInstance from "./api";

export const fetchProducts = (payload: {
  page: number;
  search?: string;
  filters?: any[];
  lang: string;
  type: string;
}) => {
  return axiosInstance.post(
    `/products/${payload.type}/${payload.page}/${
      payload.search.length > 1 ? payload.search : ","
    }/${payload.lang}`,
    { filters: payload.filters }
  );
};

export const fetchProductDetails = (payload: {
  product_id: number;
  lang: string;
}) => {
  return axiosInstance.get(
    `/products/details/${payload.product_id}/${payload.lang}`
  );
};

export const updateDeliveryAndBillingAddress = (
  data: UpdateDeliveryAndBillingAddressInterface
) => {
  return axiosInstance.put("/clients/billing-address", data);
};

export const order = (data: OrderInterface) => {
  return axiosInstance.put("/orders/purchase", data);
};

export const orderAsGuest = (data: OrderAsGuestInterface) => {
  return axiosInstance.put("/orders/guest-purchase", data);
};

export const sendOrderInvoice = (order_id: string) => {
  return axiosInstance.put("/orders/send-invoice-email", {
    order_id: order_id,
  });
};

export const sendGuestOrderInvoice = (payload: ISendOrderInvoiceAsGuest) => {
  return axiosInstance.put("/orders/send-guest-invoice-email", payload);
};

export const getCustomerPortal = () => {
  return axiosInstance.get("/orders/client/costumer-portal");
};

export const getPurchasingFilters = (lang: string) => {
  return axiosInstance.get(`/products/filters/${lang}`);
};

export const getRegionsFilter = (data) => {
  return axiosInstance.post(`/products/countries/${data.lang}`, {
    countries: data.countries,
  });
};

export const getAppellationsFilter = (data) => {
  return axiosInstance.post(`/products/countries/regions/${data.lang}`, {
    countries: data.countries,
    regions: data.regions,
  });
};

export const toggleBookmarkProduct = (product_id: string) => {
  return axiosInstance.post("/products/toggle/my-products/bookmark", {
    product_id: product_id,
  });
};

export const getProductBookmarks = (lang: string) => {
  return axiosInstance.get(`/products/bookmarks/mine/${lang}`);
};

export const submitProductBookmarks = (data) => {
  return axiosInstance.post(
    "/products/toggle/my-products/not-signed/bookmark",
    { products: data }
  );
};

export const fetchProductsCount = (payload: {
  search?: string;
  filters?: any[];
}) => {
  return axiosInstance.post(
    `/products/count/${payload.search.length > 0 ? payload.search : ","}`,
    { filters: payload.filters }
  );
};

export const getLastOrder = () => {
  return axiosInstance.get(`/orders/client/last-order`);
};

export const getGuestLastOrder = (guestEmail: string) => {
  return axiosInstance.get(
    `/orders/guest/last-order/${decodeURI(guestEmail.replace(" ", "+"))}`
  );
};

export const getAdProductData = (data: {
  product_id: string;
  lang: string;
}) => {
  return axiosInstance.get(`/products/ads/${data.product_id}/${data.lang}`);
};

export const getNeckTies = () => {
  return axiosInstance.get("/orders/get-all/neckties");
};

export const getWinesByCountry = (data: { country: string; lang: string }) => {
  return axiosInstance.post(`/products/promoted/${data.lang}`, {
    country: data.country,
  });
};

export const getMagnumWines = (lang: string) => {
  return axiosInstance.get(`/products/promotion/${lang}`);
};

export const getShowcasedProduct = (lang: string) => {
  return axiosInstance.get(`/products/map/promotion/${lang}`);
};

export const getOneHourDeliveryProducts = (lang: string) => {
  return axiosInstance.get(`/products/promoted/one-hour/delivery/${lang}`);
};
