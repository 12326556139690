const scope = "vinfinity/personal-ledger";

export const CLEAR_ACTION = `${scope}/CLEAR_ACTION`;

export const GET_USER_ORDERS_BEGIN = `${scope}/GET_USER_ORDERS_BEGIN`;
export const GET_USER_ORDERS_FAILED = `${scope}/GET_USER_ORDERS_FAILED`;
export const GET_USER_ORDERS_SUCCESS = `${scope}/GET_USER_ORDERS_SUCCESS`;

export const RATE_WINE_BEGIN = `${scope}/RATE_WINE_BEGIN`;
export const RATE_WINE_FAILED = `${scope}/RATE_WINE_FAILED`;
export const RATE_WINE_SUCCESS = `${scope}/RATE_WINE_SUCCESS`;

export const RATE_ADDED_WINE_BEGIN = `${scope}/RATE_ADDED_WINE_BEGIN`;
export const RATE_ADDED_WINE_FAILED = `${scope}/RATE_ADDED_WINE_FAILED`;
export const RATE_ADDED_WINE_SUCCESS = `${scope}/RATE_ADDED_WINE_SUCCESS`;

export const SET_PRODUCT_TASTE_BEGIN = `${scope}/SET_PRODUCT_TASTE_BEGIN`;
export const SET_PRODUCT_TASTE_FAILED = `${scope}/SET_PRODUCT_TASTE_FAILED`;
export const SET_PRODUCT_TASTE_SUCCESS = `${scope}/SET_PRODUCT_TASTE_SUCCESS`;

export const SET_ADDED_WINE_TASTE_BEGIN = `${scope}/SET_ADDED_WINE_TASTE_BEGIN`;
export const SET_ADDED_WINE_TASTE_FAILED = `${scope}/SET_ADDED_WINE_TASTE_FAILED`;
export const SET_ADDED_WINE_TASTE_SUCCESS = `${scope}/SET_ADDED_WINE_TASTE_SUCCESS`;

export const GET_PRODUCT_TRANSACTIONS_BEGIN = `${scope}/GET_PRODUCT_TRANSACTIONS_BEGIN`;
export const GET_PRODUCT_TRANSACTIONS_FAILED = `${scope}/GET_PRODUCT_TRANSACTIONS_FAILED`;
export const GET_PRODUCT_TRANSACTIONS_SUCCESS = `${scope}/GET_PRODUCT_TRANSACTIONS_SUCCESS`;

export const GET_USER_BLIND_TASTINGS_BEGIN = `${scope}/GET_USER_BLIND_TASTINGS_BEGIN`;
export const GET_USER_BLIND_TASTINGS_FAILED = `${scope}/GET_USER_BLIND_TASTINGS_FAILED`;
export const GET_USER_BLIND_TASTINGS_SUCCESS = `${scope}/GET_USER_BLIND_TASTINGS_SUCCESS`;

export const ADD_WINE_BEGIN = `${scope}/ADD_WINE_BEGIN`;
export const ADD_WINE_FAILED = `${scope}/ADD_WINE_FAILED`;
export const ADD_WINE_SUCCESS = `${scope}/ADD_WINE_SUCCESS`;

export const EDIT_ADDED_WINE_BEGIN = `${scope}/EDIT_ADDED_WINE_BEGIN`;
export const EDIT_ADDED_WINE_FAILED = `${scope}/EDIT_ADDED_WINE_FAILED`;
export const EDIT_ADDED_WINE_SUCCESS = `${scope}/EDIT_ADDED_WINE_SUCCESS`;

export const GET_ADDED_WINES_BEGIN = `${scope}/GET_ADDED_WINES_BEGIN`;
export const GET_ADDED_WINES_FAILED = `${scope}/GET_ADDED_WINES_FAILED`;
export const GET_ADDED_WINES_SUCCESS = `${scope}/GET_ADDED_WINES_SUCCESS`;

export const REQUEST_TO_SUPPLY_WINE_BEGIN = `${scope}/REQUEST_TO_SUPPLY_WINE_BEGIN`;
export const REQUEST_TO_SUPPLY_WINE_FAILED = `${scope}/REQUEST_TO_SUPPLY_WINE_FAILED`;
export const REQUEST_TO_SUPPLY_WINE_SUCCESS = `${scope}/REQUEST_TO_SUPPLY_WINE_SUCCESS`;

export const GET_JOURNAL_DATA_BEGIN = `${scope}/GET_JOURNAL_DATA_BEGIN`;
export const GET_JOURNAL_DATA_FAILED = `${scope}/GET_JOURNAL_DATA_FAILED`;
export const GET_JOURNAL_DATA_SUCCESS = `${scope}/GET_JOURNAL_DATA_SUCCESS`;

export const DELETE_ADDED_WINE_BEGIN = `${scope}/DELETE_ADDED_WINE_BEGIN`;
export const DELETE_ADDED_WINE_FAILED = `${scope}/DELETE_ADDED_WINE_FAILED`;
export const DELETE_ADDED_WINE_SUCCESS = `${scope}/DELETE_ADDED_WINE_SUCCESS`;

export const SET_JOURNAL_FILTERS = `${scope}/SET_JOURNAL_FILTERS`;
export const POPULATE_JOURNAL_FILTERS = `${scope}/POPULATE_JOURNAL_FILTERS`;
export const CLEAR_JOURNAL_FILTERS = `${scope}/CLEAR_JOURNAL_FILTERS`;

export const GET_JOURNAL_CATEGORIES_BEGIN = `${scope}/GET_JOURNAL_CATEGORIES_BEGIN`;
export const GET_JOURNAL_CATEGORIES_FAILED = `${scope}/GET_JOURNAL_CATEGORIES_FAILED`;
export const GET_JOURNAL_CATEGORIES_SUCCESS = `${scope}/GET_JOURNAL_CATEGORIES_SUCCESS`;

export const GET_ADDED_WINE_PRODUCT_DETAILS_BEGIN = `${scope}/GET_ADDED_WINE_PRODUCT_DETAILS_BEGIN`;
export const GET_ADDED_WINE_PRODUCT_DETAILS_FAILED = `${scope}/GET_ADDED_WINE_PRODUCT_DETAILS_FAILED`;
export const GET_ADDED_WINE_PRODUCT_DETAILS_SUCCESS = `${scope}/GET_ADDED_WINE_PRODUCT_DETAILS_SUCCESS`;

export const EVALUATE_JOURNAL_WINE_BEGIN = `${scope}/EVALUATE_JOURNAL_WINE_BEGIN`;
export const EVALUATE_JOURNAL_WINE_FAILED = `${scope}/EVALUATE_JOURNAL_WINE_FAILED`;
export const EVALUATE_JOURNAL_WINE_SUCCESS = `${scope}/EVALUATE_JOURNAL_WINE_SUCCESS`;
