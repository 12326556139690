import React, { useEffect, useState } from "react";
import styles from "./JournalMobileFilters.module.scss";
import { useTranslation } from "react-i18next";
import { getDefaultColour, modifyHomeFiltersForDate } from "utils/utils";
import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import { useNavigate } from "react-router-dom";
import { CloseFirstTypeIcon, SearchIcon } from "assets/icons/icons";

import StorageService from "services/storage.service";
import JournalMultiSelectDropdown from "components/JournalMultiSelectDropdown/JournalMultiSelectDropdown.component";
import JournalDateRangeFilter from "components/JournalDateRangeFilter/JournalDateRangeFilter.component";
import {
  clearAction,
  clearJournalFilters,
  getJournalCategoriesBegin,
  getJournalDataBegin,
  setJournalFilters,
} from "../actions";
import { GET_JOURNAL_DATA_SUCCESS } from "../constants";

const JournalMobileFilters: React.FC = () => {
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const {
    action: { actionName },
    categories,
    filters,
  } = useAppSelector((state: GlobalIState) => state.PersonalLedgerReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();
  const [fromDate, setFromDate] = useState("");
  const [untilDate, setUntilDate] = useState("");
  const [search, setSearch] = useState("");
  const [applyFilter, setApplyFilter] = useState(false);
  const journalSearchCookie = () =>
    StorageService.getDataByKey("journalSearch");

  const handleDateChange = (from: any, until: any) => {
    setFromDate(from);
    setUntilDate(until);
  };

  const handleResetFilters = () => {
    dispatch(clearJournalFilters());
    StorageService.deleteCookie("journalSearch");
    StorageService.deleteCookie("journalFilters");
    setFromDate("");
    setUntilDate("");
    navigate("/journal");
  };

  const handleSearch = (value: string) => {
    StorageService.setKeyValue("journalSearch", value);
    setSearch(value);
  };

  const handleApplyFilters = () => {
    setApplyFilter(true);

    dispatch(
      setJournalFilters({
        filter: "DateRangeJournal",
        value:
          fromDate === "" && untilDate === ""
            ? []
            : untilDate === ""
            ? [fromDate, fromDate]
            : [fromDate, untilDate],
      })
    );

    setTimeout(() => {
      dispatch(
        getJournalDataBegin({
          search: journalSearchCookie() ? journalSearchCookie() : "",
          filters:
            filters?.filter((item) => item?.value?.length >= 1)?.length === 0
              ? []
              : modifyHomeFiltersForDate(
                  filters?.filter((item) => item?.value?.length >= 1)
                )?.filters,
        })
      );
    }, 100);
  };

  useEffect(() => {
    if (actionName === GET_JOURNAL_DATA_SUCCESS && applyFilter) {
      setApplyFilter(false);
      StorageService.deleteCookie("journalSearch");
      dispatch(clearAction());
      navigate("/journal");
    }
  }, [actionName]);

  useEffect(() => {
    if (!categories) dispatch(getJournalCategoriesBegin());
  }, [categories]);

  useEffect(() => {
    return () => {
      StorageService.deleteCookie("journalSearch");
    };
  }, []);

  return (
    <div
      className={styles.wrapper}
      style={
        { "--color": getDefaultColour(defaultColour) } as React.CSSProperties
      }
    >
      <div className={styles.header} onClick={() => navigate("/journal")}>
        <CloseFirstTypeIcon color={getDefaultColour(defaultColour)} />
      </div>

      <div className={styles.filtersContainer}>
        <div className={styles.content}>
          <div>
            <h4>{translations("success.filters")}</h4>
            <div className={styles.lSearch}>
              <input
                type="text"
                className={styles.searchInp}
                placeholder={translations("label.search")}
                // onBlur={(e) => setIsLedgerSearching(false)}
                // onFocus={(e) => setIsLedgerSearching(true)}
                onChange={(e) => handleSearch(e.target.value)}
              />
              <SearchIcon color="#000" />
            </div>
            <JournalMultiSelectDropdown
              type="Categories"
              label={translations("label.category")}
              options={categories}
            />
            <JournalDateRangeFilter onDateChange={handleDateChange} />
            <JournalMultiSelectDropdown
              type="Sort"
              label={translations("label.sort")}
              options={[
                translations("label.highest_rating_to_lowest"),
                translations("label.highest_value_for_money_to_lowest"),
                translations("label.newest_addition_to_oldest"),
                translations("label.oldest_addition_to_newest"),
              ]}
            />
          </div>
          <div className={styles.actions}>
            <div onClick={() => handleResetFilters()}>
              <span>{translations("button.reset")}</span>
            </div>
            <div onClick={() => handleApplyFilters()}>
              <span>{translations("label.apply")}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JournalMobileFilters;
