import { openToaster } from "App/actions";
import { call, put, takeLatest } from "redux-saga/effects";
import * as homefeedService from "services/homefeed.service";

import {
  getStreamFeedFailed,
  getStreamFeedSuccess,
  callAIQuestionSuccess,
  callAIQuestionFailed,
  submitStreamPollFailed,
  submitStreamPollSuccess,
  saveAsisstantChatToStreamSuccess,
  saveAsisstantChatToStreamFailed,
  saveMomentsProfileSuccess,
  saveMomentsProfileFailed,
  saveGeneralTasteSuccess,
  saveGeneralTasteFailed,
  getTastePreferencesSuccess,
  getTastePreferencesFailed,
  getCardCategoriesSuccess,
  getCardCategoriesFailed,
  likeOrDislikeChatSuccess,
  likeOrDislikeChatFailed,
  getCardByIdSuccess,
  getCardByIdFailed,
  voteUnsavedChatSuccess,
  voteUnsavedChatFailed,
  submitClientProsAndConsSuccess,
  submitClientProsAndConsFailed,
} from "./actions";
import {
  GET_STREAM_FEED_BEGIN,
  CALL_AI_ASISSTANT_BEGIN,
  SUBMIT_STREAM_POLL_BEGIN,
  SAVE_ASISSTANT_CHAT_TO_STREAM_BEGIN,
  SAVE_MOMENTS_PROFILE_BEGIN,
  SAVE_GENERAL_TASTE_BEGIN,
  GET_TASTE_PREFERENCES_BEGIN,
  GET_CARD_CATEGORIES_BEGIN,
  LIKE_OR_DISLIKE_CHAT_BEGIN,
  GET_CARD_BY_ID_BEGIN,
  VOTE_UNSAVED_CHAT_BEGIN,
  SUBMIT_CLIENT_PROS_AND_CONS_BEGIN,
} from "./constants";
import axios from "axios";
import StorageService from "services/storage.service";

export function* callAIAsisstant(action) {
  try {
    const response = yield call(
      homefeedService.callAIAsisstant,
      action.payload
    );
    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(callAIQuestionSuccess(response?.data?.data));
    } else {
      yield put(callAIQuestionFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(callAIQuestionFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* getStreamFeed(action) {
  try {
    const response = yield call(homefeedService.getStreamFeed, action.payload);

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(getStreamFeedSuccess(response?.data?.data));
    } else {
      yield put(getStreamFeedFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(getStreamFeedFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* submitStreamPoll(action) {
  try {
    const response = yield call(
      homefeedService.submitStreamPoll,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(submitStreamPollSuccess(response?.data?.data));
    } else {
      yield put(submitStreamPollFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(submitStreamPollFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* saveAsisstantChatToStream(action) {
  try {
    const response = yield call(
      homefeedService.saveAsisstantChatToStream,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(saveAsisstantChatToStreamSuccess(response?.data?.data));
    } else {
      yield put(saveAsisstantChatToStreamFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(saveAsisstantChatToStreamFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* saveMomentsProfile(action) {
  try {
    const response = yield call(
      homefeedService.saveMomentsProfile,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(saveMomentsProfileSuccess(response?.data?.data));
    } else {
      yield put(saveMomentsProfileFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(saveMomentsProfileFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* saveGeneralTaste(action) {
  try {
    const response = yield call(
      homefeedService.saveGeneralTaste,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(saveGeneralTasteSuccess(response?.data?.data));
    } else {
      yield put(saveGeneralTasteFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(saveGeneralTasteFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* getTastePreferences(action) {
  try {
    const response = yield call(homefeedService.getTastePreferences);

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(getTastePreferencesSuccess(response?.data?.data));
    } else {
      yield put(getTastePreferencesFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(getTastePreferencesFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* getCardCategories(action) {
  try {
    const response = yield call(homefeedService.gertCardCategories);

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(getCardCategoriesSuccess(response?.data?.data));
    } else {
      yield put(getCardCategoriesFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(getCardCategoriesFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* likeOrDislikeChat(action) {
  try {
    const response = yield call(
      homefeedService.likeOrDislikeChat,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(likeOrDislikeChatSuccess(response?.data?.data));
    } else {
      yield put(likeOrDislikeChatFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(likeOrDislikeChatFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* voteUnsavedChat(action) {
  try {
    const response = yield call(
      homefeedService.voteUnsavedChat,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(voteUnsavedChatSuccess(response?.data?.data));
    } else {
      yield put(voteUnsavedChatFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(voteUnsavedChatFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* getCardById(action) {
  try {
    const response = yield call(homefeedService.getCardById, action.payload);

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(getCardByIdSuccess(response?.data?.data));
    } else {
      yield put(getCardByIdFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(getCardByIdFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* submitProsAndCons(action) {
  try {
    const response = yield call(
      homefeedService.submitProsAndCons,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(submitClientProsAndConsSuccess(response?.data?.data));
    } else {
      yield put(submitClientProsAndConsFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(submitClientProsAndConsFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export default function* homefeedSaga() {
  yield takeLatest(CALL_AI_ASISSTANT_BEGIN, callAIAsisstant);
  yield takeLatest(GET_STREAM_FEED_BEGIN, getStreamFeed);
  yield takeLatest(SUBMIT_STREAM_POLL_BEGIN, submitStreamPoll);
  yield takeLatest(
    SAVE_ASISSTANT_CHAT_TO_STREAM_BEGIN,
    saveAsisstantChatToStream
  );
  yield takeLatest(SAVE_MOMENTS_PROFILE_BEGIN, saveMomentsProfile);
  yield takeLatest(SAVE_GENERAL_TASTE_BEGIN, saveGeneralTaste);
  yield takeLatest(GET_TASTE_PREFERENCES_BEGIN, getTastePreferences);
  yield takeLatest(GET_CARD_CATEGORIES_BEGIN, getCardCategories);
  yield takeLatest(LIKE_OR_DISLIKE_CHAT_BEGIN, likeOrDislikeChat);
  yield takeLatest(VOTE_UNSAVED_CHAT_BEGIN, voteUnsavedChat);
  yield takeLatest(GET_CARD_BY_ID_BEGIN, getCardById);
  yield takeLatest(SUBMIT_CLIENT_PROS_AND_CONS_BEGIN, submitProsAndCons);
}
