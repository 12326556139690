import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import styles from "./LedgerDetails.module.scss";
import { BackButtonIcon } from "assets/icons/icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getDefaultColour, modifyHomeFiltersForDate } from "utils/utils";
import { useTranslation } from "react-i18next";
import AddedWinePlace from "../AddedWinePlace/AddedWinePlace.page";
import MobilePurchasedQuantity from "../MobilePurchasedQuantity/MobilePurchasedQuantity.page";
import { useEffect } from "react";
import { getJournalDataBegin } from "pages/PersonalLedger/actions";
import StorageService from "services/storage.service";

function LedgerDetails() {
  const {
    action: { isLoading },
    journalWines,
    filters,
  } = useAppSelector((state: GlobalIState) => state.PersonalLedgerReducer);
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();
  const [searchParams] = useSearchParams();
  const productIdParam = searchParams.get("id");
  const productOrder = journalWines?.find(
    (item) => item?.product?.id === Number(productIdParam)
  );
  const journalSearchCookie = () =>
    StorageService.getDataByKey("journalSearch");

  useEffect(() => {
    dispatch(
      getJournalDataBegin({
        search: journalSearchCookie() ? journalSearchCookie() : "",
        filters:
          filters?.filter((item) => item?.value?.length >= 1)?.length === 0
            ? []
            : modifyHomeFiltersForDate(
                filters?.filter((item) => item?.value?.length >= 1)
              )?.filters,
      })
    );
  }, [filters]);

  return (
    <div
      className={styles.wrapper}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      <div className={styles.header}>
        <div className="pointer" onClick={() => navigate("/journal")}>
          <BackButtonIcon color={"#000"} />
        </div>
        <div className={styles.tabs}>
          <div
            className={styles.tab}
            onClick={() =>
              navigate(`/journal/product-details?id=${productIdParam}`)
            }
          >
            <span>{translations("label.info")}</span>
          </div>
          <div
            className={styles.tab}
            style={{ borderRadius: 0 }}
            onClick={() =>
              navigate(`/journal/rate-and-taste?id=${productIdParam}`)
            }
          >
            <span>{translations("label.tasting")}</span>
          </div>
          <div
            className={styles.activeTab}
            onClick={() => navigate(`/journal/ledger?id=${productIdParam}`)}
          >
            <span>
              {translations(
                productOrder?.product?.inputted_data
                  ? "label.place"
                  : "label.ledger"
              )}
            </span>
          </div>
        </div>
      </div>

      {productOrder?.product?.inputted_data ? (
        <AddedWinePlace />
      ) : (
        <MobilePurchasedQuantity />
      )}
    </div>
  );
}

export default LedgerDetails;
