import { produce } from "immer";
import { PersonalLedgerInterface } from "types/reducers/personal-ledger.reducer.type";
import {
  ADD_WINE_BEGIN,
  ADD_WINE_FAILED,
  ADD_WINE_SUCCESS,
  CLEAR_ACTION,
  CLEAR_JOURNAL_FILTERS,
  DELETE_ADDED_WINE_BEGIN,
  DELETE_ADDED_WINE_FAILED,
  DELETE_ADDED_WINE_SUCCESS,
  GET_ADDED_WINES_BEGIN,
  GET_ADDED_WINES_FAILED,
  GET_ADDED_WINES_SUCCESS,
  GET_JOURNAL_CATEGORIES_BEGIN,
  GET_JOURNAL_CATEGORIES_FAILED,
  GET_JOURNAL_CATEGORIES_SUCCESS,
  GET_PRODUCT_TRANSACTIONS_BEGIN,
  GET_PRODUCT_TRANSACTIONS_FAILED,
  GET_PRODUCT_TRANSACTIONS_SUCCESS,
  GET_JOURNAL_DATA_BEGIN,
  GET_JOURNAL_DATA_FAILED,
  GET_JOURNAL_DATA_SUCCESS,
  GET_USER_BLIND_TASTINGS_BEGIN,
  GET_USER_BLIND_TASTINGS_FAILED,
  GET_USER_BLIND_TASTINGS_SUCCESS,
  GET_USER_ORDERS_BEGIN,
  GET_USER_ORDERS_FAILED,
  GET_USER_ORDERS_SUCCESS,
  POPULATE_JOURNAL_FILTERS,
  RATE_ADDED_WINE_BEGIN,
  RATE_ADDED_WINE_FAILED,
  RATE_ADDED_WINE_SUCCESS,
  RATE_WINE_BEGIN,
  RATE_WINE_FAILED,
  RATE_WINE_SUCCESS,
  REQUEST_TO_SUPPLY_WINE_BEGIN,
  REQUEST_TO_SUPPLY_WINE_FAILED,
  REQUEST_TO_SUPPLY_WINE_SUCCESS,
  SET_ADDED_WINE_TASTE_BEGIN,
  SET_ADDED_WINE_TASTE_FAILED,
  SET_ADDED_WINE_TASTE_SUCCESS,
  SET_JOURNAL_FILTERS,
  SET_PRODUCT_TASTE_BEGIN,
  SET_PRODUCT_TASTE_FAILED,
  SET_PRODUCT_TASTE_SUCCESS,
  GET_ADDED_WINE_PRODUCT_DETAILS_BEGIN,
  GET_ADDED_WINE_PRODUCT_DETAILS_FAILED,
  GET_ADDED_WINE_PRODUCT_DETAILS_SUCCESS,
  EDIT_ADDED_WINE_BEGIN,
  EDIT_ADDED_WINE_FAILED,
  EDIT_ADDED_WINE_SUCCESS,
  EVALUATE_JOURNAL_WINE_BEGIN,
  EVALUATE_JOURNAL_WINE_FAILED,
  EVALUATE_JOURNAL_WINE_SUCCESS,
} from "./constants";
import StorageService from "services/storage.service";

const initialState: PersonalLedgerInterface = {
  action: {
    isLoading: false,
    error: null,
    message: "",
    isSuccess: false,
    actionName: null,
  },
  orders: null,
  productTransactions: null,
  userBlindTastings: null,
  addedWines: null,
  journalWines: null,
  filters: [
    { filter: "Categories", value: [] },
    { filter: "DateRangeJournal", value: [] },
    { filter: "Sort", value: [] },
  ],
  categories: null,
  addedWineProductDetails: null,
};

const PersonalLedgerReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case CLEAR_ACTION:
        draft.action = initialState.action;
        break;
      case GET_USER_ORDERS_BEGIN:
      case RATE_WINE_BEGIN:
      case RATE_ADDED_WINE_BEGIN:
      case SET_PRODUCT_TASTE_BEGIN:
      case SET_ADDED_WINE_TASTE_BEGIN:
      case GET_PRODUCT_TRANSACTIONS_BEGIN:
      case GET_USER_BLIND_TASTINGS_BEGIN:
      case ADD_WINE_BEGIN:
      case EDIT_ADDED_WINE_BEGIN:
      case GET_ADDED_WINES_BEGIN:
      case REQUEST_TO_SUPPLY_WINE_BEGIN:
      case GET_JOURNAL_DATA_BEGIN:
      case DELETE_ADDED_WINE_BEGIN:
      case GET_JOURNAL_CATEGORIES_BEGIN:
      case GET_ADDED_WINE_PRODUCT_DETAILS_BEGIN:
      case EVALUATE_JOURNAL_WINE_BEGIN:
        draft.action.isLoading = true;
        draft.action.isSuccess = false;
        draft.action.error = null;
        draft.action.actionName = action.type;
        break;
      case SET_JOURNAL_FILTERS:
        const value = action.payload.value;
        const updatedFilters = [...draft.filters];

        const filterIndex = updatedFilters.findIndex(
          (filter) => filter.filter === action.payload.filter
        );

        if (filterIndex !== -1) {
          updatedFilters[filterIndex] = {
            ...updatedFilters[filterIndex],
            value,
          };
          draft.filters = updatedFilters;
          StorageService.setKeyValue("journalFilters", updatedFilters);
        }
        break;
      case GET_USER_ORDERS_FAILED:
      case RATE_WINE_FAILED:
      case RATE_ADDED_WINE_FAILED:
      case SET_PRODUCT_TASTE_FAILED:
      case SET_ADDED_WINE_TASTE_FAILED:
      case GET_PRODUCT_TRANSACTIONS_FAILED:
      case GET_USER_BLIND_TASTINGS_FAILED:
      case ADD_WINE_FAILED:
      case EDIT_ADDED_WINE_FAILED:
      case GET_ADDED_WINES_FAILED:
      case REQUEST_TO_SUPPLY_WINE_FAILED:
      case GET_JOURNAL_DATA_FAILED:
      case DELETE_ADDED_WINE_FAILED:
      case GET_JOURNAL_CATEGORIES_FAILED:
      case GET_ADDED_WINE_PRODUCT_DETAILS_FAILED:
      case EVALUATE_JOURNAL_WINE_FAILED:
        draft.action.isLoading = false;
        draft.action.error = action.payload ? action.payload : "Unknown error";
        draft.action.isSuccess = false;
        draft.action.actionName = action.type;
        break;
      case GET_USER_ORDERS_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.journalWines = null;
        draft.orders = action.payload;
        break;
      case RATE_WINE_SUCCESS:
      case RATE_ADDED_WINE_SUCCESS:
      case SET_PRODUCT_TASTE_SUCCESS:
      case SET_ADDED_WINE_TASTE_SUCCESS:
      case ADD_WINE_SUCCESS:
      case EDIT_ADDED_WINE_SUCCESS:
      case REQUEST_TO_SUPPLY_WINE_SUCCESS:
      case DELETE_ADDED_WINE_SUCCESS:
      case EVALUATE_JOURNAL_WINE_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        break;
      case GET_PRODUCT_TRANSACTIONS_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.productTransactions = action.payload;
        break;
      case GET_USER_BLIND_TASTINGS_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.userBlindTastings = action.payload;
        draft.journalWines = null;
        break;
      case GET_ADDED_WINES_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.addedWines = action.payload;
        draft.journalWines = null;
        break;
      case GET_JOURNAL_DATA_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.orders = null;
        draft.userBlindTastings = null;
        draft.addedWines = null;
        draft.journalWines = action.payload?.length > 0 ? action.payload : null;
        break;
      case POPULATE_JOURNAL_FILTERS:
        const journalFiltersCookie = () =>
          StorageService.getDataByKey("journalFilters");
        draft.filters = journalFiltersCookie();
        break;
      case CLEAR_JOURNAL_FILTERS:
        draft.action.actionName = action.type;
        draft.filters = initialState.filters;
        break;
      case GET_JOURNAL_CATEGORIES_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.categories = action.payload.map(
          (category) => category.category_name
        );
        break;
      case GET_ADDED_WINE_PRODUCT_DETAILS_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.addedWineProductDetails = action.payload;
        break;
      default:
        break;
    }
  });

export default PersonalLedgerReducer;
