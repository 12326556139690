import React, { useEffect, useState } from "react";
import styles from "./GeneralTaste.module.scss";
import { useTranslation } from "react-i18next";
import { getDefaultColour, getOnlyNumbersFromString } from "utils/utils";
import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import { BackButtonIcon } from "assets/icons/icons";
import { useNavigate, useSearchParams } from "react-router-dom";

import TasteProfileOptions from "assets/data/taste-profile-options.json";
import ButtonComponent from "components/Button/Button.component";
import { getTastePreferencesBegin, saveGeneralTasteBegin } from "../actions";
import { SAVE_GENERAL_TASTE_SUCCESS } from "../constants";
import { openToaster } from "App/actions";
import StorageService from "services/storage.service";

const GeneralTaste: React.FC = () => {
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const {
    action: { actionName },
    tastePreferences,
  } = useAppSelector((state: GlobalIState) => state.HomeFeedReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();

  const [currentStep, setCurrentStep] = useState(0);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [answers, setAnswers] = useState<
    {
      question: string;
      answer: string;
      color: string;
    }[]
  >([]);
  const currentQuestion = TasteProfileOptions[currentStep];
  const selectedAnswer = answers.find(
    (ans) => ans.question === currentQuestion.title
  )?.answer;

  const handleOptionSelect = (
    questionTitle: string,
    optionLabel: string,
    color: string
  ) => {
    setIsDisabled(false);
    setAnswers((prev) => {
      const updatedAnswers = prev.filter(
        (ans) => ans.question !== questionTitle
      );
      return [
        ...updatedAnswers,
        { question: questionTitle, answer: optionLabel, color: color },
      ];
    });
  };

  const handleNext = () => {
    if (currentStep < TasteProfileOptions.length - 1) {
      setCurrentStep((prev) => prev + 1);
      setIsDisabled(true);
    } else {
      // setIsSubmit(true);
      const updatedData = answers.map((item) => ({
        question: item.question,
        answer: item.answer,
        color: item.color,
      }));
      StorageService.setKeyValue("generalTasteData", updatedData);

      navigate("/taste-and-preferences/general-taste/preview?submit=y");
    }
  };

  const handleSubmit = () => {
    const updatedData = answers.map((item) => ({
      question: item.question,
      answer: item.answer,
      color: item.color,
    }));

    StorageService.setKeyValue("generalTasteData", updatedData);
    dispatch(saveGeneralTasteBegin(updatedData));
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep((prev) => prev - 1);
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    if (actionName === SAVE_GENERAL_TASTE_SUCCESS) {
      dispatch(getTastePreferencesBegin());
      dispatch(
        openToaster(
          "SUCCESS",
          translations("label.your_general_taste_profile_stored_successfully")
        )
      );
      navigate("/taste-and-preferences/general-taste/preview");
    }
  }, [actionName]);

  useEffect(() => {
    if (!tastePreferences) dispatch(getTastePreferencesBegin());
  }, [tastePreferences]);

  return (
    <div
      className={styles.wrapper}
      style={
        { "--color": getDefaultColour(defaultColour) } as React.CSSProperties
      }
    >
      <div className={styles.header}>
        <div className={styles.backButtonContainer}>
          <div onClick={handleBack} className="pointer">
            <BackButtonIcon color={"#000"} />
          </div>
        </div>
        {isSubmit ? (
          <div></div>
        ) : (
          <div className={styles.rightLabel}>
            <span>
              {translations("label.step", {
                count: getOnlyNumbersFromString(currentStep.toString()) + 1,
              })}{" "}
              {translations("label.of")} 5
            </span>
          </div>
        )}
      </div>

      {isSubmit ? null : (
        <h2>{translations("label.I_prefer_a_wine_that_is")}</h2>
      )}

      {isSubmit ? (
        <div className={styles.submitContent}>
          <h2>{translations("label.your_general_taste_profile")}</h2>
          {/* <span>
            {translations("label.your_general_taste_profile.subtitle")}
          </span> */}
        </div>
      ) : null}

      <div className={styles.content}>
        {isSubmit ? null : (
          <div className={styles.questionHeader}>
            <h3>{translations(currentQuestion.title)}</h3>
            <span>{translations(currentQuestion.description)}</span>
          </div>
        )}
        {!isSubmit ? (
          <div className={styles.options}>
            {currentQuestion.options.map((option) => (
              <button
                key={option.id}
                style={{
                  width: selectedAnswer === option.label ? "92%" : "90%",
                  height: selectedAnswer === option.label ? 60 : 48,
                  backgroundColor: option.bgColor,
                  color: option.color,
                }}
                className={`${styles.option} ${
                  selectedAnswer === option.label ? styles.selectedOption : ""
                }`}
                onClick={() =>
                  handleOptionSelect(
                    currentQuestion.title,
                    option.label,
                    option.bgColor
                  )
                }
              >
                {translations(option.label)}
              </button>
            ))}
          </div>
        ) : null}

        <div className={styles.bottom}>
          <ButtonComponent
            label={translations("label.continue")}
            class={"btn btn-primary"}
            disabled={isDisabled}
            handleClick={isSubmit ? handleSubmit : handleNext}
          />
        </div>
      </div>
    </div>
  );
};

export default GeneralTaste;
