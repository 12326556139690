import { LockerIcon } from "assets/icons/icons";
import styles from "./EnterTwoFactor.module.scss";
import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { getDefaultColour, handleOnlyNumbersKeydown } from "utils/utils";
import ButtonComponent from "components/Button/Button.component";
import { logInBegin } from "pages/Authentication/actions";
import StorageService from "services/storage.service";
import { LOG_IN_SUCCESS } from "pages/Authentication/constants";
import { useNavigate } from "react-router-dom";
import { LOGIN_DEVICE } from "utils/enums";

interface Props {}

function EnterTwoFactor() {
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const {
    action: { actionName, isLoading },
  } = useAppSelector((state: GlobalIState) => state.authenticationReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();
  const data = StorageService.getDataByKey("loginTwoFactorData");
  const [twoFactorValue, setTwoFactorValue] = useState("");

  const handleSubmit = () => {
    const loginData = {
      user: data.user,
      password: data.password,
      country_phone: data.country_phone,
      phone: data.phone,
      google_auth_code: data.google_auth_code,
      login_method: data.login_method,
      two_factor_code: twoFactorValue,
      sms_code: null,
      last_login_device: LOGIN_DEVICE.WEB_APP,
    };
    dispatch(logInBegin(loginData));
  };

  useEffect(() => {
    if (actionName === LOG_IN_SUCCESS) {
      StorageService.deleteCookie("loginTwoFactorData");
      navigate("/");
    }
  }, [actionName]);

  return (
    <div
      className={styles.mainWrapper}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      <div className={styles.header}>
        <LockerIcon />
        <h2>{translations("label.two_factor_authentication")}</h2>
        <span>{translations("label.enter_two_factor_code_login")}</span>
      </div>

      <div className={styles.twoFactorInput}>
        <input
          type="text"
          value={twoFactorValue}
          onKeyDown={handleOnlyNumbersKeydown}
          placeholder={translations("label.enter_your_code_here")}
          onChange={(e) => setTwoFactorValue(e.target.value)}
        />
      </div>

      <div className={styles.button}>
        <ButtonComponent
          label={translations("button.submit")}
          class={"btn btn-primary"}
          isLoading={isLoading}
          handleClick={() => handleSubmit()}
        />
      </div>
    </div>
  );
}

export default EnterTwoFactor;
