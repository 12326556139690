import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import styles from "./MobileAddedWineProductDetails.module.scss";
import { BackButtonIcon, EditPencilIcon } from "assets/icons/icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  formatPrice,
  getDefaultColour,
  modifyHomeFiltersForDate,
} from "utils/utils";
import { useTranslation } from "react-i18next";
import LoaderWrapper from "components/Loader/LoaderWrapper/LoaderWrapper";
import { useEffect } from "react";
import {
  getAddedWineProductDetailsBegin,
  getJournalDataBegin,
  requestToSupplyWineBegin,
} from "pages/PersonalLedger/actions";
import StorageService from "services/storage.service";
import ButtonComponent from "components/Button/Button.component";
import WineBluredImage from "assets/images/wine.png";

function MobileAddedWineProductDetails() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();
  const {
    action: { isLoading },
    journalWines,
    filters,
    addedWineProductDetails,
  } = useAppSelector((state: GlobalIState) => state.PersonalLedgerReducer);
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );

  const [searchParams] = useSearchParams();
  const productIdParam = searchParams.get("id");
  const productOrder = journalWines?.find(
    (item) => item?.product?.id === Number(productIdParam)
  );

  const journalSearchCookie = () =>
    StorageService.getDataByKey("journalSearch");

  const getLanguage = () => {
    return localStorage.getItem("lang");
  };

  const handleProposeToSupply = (id) => {
    dispatch(requestToSupplyWineBegin(id));
  };

  useEffect(() => {
    if (productIdParam) {
      dispatch(getAddedWineProductDetailsBegin(Number(productIdParam)));
    }
  }, [productIdParam]);

  useEffect(() => {
    if (!journalWines) {
      dispatch(
        getJournalDataBegin({
          search: journalSearchCookie() ? journalSearchCookie() : "",
          filters:
            filters?.filter((item) => item?.value?.length >= 1)?.length === 0
              ? []
              : modifyHomeFiltersForDate(
                  filters?.filter((item) => item?.value?.length >= 1)
                )?.filters,
        })
      );
    }
  }, [journalWines]);

  return (
    <LoaderWrapper isLoading={isLoading}>
      <div
        className={styles.wrapper}
        style={
          {
            "--color": getDefaultColour(defaultColour),
          } as React.CSSProperties
        }
      >
        <div className={styles.header}>
          <div className="pointer" onClick={() => navigate("/journal")}>
            <BackButtonIcon color={"#000"} />
          </div>
          <div className={styles.tabs}>
            <div
              className={styles.activeTab}
              onClick={() =>
                navigate(`/journal/product-details?id=${productIdParam}`)
              }
            >
              <span>{translations("label.info")}</span>
            </div>
            <div
              className={styles.tab}
              style={{ borderRadius: 0 }}
              onClick={() =>
                navigate(`/journal/rate-and-taste?id=${productIdParam}`)
              }
            >
              <span>{translations("label.tasting")}</span>
            </div>
            <div
              className={styles.tab}
              onClick={() => navigate(`/journal/ledger?id=${productIdParam}`)}
            >
              <span>
                {translations(
                  productOrder?.product?.inputted_data
                    ? "label.place"
                    : "label.ledger"
                )}
              </span>
            </div>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.card}>
            <div className={styles.imagePart}>
              <img
                src={
                  addedWineProductDetails?.product_image
                    ? addedWineProductDetails?.product_image
                    : WineBluredImage
                }
                style={
                  !addedWineProductDetails?.product_image
                    ? { opacity: 0.5 }
                    : {}
                }
                alt={addedWineProductDetails?.wine_name}
              />
            </div>
            <div className={styles.rightPart}>
              <div>
                <div className={styles.title}>
                  <span>
                    {" "}
                    {productOrder?.product?.wine_name?.length > 25
                      ? productOrder?.product?.wine_name?.substring(0, 25) +
                        "..."
                      : productOrder?.product?.wine_name}{" "}
                  </span>
                </div>
                <div>{addedWineProductDetails?.year}</div>
              </div>
              <div>
                <div className={styles.title}>
                  <span>{translations("label.bottle_size")}</span>
                </div>
                <div className={styles.bottleSizeLabel}>
                  <span>{productOrder?.product?.bottle_size}</span>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.bottleLabels}>
            <span className={styles.wineTypeLabel}>
              {addedWineProductDetails?.type?.length > 0
                ? addedWineProductDetails?.type
                : translations("label.no_type_of_wine_given")}
            </span>
            <div>
              <span>
                {addedWineProductDetails?.country?.length > 0
                  ? addedWineProductDetails?.country
                  : translations("label.no_country_given")}
              </span>
              {" | "}

              <span>
                {addedWineProductDetails?.region?.length > 0
                  ? addedWineProductDetails?.region
                  : translations("label.no_region_given")}
              </span>
            </div>
          </div>
          <div className={styles.middleLabels}>
            <span>
              {addedWineProductDetails?.producer?.length > 0
                ? addedWineProductDetails?.producer
                : translations("label.no_producer_name_given")}
            </span>
            <span className={styles.bold}>
              {addedWineProductDetails?.wine_name?.length > 0
                ? addedWineProductDetails?.wine_name
                : translations("label.no_wine_name_given")}
            </span>
            <span className={styles.year}>
              {addedWineProductDetails?.year
                ? addedWineProductDetails?.year
                : translations("label.no_year_given")}
            </span>
          </div>

          <div className={styles.informations}>
            <div>
              {" "}
              <span>
                <b>{translations("label.description")}: </b>
                {addedWineProductDetails?.description?.length > 0
                  ? addedWineProductDetails?.description
                  : translations("label.no_description_given")}
              </span>
            </div>
            <div>
              <span>
                <b>{translations("label.location")}: </b>
                {addedWineProductDetails?.location?.length > 0
                  ? addedWineProductDetails?.location
                  : translations("label.no_location_given")}
              </span>
            </div>
            <div>
              <span>
                <b>{translations("label.inventory")}: </b>
                {addedWineProductDetails?.inventory
                  ? addedWineProductDetails?.inventory
                  : translations("label.no_inventory_given")}
              </span>
            </div>

            {Object.keys(addedWineProductDetails?.custom_field ?? {})?.length >
            0
              ? Object.entries(addedWineProductDetails.custom_field ?? {}).map(
                  ([key, value]) => (
                    <div key={key}>
                      <span>
                        <b>{key}: </b>
                        {String(value)}
                      </span>
                    </div>
                  )
                )
              : null}
          </div>
          <div className={styles.editContainer}>
            <div
              className={styles.editButton}
              onClick={() =>
                navigate(`/edit-added-wine?id=${addedWineProductDetails?.id}`)
              }
            >
              <EditPencilIcon color="#fff" />
            </div>
          </div>
        </div>

        <div className={styles.proposeToSupplyCard}>
          <div className={styles.infoLabels}>
            <span>{translations("label.bottle_price")}</span>
            <span className={styles.price}>
              {addedWineProductDetails?.price > 0
                ? `CHF ${formatPrice(
                    addedWineProductDetails?.price.toFixed(2).toString()
                  )}`
                : translations("label.no_price_given")}
            </span>
          </div>
          <div className={styles.actionContent}>
            {addedWineProductDetails?.supplyRequest ? (
              <div className={styles.requestedToSupplyLabel}>
                <span>{translations("label.request_received_to_supply")}</span>
              </div>
            ) : (
              <ButtonComponent
                label={translations("label.propose_to_supply")}
                class={"btn btn-primary"}
                isLoading={isLoading}
                handleClick={() =>
                  handleProposeToSupply(addedWineProductDetails?.id)
                }
              />
            )}
          </div>
        </div>
      </div>
    </LoaderWrapper>
  );
}

export default MobileAddedWineProductDetails;
