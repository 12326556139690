import { BackButtonIcon, HowItWorksIcon } from "assets/icons/icons";
import styles from "./RateAndTastePage.module.scss";

import { useAppDispatch, useAppSelector } from "hooks";

import { GlobalIState } from "types/RootState";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import RateWine from "../RateWine/RateWine.page";
import TasteWine from "../TasteWine/TasteWine.page";
import { openModal } from "App/actions";
import { modalTypes } from "types/reducers/modal.reducer.type";

interface Props {
  setOpenedCard?: any;
  product_id: string;
}

function RateAndTaste(props: Props) {
  const dispatch = useAppDispatch();
  const { setOpenedCard, product_id } = props;
  const { t: translations } = useTranslation();
  const [activeTab, setActiveTab] = useState("rating");

  const handleInfoPopup = () => {
    dispatch(
      openModal({
        content: (
          <div className={styles.infoPopup}>
            <h3>{translations("title.tasting_info")}</h3>
            <div>
              <span>{translations("label.long_desc.body")}</span>
            </div>
            <div>
              <span>{translations("label.long_desc.aroma")}</span>
            </div>
            <div>
              <span>{translations("label.long_desc.flavour")}</span>
            </div>
            <div>
              <span>{translations("label.long_desc.acidity")}</span>
            </div>
            <div>
              <span>{translations("label.long_desc.sweetness")}</span>
            </div>
          </div>
        ),
        type: modalTypes.smInfo,
      })
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div
          className={styles.backButton}
          onClick={() =>
            setOpenedCard({
              isExpanded: false,
              item: null,
              category: "",
            })
          }
        >
          <BackButtonIcon color={"#000"} />
        </div>
        <div className={styles.tabs}>
          <div
            className={styles.tab}
            style={{
              borderTopLeftRadius: 5,
              borderBottomLeftRadius: 5,
              borderRight: "none",
              backgroundColor: activeTab === "rating" ? "#000" : "#fff",
              color: activeTab === "rating" ? "#fff" : "#000",
            }}
            onClick={() => setActiveTab("rating")}
          >
            {translations("tab.rating")}
          </div>
          <div
            className={styles.tab}
            style={{
              borderTopRightRadius: 5,
              borderBottomRightRadius: 5,
              backgroundColor: activeTab === "tasting" ? "#000" : "#fff",
              color: activeTab === "tasting" ? "#fff" : "#000",
            }}
            onClick={() => setActiveTab("tasting")}
          >
            {translations("tab.tasting")}
          </div>
        </div>
      </div>
      <div className={styles.content}>
        {activeTab === "tasting" ? (
          <div className={styles.infoIcon} onClick={() => handleInfoPopup()}>
            <HowItWorksIcon />
          </div>
        ) : null}
        {activeTab === "rating" ? (
          <RateWine setActiveTab={setActiveTab} product_id={product_id} />
        ) : (
          <TasteWine setActiveTab={setActiveTab} product_id={product_id} />
        )}
      </div>
    </div>
  );
}

export default RateAndTaste;
