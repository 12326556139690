import React, { useEffect, useState } from "react";
import styles from "./DP1.module.scss";
import { getDefaultColour } from "utils/utils";
import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import { IStreamFeedItem } from "types/reducers/homefeed.reducer.type";
import {
  AnswerBorderSpace,
  AnswerTouchLineIcon,
  DislikeIcon,
  InfinityIcon,
  LikeIcon,
  OpenChatIcon,
  ShareArrowIcon,
} from "assets/icons/icons";
import { useNavigate } from "react-router-dom";
import StorageService from "services/storage.service";
import { clearAction, getCardByIdBegin } from "pages/Home/actions";
import { GET_CARD_BY_ID_SUCCESS } from "pages/Home/constants";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";

interface DP1CardProps {
  item: IStreamFeedItem;
  question: string;
  answer: string;
}

const DP1Card: React.FC<DP1CardProps> = ({ item, question, answer }) => {
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const {
    action: { actionName },
    card,
  } = useAppSelector((state: GlobalIState) => state.HomeFeedReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isMobile = useCheckMobileScreen();
  const [isExpanded, setIsExpanded] = useState(false);

  const formatLine = (line: string) => {
    const boldPattern = /\*\*(.*?)\*\*/g;
    const italicPattern = /\*(.*?)\*/g;
    const headerPattern = /^###\s*(.*)$/;

    if (headerPattern.test(line)) {
      const headerText = line.replace(headerPattern, "$1");
      return (
        <h3
          key={line}
          style={{
            color: "#FF6333",
            marginBottom: "12px",
            fontWeight: "bold",
          }}
        >
          {headerText}
        </h3>
      );
    }

    const formattedLine = line
      .replace(boldPattern, (_, boldText) => `<strong>${boldText}</strong>`)
      .replace(italicPattern, (_, italicText) => `<em>${italicText}</em>`);

    return (
      <span
        key={line}
        dangerouslySetInnerHTML={{ __html: formattedLine }}
        style={{ display: "block", marginBottom: "8px" }}
      />
    );
  };

  const formatResponse = (text: string) => {
    if (!text) return null;

    const lines = text.split("\n").filter((line) => line.trim() !== "");

    return lines.map((line, index) => {
      return <p key={index}>{formatLine(line)}</p>;
    });
  };

  const handleOpenChat = () => {
    StorageService.deleteCookie("chatVote");
    dispatch(getCardByIdBegin(item?.id));
  };

  useEffect(() => {
    if (actionName === GET_CARD_BY_ID_SUCCESS) {
      dispatch(clearAction());
      navigate(`/chat-history?id=${card?.id}`);
    }
  }, [actionName]);

  return (
    <div
      className={styles.cardContainer}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      <div style={{ marginTop: "30px" }}>
        <InfinityIcon />
      </div>

      <div className={styles.chatTimeline}>
        <div className={styles.question}>
          <span>{question}</span>
        </div>
        <div
          style={{
            height:
              !isExpanded && answer?.length >= 400
                ? isMobile
                  ? 320
                  : 200
                : "",
          }}
          className={styles.answer}
        >
          <div className={styles.answerTouchLine}>
            <AnswerTouchLineIcon />
          </div>

          <div className={styles.formattedAnswer}>
            {formatResponse(
              isExpanded ? answer : answer?.slice(0, 400) + "..."
            )}
          </div>

          {!isExpanded && answer?.length >= 400 ? (
            <div className={styles.answerOverlay}></div>
          ) : null}
          {!isExpanded && answer?.length >= 400 ? (
            <div
              className={styles.expandCard}
              onClick={() => setIsExpanded(true)}
            >
              ...
            </div>
          ) : null}
        </div>
      </div>

      <div className={styles.footer}>
        <div className={styles.openChatAction} onClick={() => handleOpenChat()}>
          <OpenChatIcon />
        </div>
        <div>
          <AnswerBorderSpace />
        </div>
      </div>
    </div>
  );
};

export default DP1Card;
