import { useEffect, useRef, useState } from "react";
import styles from "./StreamDateRangeFilter.module.scss";
import { ArrowDownIcon } from "assets/icons/icons";
import { useTranslation } from "react-i18next";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import { useAppDispatch, useAppSelector } from "hooks";
import { setHomeFilters } from "pages/Home/actions";
import StorageService from "services/storage.service";
import { getDefaultColour, getFilterValue } from "utils/utils";
import { GlobalIState } from "types/RootState";
import dayjs from "dayjs";
import { openToaster } from "App/actions";
import InputMui from "components/InputMui/InputMui";

type DateRangeProps = {
  onDateChange: (from: string, until: string) => void;
};

export default function StreamDateRangeFilter({
  onDateChange,
}: DateRangeProps) {
  const { filters } = useAppSelector(
    (state: GlobalIState) => state.HomeFeedReducer
  );
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const dispatch = useAppDispatch();
  const isMobile = useCheckMobileScreen();
  const { t: translations } = useTranslation();
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [untilDate, setUntilDate] = useState("");
  const [isInputFocused, setIsInputFocused] = useState(false);

  const cookieDateStored = getFilterValue("scheduleTime", filters);

  const handleResetFilter = () => {
    setFromDate("");
    setUntilDate("");
    onDateChange("", "");
    dispatch(setHomeFilters({ filter: "scheduleTime", value: [] }));
    setIsDropdownOpen(false);
  };

  const handleToggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleApplyFilter = () => {
    dispatch(
      setHomeFilters({
        filter: "scheduleTime",
        value:
          fromDate === "" && untilDate === ""
            ? []
            : untilDate === ""
            ? [fromDate, fromDate]
            : [fromDate, untilDate],
      })
    );
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !(dropdownRef.current as HTMLElement).contains(event.target as Node) &&
        inputRef.current &&
        !(inputRef.current as HTMLElement).contains(event.target as Node) &&
        !isInputFocused
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isInputFocused]);

  useEffect(() => {
    if (cookieDateStored && cookieDateStored[0]) {
      setFromDate(cookieDateStored[0]);
    }

    if (cookieDateStored && cookieDateStored[1]) {
      setUntilDate(cookieDateStored[1]);
    }
  }, [filters, cookieDateStored]);

  useEffect(() => {
    onDateChange(fromDate, untilDate);
  }, [fromDate, untilDate]);

  return (
    <div
      ref={dropdownRef}
      style={
        {
          "--color": getDefaultColour(defaultColour),
          height: isMobile
            ? !isDropdownOpen && (fromDate !== "" || untilDate !== "")
              ? 72
              : 40
            : 30,
        } as React.CSSProperties
      }
      className={styles.multiSelectDropdownContainer}
    >
      <div
        className={styles.dropdownLabel}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        <span>{translations("label.date")}</span>
        <ArrowDownIcon color={"#000"} />
      </div>

      {!isDropdownOpen && isMobile && (fromDate !== "" || untilDate !== "") ? (
        <div className={styles.selectedDate}>
          <span>
            {dayjs(fromDate).format("DD.MM.YYYY")} -{" "}
            {dayjs(untilDate).format("DD.MM.YYYY")}
          </span>
        </div>
      ) : null}

      {isDropdownOpen && (
        <div className={styles.dropdownContent}>
          <div>
            <div className={styles.dateRanges}>
              <div className={styles.dateLabel}>
                <span>{translations("label.from")}</span>
              </div>

              <div className={styles.datePicker}>
                <InputMui
                  style={
                    {
                      "--color": getDefaultColour(defaultColour),
                    } as React.CSSProperties
                  }
                  label={translations("label.from")}
                  type="date"
                  name="from"
                  value={fromDate}
                  onChange={(e) => {
                    setFromDate(dayjs(e?.$d).format());
                  }}
                  required
                  inputRef={inputRef}
                  onFocus={() => setIsInputFocused(true)}
                  onBlur={() => setIsInputFocused(false)}
                />
              </div>
            </div>

            <div className={styles.dateRanges}>
              <div className={styles.dateLabel}>
                <span>{translations("label.until")}</span>
              </div>

              <div className={styles.datePicker}>
                <InputMui
                  style={
                    {
                      "--color": getDefaultColour(defaultColour),
                    } as React.CSSProperties
                  }
                  label={translations("label.until")}
                  type="date"
                  name="until"
                  value={untilDate}
                  onChange={(e) => {
                    setUntilDate(dayjs(e?.$d).format());
                  }}
                  required
                  inputRef={inputRef}
                  onFocus={() => setIsInputFocused(true)}
                  onBlur={() => setIsInputFocused(false)}
                />
              </div>
            </div>

            <div
              className={styles.resetFilterBtn}
              onClick={() => handleResetFilter()}
            >
              <span>{translations("button.reset")}</span>
            </div>
          </div>

          {isMobile ? (
            <div
              className={styles.button}
              onClick={() => handleToggleDropdown()}
            >
              <span>{translations("button.close")}</span>
            </div>
          ) : (
            <div className={styles.button} onClick={() => handleApplyFilter()}>
              <span>{translations("label.apply")}</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
