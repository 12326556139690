import { useAppSelector, useAppDispatch } from "hooks";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GlobalIState } from "types/RootState";
import { getDefaultColour } from "utils/utils";

import styles from "./MagicMatch.module.scss";
import { useEffect, useState } from "react";
import { getClientBegin } from "pages/Profile/actions";
import StorageService from "services/storage.service";
import ButtonComponent from "components/Button/Button.component";

import Lottie from "react-lottie";
import * as magicMatchAnimation from "assets/animations/magic-match.json";
import CoinImage from "assets/images/Coin.png";
import { InfoModalCloseIcon } from "assets/icons/icons";
import GameCardFlip from "components/FlipCard/GameCardFlip";
import { getTodaysQuestionBegin, submitMagicMatchBegin } from "../actions";
import { SUBMIT_MAGIC_MATCH_SUCCESS } from "../constants";

function MagicMatch() {
  const { defaultColour, client } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const {
    action: { isLoading, actionName },
    todays_question,
    todaysGame,
  } = useAppSelector((state: GlobalIState) => state.GamificationReducer);
  const { t: translations } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isLoggedIn = () => StorageService.getDataByKey("token");
  const [state, setState] = useState({
    activeStep: "",
    selectedAnswerId: 0,
  });
  const [cards, setCards] = useState([]);
  const [turns, setTurns] = useState(0);
  const [choiceOne, setChoiceOne] = useState(null);
  const [choiceTwo, setChoiceTwo] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [timeLeft, setTimeLeft] = useState(40);
  const [isTimeUp, setIsTimeUp] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const stepParam = searchParams.get("step");
  const submitParam = searchParams.get("submit");
  const matchedItemsCookie = () => StorageService.getDataByKey("imagesMatched");
  const cardImages = todays_question?.images?.map((item) => ({ src: item }));
  const formattedTimeLeft = timeLeft < 10 ? `0${timeLeft}` : timeLeft;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: magicMatchAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const getLanguage = () => {
    return localStorage.getItem("lang");
  };

  const shuffleCards = () => {
    const shuffleCards = [...cardImages, ...cardImages]
      .sort(() => Math.random() - 0.5)
      .map((card) => ({ ...card, id: Math.random(), matched: false }));
    setChoiceOne(null);
    setChoiceTwo(null);
    setCards(shuffleCards);
    setTurns(0);
  };

  const handleChoice = (card) => {
    choiceOne ? setChoiceTwo(card) : setChoiceOne(card);
  };

  const handleStartGame = () => {
    navigate("/games/magic-match?step=quiz");
  };

  const handleQuizSubmit = () => {
    let matchedItems = Array.from(
      new Map(cards.map((item) => [item.src, item])).values()
    ).filter((item) => item.matched);

    if (isLoggedIn()) {
      dispatch(submitMagicMatchBegin(matchedItems?.length));
    } else {
      StorageService.setKeyValue("imagesMatched", matchedItems?.length);
      StorageService.setKeyValue(
        "lastRouteCookie",
        "/games/magic-match?submit=true"
      );
      navigate("/login");
    }
  };

  const resetTurn = () => {
    setChoiceOne(null);
    setChoiceTwo(null);
    setTurns((prevTurns) => prevTurns + 1);
    setDisabled(false);
  };

  useEffect(() => {
    dispatch(getTodaysQuestionBegin(getLanguage()));
    if (!client && isLoggedIn()) {
      dispatch(getClientBegin());
    }
  }, [state.activeStep]);

  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault();

      if (client?.has_played) {
        navigate("/home");
      } else {
        window.history.back();
      }
    };

    window.history.pushState(null, null, window.location.href);

    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [navigate, client?.has_played]);

  useEffect(() => {
    if (stepParam === "quiz" && state.activeStep !== "submit") {
      setState({ ...state, activeStep: "playQuiz" });
    }

    if (submitParam) {
      dispatch(submitMagicMatchBegin(Number(matchedItemsCookie())));
      StorageService.deleteCookie("imagesMatched");
      searchParams.delete("submit");
      setSearchParams(searchParams);
    }
  }, [stepParam, submitParam]);

  useEffect(() => {
    if (actionName === SUBMIT_MAGIC_MATCH_SUCCESS && !submitParam) {
      StorageService.deleteCookie("imagesMatched");
      navigate("/games");
      window.location.reload();
    }
  }, [actionName]);

  useEffect(() => {
    if (choiceOne && choiceTwo) {
      setDisabled(true);
      if (choiceOne.src === choiceTwo.src && choiceOne.id !== choiceTwo.id) {
        setCards((prevCards) => {
          return prevCards.map((card) => {
            if (card.src === choiceOne.src) {
              return { ...card, matched: true };
            } else {
              return card;
            }
          });
        });
        resetTurn();
      } else {
        setTimeout(() => resetTurn(), 1000);
      }
    }
  }, [choiceOne, choiceTwo]);

  useEffect(() => {
    if (cards?.length === 0 && todays_question?.images?.length > 0) {
      shuffleCards();
    }
  }, [todays_question?.images]);

  useEffect(() => {
    if (!client && isLoggedIn()) {
      dispatch(getClientBegin());
    }
    if (todaysGame !== "Magic Match") {
      navigate("/games");
      window.location.reload();
    }
  }, [client, todaysGame]);

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else if (
      stepParam === "quiz" &&
      (timeLeft <= 0 || cards?.filter((item) => item.matched)?.length === 12)
    ) {
      setIsTimeUp(true);
      setState({ ...state, activeStep: "submit" });
    }
  }, [timeLeft, cards]);

  useEffect(() => {
    if (
      stepParam === "quiz" &&
      cards?.filter((item) => item.matched)?.length === 12
    ) {
      setIsTimeUp(true);
      setState({ ...state, activeStep: "submit" });
    }
  }, [cards]);

  return (
    <div
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
      className={styles.magicMatchContainer}
    >
      {state.activeStep === "" ? (
        <div className={styles.content}>
          <div className={styles.animation}>
            <Lottie
              options={defaultOptions}
              // isStopped={this.state.isStopped}
              // isPaused={this.state.isPaused}
            />
          </div>
          <div className={styles.gameInfo}>
            <div>
              <h1>{translations("label.magic_match")}</h1>
              <span>{translations("label.magic_match.subtitle")}</span>
            </div>
            <ButtonComponent
              label={translations("label.start_game")}
              class={"btn btn-primary"}
              style={{ backgroundColor: "#FF6333" }}
              handleClick={() => handleStartGame()}
            />
          </div>
        </div>
      ) : ["playQuiz", "submit"].includes(state.activeStep) ? (
        <div className={styles.playQuizGameBox}>
          <div className={styles.header}>
            <div
              onClick={() => {
                navigate("/games");
                setState({ ...state, activeStep: "" });
              }}
            >
              <InfoModalCloseIcon color="#fff" />
            </div>

            <div className={styles.questionBox}>
              <h2>{translations("label.daily_quiz")}</h2>

              <div className={styles.subtitle}>
                <span>{translations("label.magic_match")}</span>
              </div>

              {state.activeStep !== "submit" ? (
                <div className={styles.hint}>
                  <span>
                    {translations("label.magic_match.game_start_hint")}
                  </span>
                </div>
              ) : null}
            </div>

            {state.activeStep === "submit" ? (
              <div className={styles.answeredCoinBox}>
                <img src={CoinImage} alt={"Coin"} />
              </div>
            ) : (
              <div className={styles.gameBox}>
                <div className={styles.timer}>
                  {timeLeft === 0 ? (
                    <span className={styles.timeUpLabel}>
                      {translations("label.time_up")}
                    </span>
                  ) : (
                    <>
                      <span>{translations("label.time_remaining")}</span>
                      <span>0:{formattedTimeLeft}</span>
                    </>
                  )}
                </div>

                <div className={styles.cardGrid}>
                  {cards.map((card) => (
                    <GameCardFlip
                      key={card.id}
                      card={card}
                      handleChoice={handleChoice}
                      flipped={
                        card === choiceOne || card === choiceTwo || card.matched
                      }
                      disabled={disabled}
                    />
                  ))}
                </div>
              </div>
            )}

            {state.activeStep === "submit" ? (
              <>
                {!isLoggedIn() ? (
                  <div className={styles.notLoggedInUser}>
                    <span>
                      {translations("label.login_to_retrieve_medals")}
                    </span>
                  </div>
                ) : null}
                <div className={styles.earnedMedals}>
                  <span>
                    {cards?.filter((item) => item.matched)?.length === 12
                      ? 15
                      : 5}{" "}
                    {translations("label.medals")}
                  </span>
                </div>
              </>
            ) : null}

            {state.activeStep === "submit" ? (
              <div
                className={styles.submitAnswerButton}
                style={{
                  backgroundColor: "#fff",
                  color: "#000",
                  opacity: isLoading ? 0.7 : 1,
                }}
                onClick={() => handleQuizSubmit()}
              >
                <span>
                  {translations(
                    state.activeStep === "submit"
                      ? isLoggedIn()
                        ? "label.get_medals"
                        : "button.log_in"
                      : "button.next"
                  )}
                </span>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default MagicMatch;
