import React, { useEffect } from "react";
import styles from "./TastePreferencesProfile.module.scss";
import { useTranslation } from "react-i18next";
import { getDefaultColour } from "utils/utils";
import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  CloseFirstTypeIcon,
  ConsIcon,
  FiveQuestionsStored,
  PlusIconClassic,
  ProsIcon,
  TastePreferencesFirstLine,
  TastePreferencesFourthLine,
  TastePreferencesSecondLine,
  TastePreferencesThirdLine,
  TasteProfileIlustration,
} from "assets/icons/icons";
import { getTastePreferencesBegin, saveMomentsProfileBegin } from "../actions";
import StorageService from "services/storage.service";
import { openToaster } from "App/actions";
import { SAVE_MOMENTS_PROFILE_SUCCESS } from "../constants";
import TasteProfileOptions from "assets/data/taste-profile-options.json";

const TastePreferencesProfile: React.FC = () => {
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const {
    tastePreferences,
    action: { actionName },
  } = useAppSelector((state: GlobalIState) => state.HomeFeedReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const submitMomentsParam = searchParams.get("submit");
  const momentsToSubmitCookie = StorageService.getDataByKey("momentsToSubmit");
  const GeneralTasteCookie = StorageService.getDataByKey("generalTasteData");

  const getValue = (index: number) =>
    (tastePreferences?.flavours?.length !== 0
      ? tastePreferences?.flavours[0]?.client_data
      : GeneralTasteCookie
    )?.find((item) => item?.question === TasteProfileOptions[index].title);

  const values = [
    {
      id: 1,
      label: "label.taste.body",
      value: getValue(0)?.answer,
      color: getValue(0)?.color,
      class: styles.bodyValue,
    },
    {
      id: 2,
      label: "label.taste.aroma",
      value: getValue(1)?.answer,
      color: getValue(1)?.color,
      class: styles.aromaValue,
    },
    {
      id: 3,
      label: "label.taste.flavor",
      value: getValue(2)?.answer,
      color: getValue(2)?.color,
      class: styles.flavorValue,
    },
    {
      id: 4,
      label: "label.taste.acidity",
      value: getValue(3)?.answer,
      color: getValue(3)?.color,
      class: styles.acidityValue,
    },
    {
      id: 5,
      label: "label.taste.sweetness",
      value: getValue(4)?.answer,
      color: getValue(4)?.color,
      class: styles.sweetnessValue,
    },
  ];

  const MiddleDotIcon = () => (
    <svg
      width="9"
      height="10"
      viewBox="0 0 9 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* <circle cx="4.26172" cy="4.89844" r="4.24023" fill="#fff" /> */}
    </svg>
  );

  useEffect(() => {
    if (!tastePreferences) {
      dispatch(getTastePreferencesBegin());
    }
  }, [tastePreferences]);

  useEffect(() => {
    if (submitMomentsParam === "y") {
      dispatch(saveMomentsProfileBegin(momentsToSubmitCookie));
    }
  }, [submitMomentsParam]);

  useEffect(() => {
    if (actionName === SAVE_MOMENTS_PROFILE_SUCCESS) {
      dispatch(getTastePreferencesBegin());
      searchParams.delete("submit");
      setSearchParams(searchParams);
      dispatch(
        openToaster(
          "SUCCESS",
          translations("label.your_moments_profile_stored_successfully")
        )
      );
    }
  }, [actionName]);

  return (
    <div
      className={styles.wrapper}
      style={
        { "--color": getDefaultColour(defaultColour) } as React.CSSProperties
      }
    >
      <div className={styles.header}>
        <div className={styles.backButtonContainer}>
          <div onClick={() => navigate("/journal")} className="pointer">
            <CloseFirstTypeIcon color={"#000"} />
          </div>
        </div>
      </div>

      <div className={styles.content}>
        <div className={styles.profileIlustration}>
          <TasteProfileIlustration />
        </div>

        <div className={styles.twoStepsProfile}>
          <div className={styles.step}>
            <h3>{translations("label.taste_profile.your_wine_moments")}</h3>
            <div
              className={styles.card}
              onClick={() => {
                if (tastePreferences?.moments?.length === 0)
                  navigate("/taste-and-preferences/step2");
                else navigate("/taste-and-preferences/moments");
              }}
            >
              {tastePreferences?.moments?.length === 0 ? (
                <PlusIconClassic />
              ) : (
                <FiveQuestionsStored />
              )}
            </div>
          </div>
          <div className={styles.step}>
            <div>
              <h3>
                {translations("label.taste_profile.your_taste_preferences")}
              </h3>
            </div>
            <div
              className={styles.card}
              onClick={() => {
                if (tastePreferences?.flavours?.length === 0)
                  navigate("/taste-and-preferences/step2");
                else navigate("/taste-and-preferences/general-taste/preview");
              }}
            >
              {tastePreferences?.flavours?.length === 0 ? (
                <PlusIconClassic />
              ) : (
                values.map((item, index) => (
                  <div key={index} className={styles.item}>
                    <div className={item.class}>
                      <div
                        style={{ backgroundColor: item.color }}
                        className={styles.dotBox}
                      ></div>
                    </div>

                    {item.id === 1 ? (
                      <div className={styles.bodyLine}>
                        <TastePreferencesFirstLine color={`${item.color}95`} />
                      </div>
                    ) : item.id === 2 ? (
                      <div className={styles.aromaLine}>
                        <TastePreferencesSecondLine color={`${item.color}95`} />
                      </div>
                    ) : item.id === 3 ? (
                      <div className={styles.flavorLine}>
                        <TastePreferencesThirdLine color={`${item.color}95`} />
                      </div>
                    ) : item.id === 4 ? (
                      <div className={styles.acidityLine}>
                        <TastePreferencesFourthLine color={`${item.color}95`} />
                      </div>
                    ) : null}
                  </div>
                ))
              )}
            </div>
          </div>
        </div>

        <h3>{translations("label.your_yes_and_no")}</h3>
        <div
          className={styles.yesAndNoProfile}
          onClick={() => {
            if (tastePreferences?.taste_preferences?.length === 0)
              navigate("/taste-and-preferences/step1");
            else navigate("/taste-and-preferences/yes-and-no");
          }}
        >
          {tastePreferences?.taste_preferences?.length === 0 ? (
            <PlusIconClassic />
          ) : (
            <div className={styles.yesAndNoData}>
              <div className={styles.pros}>
                {tastePreferences?.taste_preferences[0]?.client_data?.yes?.map(
                  (item, index) => {
                    return (
                      <div key={index} className={styles.prosOption}>
                        <span>{item}</span>
                        <ProsIcon />
                      </div>
                    );
                  }
                )}
              </div>
              <div className={styles.cons}>
                {tastePreferences?.taste_preferences[0]?.client_data?.no?.map(
                  (item, index) => {
                    return (
                      <div key={index} className={styles.consOption}>
                        <span>{item}</span>
                        <ConsIcon />
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          )}
        </div>

        <span>{translations("label.taste_profile.subtitle")}</span>
      </div>
    </div>
  );
};

export default TastePreferencesProfile;
