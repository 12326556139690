import { call, put, takeLatest } from "redux-saga/effects";

import {
  fetchProductBookmarksFailed,
  fetchProductBookmarksSuccess,
  fetchProductDetailsFailed,
  fetchProductDetailsSuccess,
  fetchProductsCountFailed,
  fetchProductsCountSuccess,
  fetchProductsFailed,
  fetchProductsSuccess,
  getAdProductDataFailed,
  getAdProductDataSuccess,
  getAppellationsFiltersFailed,
  getAppellationsFiltersSuccess,
  getCustomerPortalFailed,
  getCustomerPortalSuccess,
  getGuestLastOrderFailed,
  getGuestLastOrderSuccess,
  getLastOrderFailed,
  getLastOrderSuccess,
  getMagnumWinesFailed,
  getMagnumWinesSuccess,
  getNeckTiesFailed,
  getNeckTiesSuccess,
  getOneHourDeliveryProductsFailed,
  getOneHourDeliveryProductsSuccess,
  getPurchasingFiltersFailed,
  getPurchasingFiltersSuccess,
  getRegionsFilterFailed,
  getRegionsFilterSuccess,
  getShowcasedProductFailed,
  getShowcasedProductSuccess,
  getWinesByCountryFailed,
  getWinesByCountrySuccess,
  orderAsGuestFailed,
  orderAsGuestSuccess,
  orderFailed,
  orderSuccess,
  sendGuestOrderInvoiceFailed,
  sendGuestOrderInvoiceSuccess,
  sendOrderInvoiceFailed,
  sendOrderInvoiceSuccess,
  submitProductBookmarksStoredFailed,
  submitProductBookmarksStoredSuccess,
  toggleBookmarkProductFailed,
  toggleBookmarkProductSuccess,
  updateShipmentAddressFailed,
  updateShipmentAddressSuccess,
} from "./actions";
import * as purchasingWineService from "services/purchasing.service";

import { openToaster } from "App/actions";
import {
  FETCH_PRODUCTS_BEGIN,
  FETCH_PRODUCTS_COUNT_BEGIN,
  FETCH_PRODUCT_BOOKMARKS_BEGIN,
  FETCH_PRODUCT_DETAILS_BEGIN,
  GET_AD_PRODUCT_INFO_BEGIN,
  GET_APPELLATIONS_FILTERS_BEGIN,
  GET_CUSTOMER_PORTAL_BEGIN,
  GET_GUEST_LAST_ORDER_BEGIN,
  GET_LAST_ORDER_BEGIN,
  GET_MAGNUM_WINES_BEGIN,
  GET_NECK_TIES_BEGIN,
  GET_ONE_HOUR_DELIVERY_PRODUCTS_BEGIN,
  GET_PURCHASING_FILTERS_BEGIN,
  GET_REGIONS_FILTERS_BEGIN,
  GET_SHOWCASED_PRODUCT_BEGIN,
  GET_WINES_BY_COUNTRY_BEGIN,
  ORDER_AS_GUEST_BEGIN,
  ORDER_BEGIN,
  SEND_GUEST_ORDER_INVOICE_BEGIN,
  SEND_ORDER_INVOICE_BEGIN,
  SUBMIT_PRODUCT_BOOKMARKS_STORED_BEGIN,
  TOGGLE_BOOKMARK_PRODUCT_BEGIN,
  UPDATE_SHIPMENT_ADDRESS_BEGIN,
} from "./constants";
import StorageService from "services/storage.service";
import { getClientBegin } from "pages/Profile/actions";

// const productsPage = StorageService.getDataByKey("products_page");

export function* fetchProducts(action) {
  const countryExists = action.payload.filters.some(
    (filter) => filter.filter === "Country"
  );

  let filtersList = (action.payload.filters = action.payload.filters.filter(
    (filter) => {
      if (
        !countryExists &&
        (filter.filter === "Region" || filter.filter === "Appellation")
      ) {
        return false;
      }
      return true;
    }
  ));

  try {
    const response = yield call(purchasingWineService.fetchProducts, {
      type: action.payload.type,
      page: action.payload.page,
      search: action.payload.search,
      filters: filtersList,
      lang: action.payload.lang,
    });

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      StorageService.setKeyValue(
        "numOfPages",
        response?.data?.data?.numOfPages
      );
      yield put(fetchProductsSuccess(response?.data?.data?.products));
    } else {
      yield put(fetchProductsFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(fetchProductsFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* fetchProductDetails(action) {
  try {
    const response = yield call(purchasingWineService.fetchProductDetails, {
      product_id: action.payload.id,
      lang: action.payload.lang,
    });

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(fetchProductDetailsSuccess(response?.data?.data?.product));
    } else {
      yield put(fetchProductDetailsFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(fetchProductDetailsFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* updateShipmentAddress(action) {
  try {
    const response = yield call(
      purchasingWineService.updateDeliveryAndBillingAddress,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(getClientBegin());
      yield put(updateShipmentAddressSuccess(response?.data?.data?.product));
    } else {
      yield put(updateShipmentAddressFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(updateShipmentAddressFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* order(action) {
  try {
    const response = yield call(purchasingWineService.order, action.payload);

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      let payload = {
        data: response?.data?.data?.order_data.order,
        session_url: response?.data?.data?.session_url,
        is_winner: response?.data?.data?.is_winner,
        is_taken_over: response?.data?.data?.is_taken_over,
        celebrity_image_url: response?.data?.data?.celebrity_image_url,
        celebrity_name: response?.data?.data?.celebrity_name,
      };

      yield put(orderSuccess(payload));
    } else {
      if (response?.data?.wine_name) {
        StorageService.setKeyValue(
          "error_wine_name_missing",
          response?.data?.wine_name
        );
      }
      yield put(orderFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(orderFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* orderAsGuest(action) {
  try {
    const response = yield call(
      purchasingWineService.orderAsGuest,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      let payload = {
        data: response?.data?.data?.order_data.order,
        session_url: response?.data?.data?.session_url,
      };

      StorageService.deleteCookie("orderType");
      yield put(orderAsGuestSuccess(payload));
    } else {
      if (response?.data?.wine_name) {
        StorageService.setKeyValue(
          "error_wine_name_missing",
          response?.data?.wine_name
        );
      }
      yield put(orderAsGuestFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(orderAsGuestFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* sendOrderInvoice(action) {
  try {
    const response = yield call(
      purchasingWineService.sendOrderInvoice,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(sendOrderInvoiceSuccess(response?.data));
    } else {
      yield put(sendOrderInvoiceFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(sendOrderInvoiceFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* sendGuestOrderInvoice(action) {
  try {
    const response = yield call(
      purchasingWineService.sendGuestOrderInvoice,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(sendGuestOrderInvoiceSuccess(response?.data));
    } else {
      yield put(sendGuestOrderInvoiceFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(sendGuestOrderInvoiceFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* getCustomerPortal(action) {
  try {
    const response = yield call(purchasingWineService.getCustomerPortal);

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(getCustomerPortalSuccess(response?.data?.data));
    } else {
      yield put(getCustomerPortalFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(getCustomerPortalFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* fetchPurchasingFilters(action) {
  try {
    const response = yield call(
      purchasingWineService.getPurchasingFilters,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(getPurchasingFiltersSuccess(response?.data?.data));
    } else {
      yield put(getPurchasingFiltersFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(getPurchasingFiltersFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* getRegionsFilter(action) {
  try {
    const response = yield call(
      purchasingWineService.getRegionsFilter,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(getRegionsFilterSuccess(response?.data?.data));
    } else {
      yield put(getRegionsFilterFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(getRegionsFilterFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* getAppellationsFilter(action) {
  try {
    const response = yield call(
      purchasingWineService.getAppellationsFilter,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(getAppellationsFiltersSuccess(response?.data?.data));
    } else {
      yield put(getAppellationsFiltersFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(getAppellationsFiltersFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* toggleBookmarkProduct(action) {
  try {
    const response = yield call(
      purchasingWineService.toggleBookmarkProduct,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(toggleBookmarkProductSuccess(response?.data));
    } else {
      yield put(toggleBookmarkProductFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(toggleBookmarkProductFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* getProductBookmarks(action) {
  try {
    const response = yield call(
      purchasingWineService.getProductBookmarks,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(fetchProductBookmarksSuccess(response?.data?.data));
    } else {
      yield put(fetchProductBookmarksFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(fetchProductBookmarksFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* submitProductBookmarks(action) {
  try {
    const response = yield call(
      purchasingWineService.submitProductBookmarks,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      localStorage.setItem("product_bookmarks", JSON.stringify([]));
      yield put(submitProductBookmarksStoredSuccess(response?.data));
    } else {
      yield put(submitProductBookmarksStoredFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(submitProductBookmarksStoredFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* fetchProductsCount(action) {
  try {
    const response = yield call(purchasingWineService.fetchProductsCount, {
      search: action.payload.search,
      filters: action.payload.filters,
    });

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(fetchProductsCountSuccess(response?.data?.data?.productsCount));
    } else {
      yield put(fetchProductsCountFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(fetchProductsCountFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* getLastOrder(action) {
  try {
    const response = yield call(purchasingWineService.getLastOrder);

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(getLastOrderSuccess(response?.data?.data));
    } else {
      yield put(getLastOrderFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(getLastOrderFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* getGuestLastOrder(action) {
  try {
    const response = yield call(
      purchasingWineService.getGuestLastOrder,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(getGuestLastOrderSuccess(response?.data?.data));
    } else {
      yield put(getGuestLastOrderFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(getGuestLastOrderFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* getAdProductData(action) {
  try {
    const response = yield call(
      purchasingWineService.getAdProductData,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(getAdProductDataSuccess(response?.data?.data));
    } else {
      yield put(getAdProductDataFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(getAdProductDataFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* getNeckTies(action) {
  try {
    const response = yield call(purchasingWineService.getNeckTies);

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(getNeckTiesSuccess(response?.data?.data));
    } else {
      yield put(getNeckTiesFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(getNeckTiesFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* getWinesByCountry(action) {
  try {
    const response = yield call(
      purchasingWineService.getWinesByCountry,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(getWinesByCountrySuccess(response?.data?.data));
    } else {
      yield put(getWinesByCountryFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(getWinesByCountryFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* getMagnumWines(action) {
  try {
    const response = yield call(
      purchasingWineService.getMagnumWines,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(getMagnumWinesSuccess(response?.data?.data));
    } else {
      yield put(getMagnumWinesFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(getMagnumWinesFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* getShowcasedProduct(action) {
  try {
    const response = yield call(
      purchasingWineService.getShowcasedProduct,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(getShowcasedProductSuccess(response?.data?.data));
    } else {
      yield put(getShowcasedProductFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(getShowcasedProductFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* getOneHourDeliveryProducts(action) {
  try {
    const response = yield call(
      purchasingWineService.getOneHourDeliveryProducts,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(getOneHourDeliveryProductsSuccess(response?.data?.data));
    } else {
      yield put(getOneHourDeliveryProductsFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(getOneHourDeliveryProductsFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export default function* purchasingWineSaga() {
  yield takeLatest(FETCH_PRODUCTS_BEGIN, fetchProducts);
  yield takeLatest(FETCH_PRODUCT_DETAILS_BEGIN, fetchProductDetails);
  yield takeLatest(UPDATE_SHIPMENT_ADDRESS_BEGIN, updateShipmentAddress);
  yield takeLatest(ORDER_BEGIN, order);
  yield takeLatest(ORDER_AS_GUEST_BEGIN, orderAsGuest);
  yield takeLatest(SEND_ORDER_INVOICE_BEGIN, sendOrderInvoice);
  yield takeLatest(SEND_GUEST_ORDER_INVOICE_BEGIN, sendGuestOrderInvoice);
  yield takeLatest(GET_CUSTOMER_PORTAL_BEGIN, getCustomerPortal);
  yield takeLatest(GET_PURCHASING_FILTERS_BEGIN, fetchPurchasingFilters);
  yield takeLatest(GET_REGIONS_FILTERS_BEGIN, getRegionsFilter);
  yield takeLatest(GET_APPELLATIONS_FILTERS_BEGIN, getAppellationsFilter);
  yield takeLatest(TOGGLE_BOOKMARK_PRODUCT_BEGIN, toggleBookmarkProduct);
  yield takeLatest(FETCH_PRODUCT_BOOKMARKS_BEGIN, getProductBookmarks);
  yield takeLatest(
    SUBMIT_PRODUCT_BOOKMARKS_STORED_BEGIN,
    submitProductBookmarks
  );
  yield takeLatest(FETCH_PRODUCTS_COUNT_BEGIN, fetchProductsCount);
  yield takeLatest(GET_LAST_ORDER_BEGIN, getLastOrder);
  yield takeLatest(GET_GUEST_LAST_ORDER_BEGIN, getGuestLastOrder);
  yield takeLatest(GET_AD_PRODUCT_INFO_BEGIN, getAdProductData);
  yield takeLatest(GET_NECK_TIES_BEGIN, getNeckTies);
  yield takeLatest(GET_WINES_BY_COUNTRY_BEGIN, getWinesByCountry);
  yield takeLatest(GET_MAGNUM_WINES_BEGIN, getMagnumWines);
  yield takeLatest(GET_SHOWCASED_PRODUCT_BEGIN, getShowcasedProduct);
  yield takeLatest(
    GET_ONE_HOUR_DELIVERY_PRODUCTS_BEGIN,
    getOneHourDeliveryProducts
  );
}
