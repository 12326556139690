export enum TASTING_TYPES_LABELS {
  BODY_1 = "label.tasting_body1",
  BODY_2 = "label.tasting_body2",
  BODY_3 = "label.tasting_body3",
  BODY_4 = "label.tasting_body4",
  BODY_5 = "label.tasting_body5",

  AROMA_1 = "label.tasting_aroma1",
  AROMA_2 = "label.tasting_aroma2",
  AROMA_3 = "label.tasting_aroma3",
  AROMA_4 = "label.tasting_aroma4",
  AROMA_5 = "label.tasting_aroma5",

  FLAVOR_1 = "label.tasting_flavor1",
  FLAVOR_2 = "label.tasting_flavor2",
  FLAVOR_3 = "label.tasting_flavor3",
  FLAVOR_4 = "label.tasting_flavor4",
  FLAVOR_5 = "label.tasting_flavor5",

  ACIDITY_1 = "label.tasting_acidity1",
  ACIDITY_2 = "label.tasting_acidity2",
  ACIDITY_3 = "label.tasting_acidity3",
  ACIDITY_4 = "label.tasting_acidity4",
  ACIDITY_5 = "label.tasting_acidity5",

  SWEETNESS_1 = "label.tasting_sweetness1",
  SWEETNESS_2 = "label.tasting_sweetness2",
  SWEETNESS_3 = "label.tasting_sweetness3",
  SWEETNESS_4 = "label.tasting_sweetness4",
  SWEETNESS_5 = "label.tasting_sweetness5",
}

export enum LOGIN_DEVICE {
  WEB_APP = "WEB APP",
}
