import {
  ISendOrderInvoiceAsGuest,
  OrderAsGuestInterface,
  OrderInterface,
} from "types/pages/purchasing.page.type";
import {
  CLEAR_CART_ITEMS,
  UPDATE_QUANTITY,
  SET_CART_ITEMS,
  POPULATE_CART_ITEMS,
  DELETE_CART_ITEM,
  FETCH_PRODUCTS_BEGIN,
  FETCH_PRODUCTS_FAILED,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCT_DETAILS_BEGIN,
  FETCH_PRODUCT_DETAILS_SUCCESS,
  FETCH_PRODUCT_DETAILS_FAILED,
  UPDATE_SHIPMENT_ADDRESS_BEGIN,
  UPDATE_SHIPMENT_ADDRESS_SUCCESS,
  UPDATE_SHIPMENT_ADDRESS_FAILED,
  ORDER_BEGIN,
  ORDER_SUCCESS,
  ORDER_FAILED,
  SEND_ORDER_INVOICE_BEGIN,
  SEND_ORDER_INVOICE_SUCCESS,
  SEND_ORDER_INVOICE_FAILED,
  GET_CUSTOMER_PORTAL_BEGIN,
  GET_CUSTOMER_PORTAL_SUCCESS,
  GET_CUSTOMER_PORTAL_FAILED,
  SET_FILTERS,
  GET_PURCHASING_FILTERS_BEGIN,
  GET_PURCHASING_FILTERS_SUCCESS,
  GET_PURCHASING_FILTERS_FAILED,
  GET_REGIONS_FILTERS_BEGIN,
  GET_REGIONS_FILTERS_SUCCESS,
  GET_REGIONS_FILTERS_FAILED,
  GET_APPELLATIONS_FILTERS_BEGIN,
  GET_APPELLATIONS_FILTERS_SUCCESS,
  GET_APPELLATIONS_FILTERS_FAILED,
  ORDER_AS_GUEST_BEGIN,
  ORDER_AS_GUEST_SUCCESS,
  ORDER_AS_GUEST_FAILED,
  SEND_GUEST_ORDER_INVOICE_BEGIN,
  SEND_GUEST_ORDER_INVOICE_SUCCESS,
  SEND_GUEST_ORDER_INVOICE_FAILED,
  TOGGLE_BOOKMARK_PRODUCT_BEGIN,
  TOGGLE_BOOKMARK_PRODUCT_SUCCESS,
  TOGGLE_BOOKMARK_PRODUCT_FAILED,
  FETCH_PRODUCT_BOOKMARKS_BEGIN,
  FETCH_PRODUCT_BOOKMARKS_SUCCESS,
  FETCH_PRODUCT_BOOKMARKS_FAILED,
  SET_PRODUCT_BOOKMARKS_COOKIE,
  POPULATE_PRODUCT_BOOKMARKS,
  SUBMIT_PRODUCT_BOOKMARKS_STORED_BEGIN,
  SUBMIT_PRODUCT_BOOKMARKS_STORED_SUCCESS,
  SUBMIT_PRODUCT_BOOKMARKS_STORED_FAILED,
  FETCH_PRODUCTS_COUNT_BEGIN,
  FETCH_PRODUCTS_COUNT_FAILED,
  FETCH_PRODUCTS_COUNT_SUCCESS,
  CLEAR_FILTERS,
  GET_LAST_ORDER_BEGIN,
  GET_LAST_ORDER_SUCCESS,
  GET_LAST_ORDER_FAILED,
  GET_AD_PRODUCT_INFO_BEGIN,
  GET_AD_PRODUCT_INFO_SUCCESS,
  GET_AD_PRODUCT_INFO_FAILED,
  GET_GUEST_LAST_ORDER_BEGIN,
  GET_GUEST_LAST_ORDER_SUCCESS,
  GET_GUEST_LAST_ORDER_FAILED,
  CLEAR_COUNTRY_REGION_APPELLATION_FILTER,
  POPULATE_FILTERS,
  GET_NECK_TIES_BEGIN,
  GET_NECK_TIES_SUCCESS,
  GET_NECK_TIES_FAILED,
  GET_WINES_BY_COUNTRY_BEGIN,
  GET_WINES_BY_COUNTRY_SUCCESS,
  GET_WINES_BY_COUNTRY_FAILED,
  GET_MAGNUM_WINES_BEGIN,
  GET_MAGNUM_WINES_SUCCESS,
  GET_MAGNUM_WINES_FAILED,
  GET_SHOWCASED_PRODUCT_BEGIN,
  GET_SHOWCASED_PRODUCT_SUCCESS,
  GET_SHOWCASED_PRODUCT_FAILED,
  GET_ONE_HOUR_DELIVERY_PRODUCTS_BEGIN,
  GET_ONE_HOUR_DELIVERY_PRODUCTS_SUCCESS,
  GET_ONE_HOUR_DELIVERY_PRODUCTS_FAILED,
} from "./constants";

export const setCartItems = (data) => {
  return {
    type: SET_CART_ITEMS,
    payload: data,
  };
};

export const deleteCartItem = (id: number) => {
  return {
    type: DELETE_CART_ITEM,
    payload: id,
  };
};

export const updateQuantity = (operation: string, id: number) => {
  return {
    type: UPDATE_QUANTITY,
    payload: { operation, id },
  };
};

export const clearCartItems = () => {
  return {
    type: CLEAR_CART_ITEMS,
  };
};

export const updateCartItems = (state: any, id: string) => {
  return {
    type: POPULATE_CART_ITEMS,
    payload: { state, id },
  };
};

export const populateCartItems = (data: any) => {
  return {
    type: POPULATE_CART_ITEMS,
    payload: data,
  };
};

export const fetchProductsBegin = (
  type: string,
  page: number,
  search: string,
  filters: any[],
  lang: string
) => ({
  type: FETCH_PRODUCTS_BEGIN,
  payload: {
    page: page,
    search: search,
    filters: filters,
    lang: lang,
    type: type,
  },
});

export const fetchProductsSuccess = (data) => ({
  type: FETCH_PRODUCTS_SUCCESS,
  payload: data,
});

export const fetchProductsFailed = (error) => ({
  type: FETCH_PRODUCTS_FAILED,
  payload: error,
});

export const fetchProductDetailsBegin = (id, lang) => ({
  type: FETCH_PRODUCT_DETAILS_BEGIN,
  payload: { id, lang },
});

export const fetchProductDetailsSuccess = (data) => ({
  type: FETCH_PRODUCT_DETAILS_SUCCESS,
  payload: data,
});

export const fetchProductDetailsFailed = (error) => ({
  type: FETCH_PRODUCT_DETAILS_FAILED,
  payload: error,
});

export const updateShipmentAddressBegin = (data) => ({
  type: UPDATE_SHIPMENT_ADDRESS_BEGIN,
  payload: data,
});

export const updateShipmentAddressSuccess = (data) => ({
  type: UPDATE_SHIPMENT_ADDRESS_SUCCESS,
  payload: data,
});

export const updateShipmentAddressFailed = (error) => ({
  type: UPDATE_SHIPMENT_ADDRESS_FAILED,
  payload: error,
});

export const orderBegin = (data: OrderInterface) => ({
  type: ORDER_BEGIN,
  payload: data,
});

export const orderSuccess = (data) => ({
  type: ORDER_SUCCESS,
  payload: data,
});

export const orderFailed = (error) => ({
  type: ORDER_FAILED,
  payload: error,
});

export const orderAsGuestBegin = (data: OrderAsGuestInterface) => ({
  type: ORDER_AS_GUEST_BEGIN,
  payload: data,
});

export const orderAsGuestSuccess = (data) => ({
  type: ORDER_AS_GUEST_SUCCESS,
  payload: data,
});

export const orderAsGuestFailed = (error) => ({
  type: ORDER_AS_GUEST_FAILED,
  payload: error,
});

export const sendOrderInvoiceBegin = (order_id: string) => ({
  type: SEND_ORDER_INVOICE_BEGIN,
  payload: order_id,
});

export const sendOrderInvoiceSuccess = (data) => ({
  type: SEND_ORDER_INVOICE_SUCCESS,
  payload: data,
});

export const sendOrderInvoiceFailed = (error) => ({
  type: SEND_ORDER_INVOICE_FAILED,
  payload: error,
});

export const sendGuestOrderInvoiceBegin = (data: ISendOrderInvoiceAsGuest) => ({
  type: SEND_GUEST_ORDER_INVOICE_BEGIN,
  payload: data,
});

export const sendGuestOrderInvoiceSuccess = (data) => ({
  type: SEND_GUEST_ORDER_INVOICE_SUCCESS,
  payload: data,
});

export const sendGuestOrderInvoiceFailed = (error) => ({
  type: SEND_GUEST_ORDER_INVOICE_FAILED,
  payload: error,
});

export const getCustomerPortalBegin = () => ({
  type: GET_CUSTOMER_PORTAL_BEGIN,
});

export const getCustomerPortalSuccess = (data) => ({
  type: GET_CUSTOMER_PORTAL_SUCCESS,
  payload: data,
});

export const getCustomerPortalFailed = (error) => ({
  type: GET_CUSTOMER_PORTAL_FAILED,
  payload: error,
});

export const setFilters = (data: { filter: string; value?: string[] }) => ({
  type: SET_FILTERS,
  payload: data,
});

export const populateFilters = (data) => ({
  type: POPULATE_FILTERS,
  payload: data,
});

export const clearFilters = () => ({
  type: CLEAR_FILTERS,
});

export const getPurchasingFiltersBegin = (lang: string) => ({
  type: GET_PURCHASING_FILTERS_BEGIN,
  payload: lang,
});

export const getPurchasingFiltersSuccess = (data) => ({
  type: GET_PURCHASING_FILTERS_SUCCESS,
  payload: data,
});

export const getPurchasingFiltersFailed = (error) => ({
  type: GET_PURCHASING_FILTERS_FAILED,
  payload: error,
});

export const getRegionsFilterBegin = (data) => ({
  type: GET_REGIONS_FILTERS_BEGIN,
  payload: data,
});

export const getRegionsFilterSuccess = (data) => ({
  type: GET_REGIONS_FILTERS_SUCCESS,
  payload: data,
});

export const getRegionsFilterFailed = (error) => ({
  type: GET_REGIONS_FILTERS_FAILED,
  payload: error,
});

export const getAppellationsFiltersBegin = (data) => ({
  type: GET_APPELLATIONS_FILTERS_BEGIN,
  payload: data,
});

export const getAppellationsFiltersSuccess = (data) => ({
  type: GET_APPELLATIONS_FILTERS_SUCCESS,
  payload: data,
});

export const getAppellationsFiltersFailed = (error) => ({
  type: GET_APPELLATIONS_FILTERS_FAILED,
  payload: error,
});

export const toggleBookmarkProductBegin = (product_id: string) => ({
  type: TOGGLE_BOOKMARK_PRODUCT_BEGIN,
  payload: product_id,
});

export const toggleBookmarkProductSuccess = (data) => ({
  type: TOGGLE_BOOKMARK_PRODUCT_SUCCESS,
  payload: data,
});

export const toggleBookmarkProductFailed = (error) => ({
  type: TOGGLE_BOOKMARK_PRODUCT_FAILED,
  payload: error,
});

export const fetchProductBookmarksBegin = (lang) => ({
  type: FETCH_PRODUCT_BOOKMARKS_BEGIN,
  payload: lang,
});

export const fetchProductBookmarksSuccess = (data) => ({
  type: FETCH_PRODUCT_BOOKMARKS_SUCCESS,
  payload: data,
});

export const fetchProductBookmarksFailed = (error) => ({
  type: FETCH_PRODUCT_BOOKMARKS_FAILED,
  payload: error,
});

export const setProductBookmarksCookie = (data) => {
  return {
    type: SET_PRODUCT_BOOKMARKS_COOKIE,
    payload: data,
  };
};

export const populateProductBookmarks = (data: any) => {
  return {
    type: POPULATE_PRODUCT_BOOKMARKS,
    payload: data,
  };
};

export const submitProductBookmarksStoredBegin = (data) => ({
  type: SUBMIT_PRODUCT_BOOKMARKS_STORED_BEGIN,
  payload: data,
});

export const submitProductBookmarksStoredSuccess = (data) => ({
  type: SUBMIT_PRODUCT_BOOKMARKS_STORED_SUCCESS,
  payload: data,
});

export const submitProductBookmarksStoredFailed = (error) => ({
  type: SUBMIT_PRODUCT_BOOKMARKS_STORED_FAILED,
  payload: error,
});

export const fetchProductsCountBegin = (search: string, filters?: any[]) => ({
  type: FETCH_PRODUCTS_COUNT_BEGIN,
  payload: { search: search, filters: filters },
});

export const fetchProductsCountSuccess = (data) => ({
  type: FETCH_PRODUCTS_COUNT_SUCCESS,
  payload: data,
});

export const fetchProductsCountFailed = (error) => ({
  type: FETCH_PRODUCTS_COUNT_FAILED,
  payload: error,
});

export const getLastOrderBegin = () => ({
  type: GET_LAST_ORDER_BEGIN,
});

export const getLastOrderSuccess = (data) => ({
  type: GET_LAST_ORDER_SUCCESS,
  payload: data,
});

export const getLastOrderFailed = (error) => ({
  type: GET_LAST_ORDER_FAILED,
  payload: error,
});

export const getGuestLastOrderBegin = (data) => ({
  type: GET_GUEST_LAST_ORDER_BEGIN,
  payload: data,
});

export const getGuestLastOrderSuccess = (data) => ({
  type: GET_GUEST_LAST_ORDER_SUCCESS,
  payload: data,
});

export const getGuestLastOrderFailed = (error) => ({
  type: GET_GUEST_LAST_ORDER_FAILED,
  payload: error,
});

export const getAdProductDataBegin = (data: {
  product_id: string;
  lang: string;
}) => ({
  type: GET_AD_PRODUCT_INFO_BEGIN,
  payload: data,
});

export const getAdProductDataSuccess = (data) => ({
  type: GET_AD_PRODUCT_INFO_SUCCESS,
  payload: data,
});

export const getAdProductDataFailed = (error) => ({
  type: GET_AD_PRODUCT_INFO_FAILED,
  payload: error,
});

export const clearCountryRegionAppellationFilter = () => ({
  type: CLEAR_COUNTRY_REGION_APPELLATION_FILTER,
});

export const getNeckTiesBegin = () => ({
  type: GET_NECK_TIES_BEGIN,
});

export const getNeckTiesSuccess = (data) => ({
  type: GET_NECK_TIES_SUCCESS,
  payload: data,
});

export const getNeckTiesFailed = (error) => ({
  type: GET_NECK_TIES_FAILED,
  payload: error,
});

export const getWinesByCountryBegin = (data: {
  country: string;
  lang: string;
}) => ({
  type: GET_WINES_BY_COUNTRY_BEGIN,
  payload: data,
});

export const getWinesByCountrySuccess = (data) => ({
  type: GET_WINES_BY_COUNTRY_SUCCESS,
  payload: data,
});

export const getWinesByCountryFailed = (error) => ({
  type: GET_WINES_BY_COUNTRY_FAILED,
  payload: error,
});

export const getMagnumWinesBegin = (lang: string) => ({
  type: GET_MAGNUM_WINES_BEGIN,
  payload: lang,
});

export const getMagnumWinesSuccess = (data) => ({
  type: GET_MAGNUM_WINES_SUCCESS,
  payload: data,
});

export const getMagnumWinesFailed = (error) => ({
  type: GET_MAGNUM_WINES_FAILED,
  payload: error,
});

export const getShowcasedProductBegin = (lang: string) => ({
  type: GET_SHOWCASED_PRODUCT_BEGIN,
  payload: lang,
});

export const getShowcasedProductSuccess = (data) => ({
  type: GET_SHOWCASED_PRODUCT_SUCCESS,
  payload: data,
});

export const getShowcasedProductFailed = (error) => ({
  type: GET_SHOWCASED_PRODUCT_FAILED,
  payload: error,
});

export const getOneHourDeliveryProductsBegin = (lang) => ({
  type: GET_ONE_HOUR_DELIVERY_PRODUCTS_BEGIN,
  payload: lang,
});

export const getOneHourDeliveryProductsSuccess = (data) => ({
  type: GET_ONE_HOUR_DELIVERY_PRODUCTS_SUCCESS,
  payload: data,
});

export const getOneHourDeliveryProductsFailed = (error) => ({
  type: GET_ONE_HOUR_DELIVERY_PRODUCTS_FAILED,
  payload: error,
});
