import { useTranslation } from "react-i18next";
import styles from "./LoginByPhoneAuthSms.module.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks";

import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import { GlobalIState } from "types/RootState";

import PasscodeInput from "components/PasscodeInput/PasscodeInput.component";
import { useEffect, useState } from "react";
import { PreviousIcon } from "assets/icons/icons";
import { getDefaultColour } from "utils/utils";
import ButtonComponent from "components/Button/Button.component";
import { openToaster } from "App/actions";
import StorageService from "services/storage.service";
import { logInBegin, sendSmsCodeBegin } from "pages/Authentication/actions";
import { LOG_IN_SUCCESS } from "pages/Authentication/constants";
import { LOGIN_DEVICE } from "utils/enums";

export default function LoginByPhoneAuthSms() {
  const {
    action: { actionName, isLoading, error },
  } = useAppSelector((state: GlobalIState) => state.authenticationReducer);
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();
  let isMobile = useCheckMobileScreen();
  const [searchParams] = useSearchParams();
  const [smsCode, setSmsCode] = useState("");
  const [showPasscode, setShowPasscode] = useState(true);
  const emailParam = searchParams.get("email");
  const countryParam = searchParams.get("country");
  const phoneParam = searchParams.get("phone");

  const getStoredLanguage = () => {
    return localStorage.getItem("lang");
  };

  const isLoggedIn = () => StorageService.getDataByKey("token");

  const handleSendCodeAgain = () => {
    dispatch(
      sendSmsCodeBegin({
        country_phone: countryParam?.replaceAll(" ", "+"),
        phone: phoneParam,
      })
    );
  };

  const handleSave = () => {
    if (smsCode?.length !== 6) {
      dispatch(
        openToaster("ERROR", translations("error.please_fill_passcode_field"))
      );
      return;
    }
    const data = {
      user: "",
      password: "",
      country_phone: countryParam?.replaceAll(" ", "+"),
      phone: phoneParam,
      google_auth_code: "",
      login_method: "phone",
      two_factor_code: "",
      sms_code: smsCode,
      last_login_device: LOGIN_DEVICE.WEB_APP,
    };

    dispatch(logInBegin(data));
  };

  useEffect(() => {
    if (error === "error.send_2fa_two_factor_code") {
      let data = {
        user: "",
        password: "",
        country_phone: countryParam?.replaceAll(" ", "+"),
        phone: phoneParam,
        google_auth_code: "",
        login_method: "phone",
      };
      StorageService.setKeyValue("loginTwoFactorData", data);
      navigate("/login/two-factor");
    }
  }, [error]);

  useEffect(() => {
    if (actionName === LOG_IN_SUCCESS || isLoggedIn()) {
      navigate("/");
    }
  }, [actionName, isLoggedIn()]);

  return (
    <div
      className={styles.container}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      <div className={styles.header}>
        <div
          className={styles.previousIcon}
          onClick={() => {
            navigate(-1);
          }}
        >
          <PreviousIcon color={getDefaultColour(defaultColour)} />
        </div>
      </div>

      <div className={styles.content}>
        <h1>{translations("button.log_in")}</h1>

        <div className={styles.passcodeContainer}>
          <span>{translations("label.log_in_by_phone.auth_code_sent")}</span>

          <PasscodeInput
            length={6}
            showPasscode={showPasscode}
            onChange={setSmsCode}
          />

          <div className={styles.hint} onClick={() => handleSendCodeAgain()}>
            <span>{translations("label.phone.send_code_again")}</span>
          </div>
        </div>

        <div className={styles.submitButton}>
          <ButtonComponent
            label={translations("button.submit")}
            class={"btn btn-primary"}
            isLoading={isLoading}
            handleClick={() => handleSave()}
          />
        </div>
      </div>
    </div>
  );
}
