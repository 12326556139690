import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import { useAppSelector } from "hooks";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GlobalIState } from "types/RootState";
import DefaultWine from "assets/images/default-wine.png";

import styles from "./NoTastePreferencesItems.module.scss";
import {
  DeleteIcon,
  FifthRateWineEmoji,
  FirstRateWineEmoji,
  FourthRateWineEmoji,
  SecondRateWineEmoji,
  ShoppingCartAddIcon,
  ThirdRateWineEmoji,
  YellowStarIcon,
} from "assets/icons/icons";

function NoTastePreferencesItems() {
  const navigate = useNavigate();
  const isMobile = useCheckMobileScreen();
  const { t: translations } = useTranslation();
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );

  const handleClick = () => {
    navigate("/");
  };

  return (
    <div className={styles.container}>
      <h2>{translations("label.record_your_taste_preferences")}</h2>
      <span className={styles.description}>
        {translations("label.journal_no_items_description1")}
      </span>
      <span className={styles.description} style={{ margin: "20px 0px" }}>
        {translations("label.journal_no_items_description2")}
      </span>

      <div className={styles.content}>
        <div className={styles.defaultRow}>
          <div className={styles.productCard}>
            <div className={styles.imageContent}>
              <img src={DefaultWine} alt="Default" />
            </div>
            <div className={styles.rightContent}>
              <div>
                <span className={styles.bold}>
                  {translations("label.producer")}
                </span>
                <span>{translations("label.wine_name")}</span>
              </div>
              <div>
                <span className={styles.bold}>
                  {translations("label.bottle_size")}
                </span>
                <span>75cl</span>
              </div>
            </div>
          </div>
          <div className={styles.label}>
            <span>{translations("label.your_ordered_or_scanned_wines")}</span>
          </div>
        </div>
        <div className={styles.secondRow}>
          <div className={styles.label}>
            <YellowStarIcon />
            <span>{translations("label.taste_and_rate")}</span>
          </div>
          <div className={styles.card}>
            <div className={styles.topsection}>
              <span className={styles.bold}>
                {translations("label.my_tasting")}
              </span>
              <span>
                {translations("label.my_tasting_default_description")}
              </span>
            </div>
            <div className={styles.ratings}>
              <span className={styles.bold}>
                {translations("label.my_rating")}
              </span>
              <div className={styles.options}>
                <FirstRateWineEmoji />
                <SecondRateWineEmoji />
                <ThirdRateWineEmoji />
                <FourthRateWineEmoji />
                <FifthRateWineEmoji />
              </div>
            </div>
          </div>
        </div>

        <div className={`${styles.defaultRow} ${styles.thirdRow}`}>
          <div className={styles.card}>
            <div className={styles.top}>
              <span className={styles.bold}>
                {translations("label.bottle_price")}
              </span>
              <span>CHF 32.24</span>
            </div>
            <div className={styles.bottom}>
              <div className={styles.mainBtn}>
                <ShoppingCartAddIcon />
              </div>
              <div className={styles.deleteWineIcon}>
                <DeleteIcon color="#000" />
              </div>
            </div>
          </div>
          <div className={styles.label}>
            <span>{translations("label.simply_reorder_or_propose")}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NoTastePreferencesItems;
