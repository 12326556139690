import { useAppSelector } from "hooks";
import styles from "./WelcomeCard.module.scss";
import { GlobalIState } from "types/RootState";
import { useTranslation } from "react-i18next";
import {
  ChatCategoryIcon,
  ChatIcon,
  CloseGrayIcon,
  InspireCategoryIcon,
  ScanDocumentIcon,
  WelcomeCardEventsIcon,
  WelcomeCardGamesIcon,
  WelcomeCardInvestmentsIcon,
  WelcomeCardTasteIcon,
  WelcomeCardTripsIcon,
  WelcomeCardWinesIcon,
} from "assets/icons/icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";

function WelcomeCard() {
  const { client } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const { t: translations } = useTranslation();
  const isMobile = useCheckMobileScreen();
  const navigate = useNavigate();

  const getLanguage = () => {
    return localStorage.getItem("lang");
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1>
          {translations("label.hi")} {client?.firstname}.
        </h1>
        <div
          className={styles.closeCard}
          onClick={() => {
            searchParams.set("hide", "y");
            setSearchParams(searchParams);
          }}
        >
          <CloseGrayIcon />
        </div>
        <span>{translations("label.what_would_you_like_to_do_today")}</span>
      </div>

      <div className={styles.content}>
        <div className={styles.card}>
          <div className={styles.col}>
            <div
              className={styles.bordered}
              onClick={() => navigate("/ask-dino")}
            >
              <ChatCategoryIcon />
              <h3>{translations("label.ask_me")}</h3>
            </div>
            <div
              style={{
                minHeight: isMobile && ["fr"].includes(getLanguage()) ? 90 : 60,
              }}
              className={styles.description}
            >
              <span>{translations("label.ask_me.description")}</span>
            </div>
          </div>
          {/* <div className={styles.col}>
            <div className={styles.bordered}>
              <ScanDocumentIcon />
              <h3>{translations("label.scan")}</h3>
            </div>
            <div
              style={{
                minHeight: isMobile && ["fr"].includes(getLanguage()) ? 90 : 60,
              }}
              className={styles.description}
            >
              <span>{translations("label.scan.description")}</span>
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.bordered}>
              <InspireCategoryIcon />
              <h3>{translations("label.inspire")}</h3>
            </div>
            <div
              style={{
                minHeight: isMobile && ["fr"].includes(getLanguage()) ? 90 : 60,
              }}
              className={styles.description}
            >
              <span>{translations("label.inspire.description")}</span>
            </div>
          </div> */}
        </div>

        <div className={styles.descriptionContent}>
          <span style={{ marginBottom: "15px" }}>
            {translations("label.or")}
          </span>

          <span>{translations("label.ask_me.welcome_description")}</span>

          <div className={styles.modules}>
            <div onClick={() => navigate("/purchasing")}>
              <WelcomeCardWinesIcon />
            </div>
            <div onClick={() => navigate("/journal")}>
              <WelcomeCardTasteIcon />
            </div>
            {/* <div>
              <WelcomeCardTripsIcon />
            </div> */}
            {/* <div>
              <WelcomeCardEventsIcon />
            </div> */}
            <div onClick={() => navigate("/games")}>
              <WelcomeCardGamesIcon />
            </div>
            <div onClick={() => navigate("/investments")}>
              <WelcomeCardInvestmentsIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WelcomeCard;
