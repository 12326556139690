import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import styles from "./MobileRateAndTaste.module.scss";
import {
  BackButtonIcon,
  FifthRateWineEmoji,
  FirstRateWineEmoji,
  FourthRateWineEmoji,
  HowItWorksIcon,
  SecondRateWineEmoji,
  ThirdRateWineEmoji,
} from "assets/icons/icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  getClientTastingLabel,
  getDefaultColour,
  modifyHomeFiltersForDate,
} from "utils/utils";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import LoaderWrapper from "components/Loader/LoaderWrapper/LoaderWrapper";

import RateWine from "../RateWine/RateWine.page";
import TasteWine from "../TasteWine/TasteWine.page";
import StorageService from "services/storage.service";
import { getJournalDataBegin } from "pages/PersonalLedger/actions";
import { openModal } from "App/actions";
import { modalTypes } from "types/reducers/modal.reducer.type";

function MobileRateAndTaste() {
  const { t: translations } = useTranslation();
  const {
    action: { isLoading },
    journalWines,
    filters,
  } = useAppSelector((state: GlobalIState) => state.PersonalLedgerReducer);
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const productIdParam = searchParams.get("id");
  const [tastingLabels, setTastingLabels] = useState([""]);
  const [activeTab, setActiveTab] = useState("rating");
  const productOrder = journalWines?.find(
    (item) => item?.product?.id === Number(productIdParam)
  );
  const journalSearchCookie = () =>
    StorageService.getDataByKey("journalSearch");

  const getSelectedRating = (item) => {
    if (item?.product?.ratings?.length !== 0) {
      switch (item?.product?.ratings[0]?.rating) {
        case 1:
          return <FirstRateWineEmoji />;
        case 2:
          return <SecondRateWineEmoji />;
        case 3:
          return <ThirdRateWineEmoji />;
        case 4:
          return <FourthRateWineEmoji />;
        case 5:
          return <FifthRateWineEmoji />;
        default:
          break;
      }
    }
  };

  const handleInfoPopup = () => {
    dispatch(
      openModal({
        content: (
          <div className={styles.infoPopup}>
            <h3>{translations("title.tasting_info")}</h3>
            <div>
              <span>{translations("label.long_desc.body")}</span>
            </div>
            <div>
              <span>{translations("label.long_desc.aroma")}</span>
            </div>
            <div>
              <span>{translations("label.long_desc.flavour")}</span>
            </div>
            <div>
              <span>{translations("label.long_desc.acidity")}</span>
            </div>
            <div>
              <span>{translations("label.long_desc.sweetness")}</span>
            </div>
          </div>
        ),
        type: modalTypes.smInfo,
      })
    );
  };

  useEffect(() => {
    setTastingLabels(
      journalWines?.length > 0
        ? getClientTastingLabel(productOrder?.product?.clientTastings)?.map(
            (label) => translations(label)
          )
        : [""]
    );

    if (!journalWines) {
      dispatch(
        getJournalDataBegin({
          search: journalSearchCookie() ? journalSearchCookie() : "",
          filters:
            filters?.filter((item) => item?.value?.length >= 1)?.length === 0
              ? []
              : modifyHomeFiltersForDate(
                  filters?.filter((item) => item?.value?.length >= 1)
                )?.filters,
        })
      );
    }
  }, [journalWines]);

  return (
    <LoaderWrapper isLoading={isLoading}>
      <div
        className={styles.wrapper}
        style={
          {
            "--color": getDefaultColour(defaultColour),
          } as React.CSSProperties
        }
      >
        <div className={styles.header}>
          <div className="pointer" onClick={() => navigate("/journal")}>
            <BackButtonIcon color={"#000"} />
          </div>
          <div className={styles.tabs}>
            <div
              className={styles.tab}
              onClick={() =>
                navigate(`/journal/product-details?id=${productIdParam}`)
              }
            >
              <span>{translations("label.info")}</span>
            </div>
            <div
              className={styles.activeTab}
              style={{ borderRadius: 0 }}
              onClick={() =>
                navigate(`/journal/rate-and-taste?id=${productIdParam}`)
              }
            >
              <span>{translations("label.tasting")}</span>
            </div>
            <div
              className={styles.tab}
              onClick={() => navigate(`/journal/ledger?id=${productIdParam}`)}
            >
              <span>
                {translations(
                  productOrder?.product?.inputted_data
                    ? "label.place"
                    : "label.ledger"
                )}
              </span>
            </div>
          </div>
        </div>

        <div className={styles.content}>
          <div className={styles.card}>
            <div
              className={`${styles.headerLabel} ${
                activeTab === "tasting" ? styles.active : null
              }`}
              onClick={() => setActiveTab("tasting")}
            >
              <span>{translations("label.my_tasting")}</span>
              <div className={styles.valueLabel}>
                {tastingLabels?.length > 0 ? (
                  <span>
                    {tastingLabels?.length > 0
                      ? tastingLabels?.join(", ")
                      : translations("label.please_give_us_your_tasting")}{" "}
                  </span>
                ) : (
                  <span className={styles.emptyRateOrTaste}>
                    {translations("label.please_give_us_your_tasting")}
                  </span>
                )}
              </div>
            </div>

            <div
              className={`${styles.headerLabel} ${
                activeTab === "rating" ? styles.active : null
              }`}
              onClick={() => setActiveTab("rating")}
            >
              <span>{translations("label.my_rating")}</span>
              <div className={styles.valueLabel}>
                {getSelectedRating(productOrder) ? (
                  getSelectedRating(productOrder)
                ) : (
                  <span className={styles.emptyRateOrTaste}>
                    {translations("label.please_give_us_your_rating")}
                  </span>
                )}
              </div>
            </div>
          </div>

          {activeTab === "tasting" ? (
            <div className={styles.infoIcon} onClick={() => handleInfoPopup()}>
              <HowItWorksIcon />
            </div>
          ) : null}

          {activeTab === "rating" ? (
            <RateWine setActiveTab={setActiveTab} product_id={productIdParam} />
          ) : (
            <TasteWine
              setActiveTab={setActiveTab}
              product_id={productIdParam}
            />
          )}
        </div>
      </div>
    </LoaderWrapper>
  );
}

export default MobileRateAndTaste;
