import { useTranslation } from "react-i18next";
import styles from "./TasteWine.module.scss";
import { getTastingColorOptions, modifyHomeFiltersForDate } from "utils/utils";
import ButtonComponent from "components/Button/Button.component";
import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import { useEffect, useState } from "react";
import { DownArrow } from "assets/icons/icons";
import {
  SET_ADDED_WINE_TASTE_SUCCESS,
  SET_PRODUCT_TASTE_SUCCESS,
} from "pages/PersonalLedger/constants";
import {
  getJournalDataBegin,
  setAddedWineTasteBegin,
  setProductTasteBegin,
} from "pages/PersonalLedger/actions";
import { openToaster, scrollToTop } from "App/actions";
import { ITastings } from "types/reducers/personal-ledger.reducer.type";
import LoaderWrapper from "components/Loader/LoaderWrapper/LoaderWrapper";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import StorageService from "services/storage.service";

interface Props {
  setActiveTab?: any;
  product_id: string;
}

function TasteWine(props: Props) {
  const {
    journalWines,
    action: { actionName, isLoading },
    filters,
  } = useAppSelector((state: GlobalIState) => state.PersonalLedgerReducer);

  const dispatch = useAppDispatch();
  const isMobile = useCheckMobileScreen();
  const { setActiveTab, product_id } = props;
  const { t: translations } = useTranslation();
  const [selectedValues, setSelectedValues] = useState({
    body: "",
    aroma: "",
    flavor: "",
    acidity: "",
    sweetness: "",
  });
  const isDisabled = Object?.keys(selectedValues)?.length === 0;
  const item: any = journalWines?.filter(
    (item) => item.product?.id === Number(product_id)
  );
  const journalSearchCookie = () =>
    StorageService.getDataByKey("journalSearch");

  const tasteOptions = [
    {
      id: 1,
      label: translations("label.taste.body"),
      description: translations("label.body.description"),
      firstLabel: translations("label.tasting_body1"),
      secondLabel: translations("label.tasting_body2"),
      thirdLabel: translations("label.tasting_body3"),
      fourthLabel: translations("label.tasting_body4"),
      fifthLabel: translations("label.tasting_body5"),
    },
    {
      id: 2,
      label: translations("label.taste.aroma"),
      description: translations("label.aroma.description"),
      firstLabel: translations("label.tasting_aroma1"),
      secondLabel: translations("label.tasting_aroma2"),
      thirdLabel: translations("label.tasting_aroma3"),
      fourthLabel: translations("label.tasting_aroma4"),
      fifthLabel: translations("label.tasting_aroma5"),
    },
    {
      id: 3,
      label: translations("label.taste.flavor"),
      description: translations("label.flavor.description"),
      firstLabel: translations("label.tasting_flavor1"),
      secondLabel: translations("label.tasting_flavor2"),
      thirdLabel: translations("label.tasting_flavor3"),
      fourthLabel: translations("label.tasting_flavor4"),
      fifthLabel: translations("label.tasting_flavor5"),
    },
    {
      id: 4,
      label: translations("label.taste.acidity"),
      description: translations("label.acidity.description"),
      firstLabel: translations("label.tasting_acidity1"),
      secondLabel: translations("label.tasting_acidity2"),
      thirdLabel: translations("label.tasting_acidity3"),
      fourthLabel: translations("label.tasting_acidity4"),
      fifthLabel: translations("label.tasting_acidity5"),
    },
    {
      id: 5,
      label: translations("label.taste.sweetness"),
      description: translations("label.sweetness.description"),
      firstLabel: translations("label.tasting_sweetness1"),
      secondLabel: translations("label.tasting_sweetness2"),
      thirdLabel: translations("label.tasting_sweetness3"),
      fourthLabel: translations("label.tasting_sweetness4"),
      fifthLabel: translations("label.tasting_sweetness5"),
    },
  ];

  const getItemName = (item) => {
    switch (item) {
      case 1:
        return "body";
      case 2:
        return "aroma";
      case 3:
        return "flavor";
      case 4:
        return "acidity";
      case 5:
        return "sweetness";
      default:
        return "body";
    }
  };

  const hasSpecificValue = (id, order) => {
    switch (id) {
      case 1:
        return selectedValues.body == order;
      case 2:
        return selectedValues.aroma == order;
      case 3:
        return selectedValues.flavor == order;
      case 4:
        return selectedValues.acidity == order;
      case 5:
        return selectedValues.sweetness == order;
    }
  };

  const handleChange = (name, value) => {
    setSelectedValues((prevState) => ({
      ...prevState,
      [getItemName(name)]: value,
    }));
  };

  const handleSubmit = () => {
    const checkIfEmptyValues = Object.values(selectedValues).some(
      (value) => value === ""
    );

    let data: ITastings[] = Object.keys(selectedValues).map((key) => ({
      type: key,
      rating: Number(selectedValues[key]),
    }));

    if (checkIfEmptyValues) {
      dispatch(
        openToaster("ERROR", translations("error.tasting_mandatory_fields"))
      );
      return;
    }

    if (item && item[0]?.product?.inputted_data) {
      dispatch(
        setAddedWineTasteBegin({
          product_id: Number(product_id),
          tastings: data,
        })
      );
    } else {
      dispatch(
        setProductTasteBegin({
          product_id: Number(product_id),
          tastings: data,
          isBlind: false,
        })
      );
    }
  };

  useEffect(() => {
    if (
      [SET_PRODUCT_TASTE_SUCCESS, SET_ADDED_WINE_TASTE_SUCCESS].includes(
        actionName
      )
    ) {
      setActiveTab("rating");
      dispatch(
        getJournalDataBegin({
          search: journalSearchCookie() ? journalSearchCookie() : "",
          filters:
            filters?.filter((item) => item?.value?.length >= 1)?.length === 0
              ? []
              : modifyHomeFiltersForDate(
                  filters?.filter((item) => item?.value?.length >= 1)
                )?.filters,
        })
      );
      dispatch(scrollToTop());
    }
  }, [actionName]);

  useEffect(() => {
    if (item?.length !== 0) {
      const selectedValuesObj = item[0]?.product?.clientTastings?.reduce(
        (acc, item) => {
          acc[item.type] = item.rating.toString();
          return acc;
        },
        {}
      );

      setSelectedValues(selectedValuesObj);
    }
  }, [product_id]);

  return (
    <div className={styles.wrapper}>
      <LoaderWrapper isLoading={isLoading}>
        {tasteOptions.map((item) => (
          <div key={item.id} className={styles.item}>
            <div className={styles.header}>
              <h4>{item.label}</h4>
              <span>{item.description}</span>
            </div>
            <div className={styles.colorOptions}>
              <div className={styles.options}>
                {getTastingColorOptions().map((col, idx) => (
                  <div
                    key={idx}
                    className={styles.color}
                    style={{
                      width: hasSpecificValue(item.id, col.order)
                        ? isMobile
                          ? 65
                          : 75
                        : isMobile
                        ? 60
                        : 65,
                      height: hasSpecificValue(item.id, col.order) ? 46 : 40,
                      backgroundColor: col.color,
                    }}
                    onClick={() => handleChange(item.id, col.order)}
                  >
                    <span>
                      {idx === 0
                        ? item.firstLabel
                        : idx === 1
                        ? item.secondLabel
                        : idx === 2
                        ? item.thirdLabel
                        : idx === 3
                        ? item.fourthLabel
                        : item.fifthLabel}
                    </span>
                    {hasSpecificValue(item.id, col.order) ? (
                      <div className={styles.arrowIcon}>
                        <DownArrow />
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
        <div className={styles.submitBtn}>
          <ButtonComponent
            label={translations("button.submit")}
            class={"btn btn-primary"}
            isLoading={isLoading}
            disabled={isDisabled}
            handleClick={() => handleSubmit()}
          />
        </div>
      </LoaderWrapper>
    </div>
  );
}

export default TasteWine;
