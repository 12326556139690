import {
  ILikeOrDislike,
  IVoteUnsavedChat,
} from "types/reducers/homefeed.reducer.type";
import {
  GET_STREAM_FEED_BEGIN,
  GET_STREAM_FEED_FAILED,
  GET_STREAM_FEED_SUCCESS,
  CALL_AI_ASISSTANT_BEGIN,
  CALL_AI_ASISSTANT_FAILED,
  CALL_AI_ASISSTANT_SUCCESS,
  SUBMIT_STREAM_POLL_BEGIN,
  SUBMIT_STREAM_POLL_SUCCESS,
  CLEAR_DINO_AI_RESPONSE,
  SAVE_ASISSTANT_CHAT_TO_STREAM_BEGIN,
  SAVE_ASISSTANT_CHAT_TO_STREAM_SUCCESS,
  SAVE_ASISSTANT_CHAT_TO_STREAM_FAILED,
  SAVE_MOMENTS_PROFILE_BEGIN,
  SAVE_MOMENTS_PROFILE_SUCCESS,
  SAVE_MOMENTS_PROFILE_FAILED,
  SAVE_GENERAL_TASTE_BEGIN,
  SAVE_GENERAL_TASTE_SUCCESS,
  SAVE_GENERAL_TASTE_FAILED,
  CLEAR_ACTION,
  GET_TASTE_PREFERENCES_BEGIN,
  GET_TASTE_PREFERENCES_SUCCESS,
  GET_TASTE_PREFERENCES_FAILED,
  GET_CARD_CATEGORIES_BEGIN,
  GET_CARD_CATEGORIES_SUCCESS,
  GET_CARD_CATEGORIES_FAILED,
  SET_HOME_FILTERS,
  POPULATE_HOME_FILTERS,
  CLEAR_HOME_FILTERS,
  LIKE_OR_DISLIKE_CHAT_BEGIN,
  LIKE_OR_DISLIKE_CHAT_SUCCESS,
  SUBMIT_STREAM_POLL_FAILED,
  LIKE_OR_DISLIKE_CHAT_FAILED,
  GET_CARD_BY_ID_BEGIN,
  GET_CARD_BY_ID_SUCCESS,
  GET_CARD_BY_ID_FAILED,
  VOTE_UNSAVED_CHAT_BEGIN,
  VOTE_UNSAVED_CHAT_SUCCESS,
  VOTE_UNSAVED_CHAT_FAILED,
  SUBMIT_CLIENT_PROS_AND_CONS_BEGIN,
  SUBMIT_CLIENT_PROS_AND_CONS_SUCCCESS,
  SUBMIT_CLIENT_PROS_AND_CONS_FAILED,
} from "./constants";

export const callAIQuestionBegin = (question: string) => ({
  type: CALL_AI_ASISSTANT_BEGIN,
  payload: question,
});

export const callAIQuestionSuccess = (data) => ({
  type: CALL_AI_ASISSTANT_SUCCESS,
  payload: data,
});

export const callAIQuestionFailed = (error) => ({
  type: CALL_AI_ASISSTANT_FAILED,
  payload: error,
});

export const getStreamFeedBegin = (data: {
  isLoggedIn: boolean;
  lang: string;
  search: string;
  filters: any;
}) => ({
  type: GET_STREAM_FEED_BEGIN,
  payload: data,
});

export const getStreamFeedSuccess = (data) => ({
  type: GET_STREAM_FEED_SUCCESS,
  payload: data,
});

export const getStreamFeedFailed = (error) => ({
  type: GET_STREAM_FEED_FAILED,
  payload: error,
});

export const submitStreamPollBegin = (data: {
  card_id: number;
  answer: string;
}) => ({
  type: SUBMIT_STREAM_POLL_BEGIN,
  payload: data,
});

export const submitStreamPollSuccess = (data) => ({
  type: SUBMIT_STREAM_POLL_SUCCESS,
  payload: data,
});

export const submitStreamPollFailed = (error) => ({
  type: SUBMIT_STREAM_POLL_FAILED,
  payload: error,
});

export const clearDinoAIResponse = () => ({
  type: CLEAR_DINO_AI_RESPONSE,
});

export const saveAsisstantChatToStreamBegin = (data: {
  question: string;
  answer: string;
}) => ({
  type: SAVE_ASISSTANT_CHAT_TO_STREAM_BEGIN,
  payload: data,
});

export const saveAsisstantChatToStreamSuccess = (data) => ({
  type: SAVE_ASISSTANT_CHAT_TO_STREAM_SUCCESS,
  payload: data,
});

export const saveAsisstantChatToStreamFailed = (error) => ({
  type: SAVE_ASISSTANT_CHAT_TO_STREAM_FAILED,
  payload: error,
});

export const saveMomentsProfileBegin = (
  data: {
    question: string;
    answer: string;
  }[]
) => ({
  type: SAVE_MOMENTS_PROFILE_BEGIN,
  payload: data,
});

export const saveMomentsProfileSuccess = (data) => ({
  type: SAVE_MOMENTS_PROFILE_SUCCESS,
  payload: data,
});

export const saveMomentsProfileFailed = (error) => ({
  type: SAVE_MOMENTS_PROFILE_FAILED,
  payload: error,
});

export const saveGeneralTasteBegin = (
  data: {
    question: string;
    answer: string;
  }[]
) => ({
  type: SAVE_GENERAL_TASTE_BEGIN,
  payload: data,
});

export const saveGeneralTasteSuccess = (data) => ({
  type: SAVE_GENERAL_TASTE_SUCCESS,
  payload: data,
});

export const saveGeneralTasteFailed = (error) => ({
  type: SAVE_GENERAL_TASTE_FAILED,
  payload: error,
});

export const clearAction = () => ({
  type: CLEAR_ACTION,
});

export const getTastePreferencesBegin = () => ({
  type: GET_TASTE_PREFERENCES_BEGIN,
});

export const getTastePreferencesSuccess = (data) => ({
  type: GET_TASTE_PREFERENCES_SUCCESS,
  payload: data,
});

export const getTastePreferencesFailed = (error) => ({
  type: GET_TASTE_PREFERENCES_FAILED,
  payload: error,
});

export const getCardCategoriesBegin = () => ({
  type: GET_CARD_CATEGORIES_BEGIN,
});

export const getCardCategoriesSuccess = (data) => ({
  type: GET_CARD_CATEGORIES_SUCCESS,
  payload: data,
});

export const getCardCategoriesFailed = (error) => ({
  type: GET_CARD_CATEGORIES_FAILED,
  payload: error,
});

export const setHomeFilters = (data: { filter: string; value?: string[] }) => ({
  type: SET_HOME_FILTERS,
  payload: data,
});

export const populateHomeFilters = (data) => ({
  type: POPULATE_HOME_FILTERS,
  payload: data,
});

export const clearHomeFilters = () => ({
  type: CLEAR_HOME_FILTERS,
});

export const likeOrDislikeChatBegin = (data: ILikeOrDislike) => ({
  type: LIKE_OR_DISLIKE_CHAT_BEGIN,
  payload: data,
});

export const likeOrDislikeChatSuccess = (data) => ({
  type: LIKE_OR_DISLIKE_CHAT_SUCCESS,
  payload: data,
});

export const likeOrDislikeChatFailed = (error) => ({
  type: LIKE_OR_DISLIKE_CHAT_FAILED,
  payload: error,
});

export const voteUnsavedChatBegin = (data: IVoteUnsavedChat) => ({
  type: VOTE_UNSAVED_CHAT_BEGIN,
  payload: data,
});

export const voteUnsavedChatSuccess = (data) => ({
  type: VOTE_UNSAVED_CHAT_SUCCESS,
  payload: data,
});

export const voteUnsavedChatFailed = (error) => ({
  type: VOTE_UNSAVED_CHAT_FAILED,
  payload: error,
});

export const getCardByIdBegin = (id: number) => ({
  type: GET_CARD_BY_ID_BEGIN,
  payload: id,
});

export const getCardByIdSuccess = (data) => ({
  type: GET_CARD_BY_ID_SUCCESS,
  payload: data,
});

export const getCardByIdFailed = (error) => ({
  type: GET_CARD_BY_ID_FAILED,
  payload: error,
});

export const submitClientProsAndConsBegin = (data: {
  yes: string[];
  no: string[];
}) => ({
  type: SUBMIT_CLIENT_PROS_AND_CONS_BEGIN,
  payload: data,
});

export const submitClientProsAndConsSuccess = (data) => ({
  type: SUBMIT_CLIENT_PROS_AND_CONS_SUCCCESS,
  payload: data,
});

export const submitClientProsAndConsFailed = (error) => ({
  type: SUBMIT_CLIENT_PROS_AND_CONS_FAILED,
  payload: error,
});
