import { useTranslation } from "react-i18next";
import styles from "./AudioGame.module.scss";
import {
  AudioIcon,
  InfoIcon,
  InfoModalCloseIcon,
  RoundedInfoArrowUp,
} from "assets/icons/icons";
import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import { getDefaultColour } from "utils/utils";

import CoinImage from "assets/images/Coin.png";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  checkBlankAnswerBegin,
  fillInTheBlankSubmitBegin,
  getTodaysQuestionBegin,
} from "../actions";
import StorageService from "services/storage.service";
import {
  CHECK_BLANK_ANSWER_SUCCESS,
  SUBMIT_FILL_THE_BLANK_SUCCESS,
} from "../constants";
import { getClientBegin, getUserReferralsBegin } from "pages/Profile/actions";

import Lottie from "react-lottie";
import * as AudioGameAnimation from "assets/animations/audio-question.json";
import ButtonComponent from "components/Button/Button.component";
import { closeModal, openModal } from "App/actions";
import { modalTypes } from "types/reducers/modal.reducer.type";

export default function AudioGame() {
  const { defaultColour, client, referredClients } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const {
    action: { isLoading, actionName },
    todays_question,
    answer_correctness,
    todaysGame,
  } = useAppSelector((state: GlobalIState) => state.GamificationReducer);
  const { t: translations } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const audioRef = useRef(null);

  const [state, setState] = useState({ activeStep: "", answer: "" });
  const [searchParams, setSearchParams] = useSearchParams();
  const stepParam = searchParams.get("step");
  const submitParam = searchParams.get("submit");
  const answerCookie = StorageService.getDataByKey("answerCookie");
  const isLoggedIn = () => StorageService.getDataByKey("token");
  const [isPlaying, setIsPlaying] = useState(false);
  const showAlreadyPlayedGameCookie = StorageService.getDataByKey(
    "showAlreadyPlayedGame"
  );

  const getLanguage = () => {
    return localStorage.getItem("lang");
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: AudioGameAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleOpenAlreadyPlayedPopup = () => {
    dispatch(
      openModal({
        content: (
          <div className={styles.alreadyPlayedBox}>
            <div className={styles.mainContentPopup}>
              <div>
                <InfoIcon color="#fff" />
              </div>
              <div>
                <span>{translations("label.already_played_game")}</span>
              </div>
            </div>

            <div className={styles.bottomPart}>
              <div>
                <span>{translations("label.info")}</span>
              </div>
              <div
                className="pointer"
                onClick={() => {
                  StorageService.deleteCookie("showAlreadyPlayedGame");
                  dispatch(closeModal());
                }}
              >
                <RoundedInfoArrowUp />
              </div>
            </div>
          </div>
        ),
        type: modalTypes.smInfoOrange,
      })
    );
  };

  const handleStartGame = () => {
    navigate("/games/audio-questions?step=quiz");
  };

  const togglePlay = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleNext = () => {
    setState({ ...state, activeStep: "submit" });
  };

  const handleQuizSubmit = () => {
    if (answer_correctness || answer_correctness === false) {
      if (isLoggedIn())
        dispatch(fillInTheBlankSubmitBegin({ type: 5, answer: answerCookie }));
      else {
        StorageService.setKeyValue(
          "lastRouteCookie",
          "/games/audio-questions?submit=true"
        );
        navigate("/login", {
          state: {
            lastRoute: "/games/audio-questions?submit=true",
          },
        });
      }
    } else {
      dispatch(
        checkBlankAnswerBegin({ answer: state.answer, language: getLanguage() })
      );
      StorageService.setKeyValue("answerCookie", state.answer);
      return;
    }
  };

  useEffect(() => {
    dispatch(getTodaysQuestionBegin(getLanguage()));
    if (!client && isLoggedIn()) {
      dispatch(getClientBegin());
    }
  }, [state.activeStep]);

  useEffect(() => {
    if (stepParam === "quiz") {
      setState({ ...state, activeStep: "playQuiz" });
    }

    if (submitParam) {
      dispatch(fillInTheBlankSubmitBegin({ type: 5, answer: answerCookie }));
      searchParams.delete("submit");
      setSearchParams(searchParams);
      StorageService.deleteCookie("answerCookie");
    }
  }, [stepParam, submitParam]);

  useEffect(() => {
    if (actionName === SUBMIT_FILL_THE_BLANK_SUCCESS && !submitParam) {
      navigate("/games");
      window.location.reload();
    }

    if (actionName === CHECK_BLANK_ANSWER_SUCCESS) {
      setState({ ...state, activeStep: "next" });
    }
  }, [actionName]);

  useEffect(() => {
    if (isLoggedIn()) {
      dispatch(getUserReferralsBegin(true));
    }
  }, []);

  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault();

      if (client?.has_played) {
        navigate("/home");
      } else {
        window.history.back();
      }
    };

    window.history.pushState(null, null, window.location.href);

    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [navigate, client?.has_played]);

  useEffect(() => {
    if (showAlreadyPlayedGameCookie) {
      handleOpenAlreadyPlayedPopup();
    }
  }, [showAlreadyPlayedGameCookie]);

  useEffect(() => {
    if (todaysGame !== "Audio") {
      navigate("/games");
      window.location.reload();
    }
  }, [todaysGame]);

  return (
    <div
      className={styles.gamificationWrapper}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      {state.activeStep === "" ? (
        <div className={styles.content}>
          <div className={styles.animation}>
            <Lottie
              options={defaultOptions}
              // isStopped={this.state.isStopped}
              // isPaused={this.state.isPaused}
            />
          </div>
          <div className={styles.gameInfo}>
            <div>
              <h1>{translations("label.audio_questions")}</h1>
              <span>{translations("label.audio_questions_subtitle")}</span>
            </div>
            <ButtonComponent
              label={translations("label.start_game")}
              class={"btn btn-primary"}
              style={{ backgroundColor: "#FF6333" }}
              handleClick={() => handleStartGame()}
            />
          </div>
        </div>
      ) : ["playQuiz", "submit", "next"].includes(state.activeStep) ? (
        <div className={styles.playQuizGameBox}>
          <div className={styles.header}>
            <div
              onClick={() => {
                navigate("/games");
                setState({ ...state, activeStep: "" });
              }}
            >
              <InfoModalCloseIcon color="#fff" />
            </div>

            <div className={styles.questionBox}>
              <h2>{translations("label.daily_quiz")}</h2>

              <div className={styles.subtitle}>
                <span>{translations("label.audio_questions")}</span>
              </div>
              <span className={styles.hint}>
                {translations("label.audio_questions_subtitle")}
              </span>
            </div>

            {["submit"].includes(state.activeStep) ? (
              <div className={styles.answeredCoinBox}>
                <img src={CoinImage} alt={"Coin"} />
              </div>
            ) : (
              <div className={styles.audioContent}>
                <div className={styles.playAudio} onClick={() => togglePlay()}>
                  <AudioIcon />
                  {!isPlaying ? (
                    <span>{translations("label.click_to_play_question")}</span>
                  ) : null}
                  <audio
                    ref={audioRef}
                    src={todays_question?.audio_url}
                    loop={false}
                  />
                </div>

                <div className={styles.answerValue}>
                  <input
                    className={styles.blankValue}
                    placeholder={translations(
                      "label.enter_your_answer.one_word_only"
                    )}
                    value={state.answer}
                    style={
                      state.activeStep === "next"
                        ? {
                            backgroundColor: answer_correctness
                              ? "#B5FF6C"
                              : "#FF4141",
                            color: answer_correctness ? "#000" : "#fff",
                          }
                        : {}
                    }
                    disabled={state.activeStep === "next"}
                    onChange={(e) =>
                      setState({ ...state, answer: e.target.value })
                    }
                  />
                </div>
              </div>
            )}

            {state.activeStep === "next" ? (
              <div
                style={{ color: answer_correctness ? "#B5FF6C" : "#FF4141" }}
                className={styles.correctnessLabel}
              >
                <span>
                  {translations(
                    answer_correctness
                      ? "label.correct_answer"
                      : "label.incorrect_answer"
                  )}
                </span>
              </div>
            ) : null}

            {["submit"].includes(state.activeStep) ? (
              <>
                {!isLoggedIn() ? (
                  <div className={styles.notLoggedInUser}>
                    <span>
                      {translations("label.login_to_retrieve_medals")}
                    </span>
                  </div>
                ) : null}
                <div className={styles.earnedMedals}>
                  <span>
                    {answer_correctness ? 15 : 5} {translations("label.medals")}
                  </span>
                </div>
              </>
            ) : null}

            <div
              className={styles.submitAnswerButton}
              style={{
                backgroundColor: state.answer !== "" ? "#fff" : "#600f83",
                pointerEvents: state.answer !== "" ? "all" : "none",
                cursor: state.answer !== "" ? "pointer" : "default",
                color: state.answer !== "" ? "#000" : "#fff",
                opacity: isLoading ? 0.7 : 1,
              }}
              onClick={() =>
                state.activeStep === "next" ? handleNext() : handleQuizSubmit()
              }
            >
              <span>
                {translations(
                  state.activeStep === "next"
                    ? "button.next"
                    : state.activeStep === "submit"
                    ? isLoggedIn()
                      ? "label.get_medals"
                      : "button.log_in"
                    : "button.submit"
                )}
              </span>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
