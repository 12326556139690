import styles from "./MobileMyOrders.module.scss";
import { useAppDispatch, useAppSelector } from "hooks";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GlobalIState } from "types/RootState";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  DeleteIcon,
  ExpandIcon,
  FifthRateWineEmoji,
  FirstRateWineEmoji,
  FourthRateWineEmoji,
  GoldCoinIcon,
  SecondRateWineEmoji,
  ThirdRateWineEmoji,
  ValueForMoneyIlustration,
} from "assets/icons/icons";
import {
  formatPrice,
  getClientTastingLabel,
  modifyHomeFiltersForDate,
} from "utils/utils";
import { useEffect, useState } from "react";
import {
  fetchProductDetailsBegin,
  setCartItems,
} from "pages/Purchasing/actions";
import { openToaster } from "App/actions";
import { FETCH_PRODUCT_DETAILS_SUCCESS } from "pages/Purchasing/constants";
import {
  deleteAddedWineBegin,
  getJournalDataBegin,
  requestToSupplyWineBegin,
} from "pages/PersonalLedger/actions";
import WineBluredImage from "assets/images/wine.png";
import LoaderWrapper from "components/Loader/LoaderWrapper/LoaderWrapper";
import NoVinfinityWines from "../NoTastePreferencesItems/NoTastePreferencesItems.page";
import StorageService from "services/storage.service";
import EvaluateProduct from "../EvaluateProduct/EvaluateProduct.page";

function MobileMyOrders() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const {
    action: { isLoading },
    journalWines,
    filters,
  } = useAppSelector((state: GlobalIState) => state.PersonalLedgerReducer);
  const {
    action: { actionName },
    productDetails,
  } = useAppSelector((state: GlobalIState) => state.PurchasingReducer);
  const [shouldAddToCart, setShouldAddToCart] = useState(false);
  const [data, setData] = useState([]);
  const [evaluateWineId, setEvaluateWineId] = useState<number>(null);
  const journalSearchCookie = () =>
    StorageService.getDataByKey("journalSearch");

  const getSelectedRating = (item) => {
    if (item?.product?.ratings?.length !== 0) {
      switch (item?.product?.ratings[0].rating) {
        case 1:
          return <FirstRateWineEmoji />;
          break;
        case 2:
          return <SecondRateWineEmoji />;
          break;
        case 3:
          return <ThirdRateWineEmoji />;
          break;
        case 4:
          return <FourthRateWineEmoji />;
          break;
        case 5:
          return <FifthRateWineEmoji />;
          break;
        default:
          break;
      }
    }
  };

  const handleProposeToSupply = (id) => {
    dispatch(requestToSupplyWineBegin(id));
  };

  const getLanguage = () => {
    return localStorage.getItem("lang");
  };

  const handleCardExpand = (item, card) => {
    switch (card) {
      case "product-details":
        navigate(`/journal/product-details?id=${item?.product?.id}`);
        break;
      case "rate/taste":
        navigate(`/journal/rate-and-taste?id=${item?.product?.id}`);
        break;
      case "pqty":
        navigate(`/journal/ledger?id=${item?.product?.id}`);
        break;
      default:
        break;
    }
  };

  const handleAddToCart = (id: number) => {
    setShouldAddToCart(true);
    dispatch(fetchProductDetailsBegin(id, getLanguage()));
  };

  const handleDeleteAddedWine = (id: number) => {
    dispatch(deleteAddedWineBegin(id));

    dispatch(
      getJournalDataBegin({
        search: journalSearchCookie() ? journalSearchCookie() : "",
        filters:
          filters?.filter((item) => item?.value?.length >= 1)?.length === 0
            ? []
            : modifyHomeFiltersForDate(
                filters?.filter((item) => item?.value?.length >= 1)
              )?.filters,
      })
    );
  };

  const getAngle = (selected: number) => {
    switch (selected) {
      case 1:
        return -80;
        break;
      case 2:
        return -40;
        break;
      case 3:
        return 0;
        break;
      case 4:
        return 40;
        break;
      case 5:
        return 80;
        break;
      default:
        return 0;
        break;
    }
  };

  useEffect(() => {
    if (shouldAddToCart && actionName === FETCH_PRODUCT_DETAILS_SUCCESS) {
      dispatch(setCartItems(productDetails));
      dispatch(
        openToaster("SUCCESS", translations("label.item_added_to_cart"))
      );
      setShouldAddToCart(false);
    }
  }, [shouldAddToCart, actionName]);

  useEffect(() => {
    if (journalWines?.length !== 0) {
      setData([...(journalWines ?? [])]);
    } else if (!journalWines) {
      setData(null);
    }

    if (!journalSearchCookie()) {
      StorageService.setKeyValue("journalSearch", "");
    }
  }, [journalWines, journalSearchCookie()]);

  return (
    <>
      <LoaderWrapper isLoading={isLoading}>
        {data?.length === 0 ? (
          filters?.filter((item) => item?.value?.length >= 1)?.length !== 0 ||
          journalSearchCookie()?.length !== 0 ? (
            <div className={styles.emptyStream}>
              <span>{translations("label.empty_feed")}</span>
            </div>
          ) : (
            <NoVinfinityWines />
          )
        ) : (
          <div className={styles.container}>
            {data?.map((item, index) => {
              const tastingLabels = getClientTastingLabel(
                item?.product?.clientTastings
              )?.map((label) => translations(label));

              const showRateAndTasteBtn =
                item?.product?.ratings?.length === 0 &&
                item?.product?.clientTastings?.length === 0;

              const showEvaluateWine = item?.product?.id === evaluateWineId;

              return showEvaluateWine ? (
                <EvaluateProduct
                  id={evaluateWineId}
                  isAddedWine={item?.product?.inputted_data ? true : false}
                  value={
                    item?.product?.ratings &&
                    item?.product?.ratings?.length !== 0 &&
                    item?.product?.ratings[0]?.value_for_money
                      ? item?.product?.ratings[0]?.value_for_money
                      : null
                  }
                  setEvaluateWineId={setEvaluateWineId}
                />
              ) : (
                <div key={index} className={styles.itemContainer}>
                  <div className={styles.header}>
                    <div>
                      <span>
                        {translations(
                          item?.product?.inputted_data
                            ? "label.added_manually"
                            : "label.added_by_vinfinity"
                        )}
                      </span>
                    </div>
                    <div
                      onClick={() => handleCardExpand(item, "product-details")}
                    >
                      <ExpandIcon />
                      <span>{translations("label.details")}</span>
                    </div>
                  </div>
                  <div
                    className={styles.item}
                    style={{
                      backgroundColor: item?.product?.inputted_data
                        ? "#97979725"
                        : "#fff",
                    }}
                  >
                    <div className={styles.leftInfo}>
                      <div className={styles.image}>
                        <img
                          src={
                            item?.product?.product_image
                              ? item?.product?.product_image
                              : WineBluredImage
                          }
                          style={
                            !item?.product?.product_image
                              ? { opacity: 0.7 }
                              : {}
                          }
                        />
                      </div>
                      <div className={styles.info}>
                        <div>
                          <div>
                            <span className={styles.small}>
                              {item?.product?.inputted_data
                                ? ""
                                : item?.product?.region}
                            </span>
                          </div>
                          <div>
                            <span className={styles.small}>
                              {item?.product?.inputted_data
                                ? item?.product?.region
                                : item?.product?.vintage}{" "}
                              | {item?.product?.bottle_size}
                            </span>
                          </div>
                        </div>
                        <div>
                          <div className={styles.small}></div>
                          <div className={styles.colored}>
                            {item?.product?.ratings &&
                            item?.product?.ratings?.length !== 0 &&
                            item?.product?.ratings[0]?.value_for_money !== 0 ? (
                              <div
                                className={styles.vfmIlustration}
                                onClick={() =>
                                  setEvaluateWineId(item?.product?.id)
                                }
                              >
                                <ValueForMoneyIlustration
                                  angle={getAngle(
                                    item?.product?.ratings[0]?.value_for_money
                                  )}
                                />
                              </div>
                            ) : (
                              <div
                                className={styles.addPriceValueBtn}
                                onClick={() =>
                                  setEvaluateWineId(item?.product?.id)
                                }
                              >
                                <span>{translations("label.price_value")}</span>
                              </div>
                            )}
                            {item?.old_price ? (
                              <>
                                <span className={styles.discount}>
                                  CHF
                                  {formatPrice(
                                    item?.product?.old_price?.toString()
                                  )}
                                </span>
                                <br />
                              </>
                            ) : null}
                            {item?.product?.inputted_data ? (
                              <div>
                                <span>{item.product?.type}</span>
                              </div>
                            ) : (
                              <span>
                                CHF{" "}
                                {formatPrice(item?.product?.price?.toString())}
                              </span>
                            )}
                          </div>
                          <div className={styles.title}>
                            <span>
                              {item?.product?.wine_name?.length > 20
                                ? item?.product?.wine_name?.substring(0, 20) +
                                  "..."
                                : item?.product?.wine_name}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.bordered}></div>
                    <div className={styles.rightInfo}>
                      {showRateAndTasteBtn ? (
                        <div className={styles.rateAndTasteShownContent}>
                          <div
                            className={styles.button}
                            onClick={() => handleCardExpand(item, "rate/taste")}
                          >
                            <span>{translations("label.rate_and_taste")}</span>
                          </div>

                          {/* <div className={styles.medalsInfo}>
                            <GoldCoinIcon />
                            <span>{translations("label.get_25_medals")}</span>
                          </div> */}
                        </div>
                      ) : (
                        <div className={styles.productFeedback}>
                          <div className={styles.headerLabel}>
                            <span>{translations("label.my_tasting")}</span>
                            <div className={styles.tastingLabel}>
                              {tastingLabels?.length > 0 ? (
                                <span>{tastingLabels?.join(", ")}</span>
                              ) : (
                                <span className={styles.emptyRateOrTaste}>
                                  {translations(
                                    "label.please_give_us_your_tasting"
                                  )}
                                </span>
                              )}
                            </div>
                          </div>

                          <div className={styles.ratings}>
                            <div className={styles.headerLabel}>
                              <span>{translations("label.my_rating")}</span>
                            </div>
                            {getSelectedRating(item) ? (
                              <div className={styles.rateEmojis}>
                                <div
                                  className={
                                    item?.product?.ratings[0]?.rating === 1
                                      ? styles.activeIcon
                                      : styles.disabled
                                  }
                                >
                                  <FirstRateWineEmoji />
                                </div>
                                <div
                                  className={
                                    item?.product?.ratings[0]?.rating === 2
                                      ? styles.activeIcon
                                      : styles.disabled
                                  }
                                >
                                  <SecondRateWineEmoji />
                                </div>
                                <div
                                  className={
                                    item?.product?.ratings[0]?.rating === 3
                                      ? styles.activeIcon
                                      : styles.disabled
                                  }
                                >
                                  <ThirdRateWineEmoji />
                                </div>
                                <div
                                  className={
                                    item?.product?.ratings[0]?.rating === 4
                                      ? styles.activeIcon
                                      : styles.disabled
                                  }
                                >
                                  <FourthRateWineEmoji />
                                </div>
                                <div
                                  className={
                                    item?.product?.ratings[0]?.rating === 5
                                      ? styles.activeIcon
                                      : styles.disabled
                                  }
                                >
                                  <FifthRateWineEmoji />
                                </div>
                              </div>
                            ) : (
                              <div>
                                <span className={styles.emptyRateOrTaste}>
                                  {translations(
                                    "label.please_give_us_your_rating"
                                  )}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </LoaderWrapper>
    </>
  );
}

export default MobileMyOrders;
