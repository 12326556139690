import { produce } from "immer";
import { GamificationReducerReducerInterface } from "types/reducers/gamification.reducer.type";
import { HomeFeedReducerInterface } from "types/reducers/homefeed.reducer.type";
import {
  GET_STREAM_FEED_BEGIN,
  GET_STREAM_FEED_FAILED,
  GET_STREAM_FEED_SUCCESS,
  CALL_AI_ASISSTANT_BEGIN,
  CALL_AI_ASISSTANT_FAILED,
  CALL_AI_ASISSTANT_SUCCESS,
  SUBMIT_STREAM_POLL_BEGIN,
  SUBMIT_STREAM_POLL_FAILED,
  SUBMIT_STREAM_POLL_SUCCESS,
  CLEAR_DINO_AI_RESPONSE,
  SAVE_ASISSTANT_CHAT_TO_STREAM_BEGIN,
  SAVE_ASISSTANT_CHAT_TO_STREAM_FAILED,
  SAVE_ASISSTANT_CHAT_TO_STREAM_SUCCESS,
  SAVE_MOMENTS_PROFILE_BEGIN,
  SAVE_MOMENTS_PROFILE_FAILED,
  SAVE_MOMENTS_PROFILE_SUCCESS,
  SAVE_GENERAL_TASTE_BEGIN,
  SAVE_GENERAL_TASTE_FAILED,
  SAVE_GENERAL_TASTE_SUCCESS,
  CLEAR_ACTION,
  GET_TASTE_PREFERENCES_BEGIN,
  GET_TASTE_PREFERENCES_FAILED,
  GET_TASTE_PREFERENCES_SUCCESS,
  GET_CARD_CATEGORIES_BEGIN,
  GET_CARD_CATEGORIES_FAILED,
  GET_CARD_CATEGORIES_SUCCESS,
  SET_HOME_FILTERS,
  POPULATE_HOME_FILTERS,
  CLEAR_HOME_FILTERS,
  LIKE_OR_DISLIKE_CHAT_BEGIN,
  LIKE_OR_DISLIKE_CHAT_FAILED,
  LIKE_OR_DISLIKE_CHAT_SUCCESS,
  GET_CARD_BY_ID_BEGIN,
  GET_CARD_BY_ID_SUCCESS,
  GET_CARD_BY_ID_FAILED,
  VOTE_UNSAVED_CHAT_BEGIN,
  VOTE_UNSAVED_CHAT_FAILED,
  VOTE_UNSAVED_CHAT_SUCCESS,
  SUBMIT_CLIENT_PROS_AND_CONS_BEGIN,
  SUBMIT_CLIENT_PROS_AND_CONS_SUCCCESS,
  SUBMIT_CLIENT_PROS_AND_CONS_FAILED,
} from "./constants";
import StorageService from "services/storage.service";

const initialState: HomeFeedReducerInterface = {
  action: {
    isLoading: false,
    error: null,
    message: "",
    isSuccess: false,
    actionName: null,
  },
  question: "",
  asisstantAnswer: "",
  streamFeed: [],
  has_answered: false,
  tastePreferences: null,
  cardCategories: null,
  filters: [
    { filter: "Categories", value: [] },
    { filter: "scheduleTime", value: [] },
  ],
  card: null,
};

const HomeFeedReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case CALL_AI_ASISSTANT_BEGIN:
      case GET_STREAM_FEED_BEGIN:
      case SUBMIT_STREAM_POLL_BEGIN:
      case SAVE_ASISSTANT_CHAT_TO_STREAM_BEGIN:
      case SAVE_MOMENTS_PROFILE_BEGIN:
      case SAVE_GENERAL_TASTE_BEGIN:
      case GET_TASTE_PREFERENCES_BEGIN:
      case GET_CARD_CATEGORIES_BEGIN:
      case LIKE_OR_DISLIKE_CHAT_BEGIN:
      case VOTE_UNSAVED_CHAT_BEGIN:
      case GET_CARD_BY_ID_BEGIN:
      case SUBMIT_CLIENT_PROS_AND_CONS_BEGIN:
        draft.action.isLoading = true;
        draft.action.isSuccess = false;
        draft.action.error = null;
        draft.action.actionName = action.type;
        break;
      case CALL_AI_ASISSTANT_FAILED:
      case GET_STREAM_FEED_FAILED:
      case SUBMIT_STREAM_POLL_FAILED:
      case SAVE_ASISSTANT_CHAT_TO_STREAM_FAILED:
      case SAVE_MOMENTS_PROFILE_FAILED:
      case SAVE_GENERAL_TASTE_FAILED:
      case GET_TASTE_PREFERENCES_FAILED:
      case GET_CARD_CATEGORIES_FAILED:
      case LIKE_OR_DISLIKE_CHAT_FAILED:
      case VOTE_UNSAVED_CHAT_FAILED:
      case GET_CARD_BY_ID_FAILED:
      case SUBMIT_CLIENT_PROS_AND_CONS_FAILED:
        draft.action.isLoading = false;
        draft.action.error = action.payload ? action.payload : "Unknown error";
        draft.action.isSuccess = false;
        draft.action.actionName = action.type;
        break;
      case CALL_AI_ASISSTANT_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.asisstantAnswer = action.payload;
        break;
      case GET_STREAM_FEED_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.streamFeed = null;
        draft.streamFeed = action.payload?.cards;
        draft.has_answered = action.payload.has_answered;
        break;
      case SUBMIT_STREAM_POLL_SUCCESS:
      case SAVE_ASISSTANT_CHAT_TO_STREAM_SUCCESS:
      case SAVE_MOMENTS_PROFILE_SUCCESS:
      case SAVE_GENERAL_TASTE_SUCCESS:
      case LIKE_OR_DISLIKE_CHAT_SUCCESS:
      case VOTE_UNSAVED_CHAT_SUCCESS:
      case SUBMIT_CLIENT_PROS_AND_CONS_SUCCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        break;
      case CLEAR_DINO_AI_RESPONSE:
        draft.question = "";
        draft.asisstantAnswer = "";
        break;
      case CLEAR_ACTION:
        draft.action = initialState.action;
        break;
      case GET_TASTE_PREFERENCES_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.tastePreferences = action.payload;
        break;
      case GET_CARD_CATEGORIES_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.cardCategories = action.payload.map(
          (category) => category.category_name
        );
        break;
      case SET_HOME_FILTERS:
        const value = action.payload.value;
        const updatedFilters = [...draft.filters];

        const filterIndex = updatedFilters.findIndex(
          (filter) => filter.filter === action.payload.filter
        );

        if (filterIndex !== -1) {
          updatedFilters[filterIndex] = {
            ...updatedFilters[filterIndex],
            value,
          };
          draft.filters = updatedFilters;
          StorageService.setKeyValue("homeFilters", updatedFilters);
        }
        break;
      case POPULATE_HOME_FILTERS:
        const homeFiltersCookie = () =>
          StorageService.getDataByKey("homeFilters");
        draft.filters = homeFiltersCookie();
        break;
      case GET_CARD_BY_ID_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.card = action.payload;
        break;
      case CLEAR_HOME_FILTERS:
        draft.action.actionName = action.type;
        draft.filters = initialState.filters;
        break;
      default:
        break;
    }
  });

export default HomeFeedReducer;
