import { useTranslation } from "react-i18next";
import styles from "./LoginPassword.module.scss";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks";

import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import { GlobalIState } from "types/RootState";

import { useEffect, useState } from "react";
import {
  HidePasswordIcon,
  PreviousIcon,
  ShowPasswordIcon,
} from "assets/icons/icons";
import { getDefaultColour } from "utils/utils";
import ButtonComponent from "components/Button/Button.component";
import { logInBegin } from "pages/Authentication/actions";
import StorageService from "services/storage.service";
import { LOG_IN_SUCCESS } from "pages/Authentication/constants";
import { LOGIN_DEVICE } from "utils/enums";

export default function LoginPassword() {
  const {
    action: { actionName, isLoading, error },
  } = useAppSelector((state: GlobalIState) => state.authenticationReducer);
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t: translations } = useTranslation();
  let isMobile = useCheckMobileScreen();
  const [searchParams] = useSearchParams();
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const emailParam = searchParams.get("email");
  const eventIdParam = searchParams.get("eventId");
  let lastRoute = location.state?.lastRoute;
  const lastRouteCookie = StorageService.getDataByKey("lastRouteCookie");

  const getStoredLanguage = () => {
    return localStorage.getItem("lang");
  };

  const isLoggedIn = () => StorageService.getDataByKey("token");

  const handleSave = () => {
    const data = {
      user: emailParam.replaceAll(" ", "+"),
      password: password,
      country_phone: "",
      phone: "",
      google_auth_code: "",
      login_method: "email",
      two_factor_code: "",
      sms_code: null,
      last_login_device: LOGIN_DEVICE.WEB_APP,
    };

    dispatch(logInBegin(data));
  };

  useEffect(() => {
    if (actionName === LOG_IN_SUCCESS) {
      if (eventIdParam) {
        navigate(`/events?id=${eventIdParam}`);
      } else
        lastRoute || lastRouteCookie
          ? navigate(lastRouteCookie ? lastRouteCookie : lastRoute)
          : navigate("/");
    }
  }, [actionName]);

  useEffect(() => {
    if (error === "error.send_2fa_two_factor_code") {
      let data = {
        user: emailParam.replaceAll(" ", "+"),
        password: password,
        country_phone: "",
        phone: "",
        google_auth_code: "",
        login_method: "email",
      };
      StorageService.setKeyValue("loginTwoFactorData", data);
      navigate("/login/two-factor");
    }
  }, [error]);

  useEffect(() => {
    if (isLoggedIn()) {
      navigate("/");
    }
  }, [isLoggedIn()]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.previousIcon} onClick={() => navigate("/login")}>
          <PreviousIcon color={getDefaultColour(defaultColour)} />
        </div>
      </div>

      <div className={styles.content}>
        <h1>{translations("button.log_in")}</h1>

        <div className={styles.passwordContainer}>
          <h3>{translations("label.enter_your_password")}</h3>
          <div
            className={styles.showPasswordLabel}
            onClick={() => setShowPassword(!showPassword)}
          >
            <span>
              {translations(
                showPassword ? "label.hide_password" : "label.show_password"
              )}
            </span>
            <div>
              {showPassword ? (
                <ShowPasswordIcon color={"#000"} />
              ) : (
                <HidePasswordIcon color={"#000"} />
              )}
            </div>
          </div>

          <div className={styles.passwordInput}>
            <input
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className={styles.bottomPart}>
            <div
              className={styles.forgotPasscodeLabel}
              onClick={() => navigate("/forgot-password")}
            >
              <span>{translations("label.forgot_password?")}</span>
            </div>
          </div>
        </div>

        <div className={styles.saveButton}>
          <ButtonComponent
            label={translations("button.submit")}
            class={"btn btn-primary"}
            isLoading={isLoading}
            handleClick={() => handleSave()}
          />
        </div>
      </div>
    </div>
  );
}
