import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import styles from "./AddedWineProductDetails.module.scss";
import { formatPrice, getDefaultColour } from "utils/utils";
import { BackButtonIcon, CamIcon, EditPencilIcon } from "assets/icons/icons";
import LoaderWrapper from "components/Loader/LoaderWrapper/LoaderWrapper";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import { useRef, useState } from "react";
import { editAddedWineBegin } from "../actions";
import WineBluredImage from "assets/images/wine.png";

interface Props {
  setShowProductDetails?: any;
}

function AddedWineProductDetails(props: Props) {
  const { setShowProductDetails } = props;
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const {
    action: { isLoading },
    addedWineProductDetails,
  } = useAppSelector((state: GlobalIState) => state.PersonalLedgerReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();
  const isMobile = useCheckMobileScreen();
  const imageInputFileRef = useRef(null);
  const [imgFile, setImgFile] = useState("");
  const [imgPreview, setImgPreview] = useState(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    setImgFile(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImgPreview(reader.result);

      let data = {
        id: addedWineProductDetails?.id?.toString(),
        wine_name: addedWineProductDetails?.wine_name,
        producer: addedWineProductDetails?.producer,
        bottle_size: addedWineProductDetails?.bottle_size,
        type: addedWineProductDetails?.type,
        country: addedWineProductDetails?.country,
        region: addedWineProductDetails?.region,
        year:
          addedWineProductDetails?.year?.toString() === "0"
            ? 0
            : Number(addedWineProductDetails?.year),
        price:
          addedWineProductDetails?.price?.toString() === "0"
            ? ""
            : addedWineProductDetails?.price,
        description: addedWineProductDetails?.description,
        location: addedWineProductDetails.location,
        inventory: addedWineProductDetails?.inventory,
        custom_field: JSON.stringify(addedWineProductDetails?.custom_field),
        image: false,
        image_file: false,
        location_image: true,
        location_image_file: file,
      };

      dispatch(editAddedWineBegin(data));
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleUpdateImageClick = () => {
    imageInputFileRef?.current?.click();
  };

  return (
    <LoaderWrapper isLoading={isLoading}>
      <div
        className={styles.detailsWrapper}
        style={
          {
            "--color": getDefaultColour(defaultColour),
          } as React.CSSProperties
        }
      >
        <div className={styles.header}>
          <div
            className="pointer"
            onClick={() => setShowProductDetails({ id: null, show: null })}
          >
            <BackButtonIcon color={"#000"} />
          </div>
          <div className={styles.wineTypeLabel}>
            <span>
              {addedWineProductDetails?.type?.length > 0
                ? addedWineProductDetails?.type
                : translations("label.no_type_of_wine_given")}
            </span>
          </div>
          <div
            className={styles.editButton}
            onClick={() =>
              navigate(`/edit-added-wine?id=${addedWineProductDetails?.id}`)
            }
          >
            <EditPencilIcon color="#fff" />
          </div>
        </div>

        <div className={styles.mainInfo}>
          <div className={styles.leftPart}>
            <div className={styles.bottleLabels}>
              <span>
                {addedWineProductDetails?.bottle_size?.length > 0
                  ? addedWineProductDetails?.bottle_size
                  : translations("label.no_bottle_size_given")}
              </span>
              <span>
                {addedWineProductDetails?.country?.length > 0
                  ? addedWineProductDetails?.country
                  : translations("label.no_country_given")}
              </span>
              <span>
                {addedWineProductDetails?.region?.length > 0
                  ? addedWineProductDetails?.region
                  : translations("label.no_region_given")}
              </span>
            </div>
            <div className={styles.middleLabels}>
              <span>
                {addedWineProductDetails?.producer?.length > 0
                  ? addedWineProductDetails?.producer
                  : translations("label.no_producer_name_given")}
              </span>
              <span className={styles.bold}>
                {addedWineProductDetails?.wine_name?.length > 0
                  ? addedWineProductDetails?.wine_name
                  : translations("label.no_wine_name_given")}
              </span>
              <span className={styles.year}>
                {addedWineProductDetails?.year
                  ? addedWineProductDetails?.year
                  : translations("label.no_year_given")}
              </span>

              <span className={styles.price}>
                {addedWineProductDetails?.price > 0
                  ? `CHF ${formatPrice(
                      addedWineProductDetails?.price.toFixed(2).toString()
                    )}`
                  : translations("label.no_price_given")}
              </span>
            </div>
          </div>
          <div className={styles.rightPart}>
            <img
              src={
                addedWineProductDetails?.product_image
                  ? addedWineProductDetails?.product_image
                  : WineBluredImage
              }
              style={
                !addedWineProductDetails?.product_image ? { opacity: 0.5 } : {}
              }
              alt={addedWineProductDetails?.wine_name}
            />
          </div>
        </div>

        <div className={styles.mainInfo} style={{ minHeight: "200px" }}>
          <div className={styles.leftPart}>
            <div className={styles.informations}>
              <div>
                {" "}
                <span>
                  <b>{translations("label.description")}: </b>
                  {addedWineProductDetails?.description?.length > 0
                    ? addedWineProductDetails?.description
                    : translations("label.no_description_given")}
                </span>
              </div>
              <div>
                <span>
                  <b>{translations("label.location")}: </b>
                  {addedWineProductDetails?.location?.length > 0
                    ? addedWineProductDetails?.location
                    : translations("label.no_location_given")}
                </span>
              </div>
              <div>
                <span>
                  <b>{translations("label.inventory")}: </b>
                  {addedWineProductDetails?.inventory
                    ? addedWineProductDetails?.inventory
                    : translations("label.no_inventory_given")}
                </span>
              </div>

              {Object.keys(addedWineProductDetails?.custom_field ?? {})
                ?.length > 0
                ? Object.entries(
                    addedWineProductDetails.custom_field ?? {}
                  ).map(([key, value]) => (
                    <div key={key}>
                      <span>
                        <b>{key}: </b>
                        {String(value)}
                      </span>
                    </div>
                  ))
                : null}
            </div>
          </div>
          <div className={styles.rightPart}>
            <input
              type="file"
              id="locationImageUploadInput"
              ref={imageInputFileRef}
              style={{ display: "none" }}
              accept="image/*"
              onChange={handleImageChange}
            />
            <div
              style={
                addedWineProductDetails?.location_image?.length > 0
                  ? {
                      backgroundImage: `url(${
                        imgPreview
                          ? imgPreview
                          : addedWineProductDetails?.location_image
                      })`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                    }
                  : { backgroundColor: getDefaultColour(defaultColour) }
              }
              className={styles.locationImageCard}
              onClick={handleUpdateImageClick}
            >
              {!addedWineProductDetails?.location_image ? (
                <>
                  <span>{translations("label.image_of_location")}</span>
                  <CamIcon />
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </LoaderWrapper>
  );
}

export default AddedWineProductDetails;
