import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import styles from "./Profile.module.scss";
import {
  ArrowRightBlackIcon,
  BackButtonIcon,
  CameraIcon,
  GlobeIcon,
  LogoutIcon,
  PreviousIcon,
} from "assets/icons/icons";

import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  clearAction,
  clearClient,
  confirmAddEmailBegin,
  confirmChangeEmailBegin,
  confirmPasswordChangeBegin,
  generateTwoFactorAuthBegin,
  getClientBegin,
  getReferralCodeBegin,
  updateNotificationIdentBegin,
  updateProfilePictureBegin,
} from "./actions";

import { GlobalIState } from "types/RootState";
import CheckboxToggleComponent from "components/Checkbox/CheckboxToggle/CheckboxToggle.component";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import ChangeLoginCredentials from "./ChangeLoginCredentials/ChangeLoginCredentials.page";

import PersonalDetails from "./PersonalDetails/PersonalDetails.page";
import AddressDetails from "./AddressDetails/AddressDetails.page";
import TermsAndConditions from "./LegalInformationPages/TermsAndConditions/TermsAndConditions.page";
import PrivacyPolicy from "./LegalInformationPages/PrivacyPolicy/PrivacyPolicy.page";
import {
  openToaster,
  setLeftPartItem,
  setMiddlepartItem,
  setRightPartItem,
} from "App/actions";
import AccountClosure from "./AccountClosure/AccountClosure.page";
import Language from "./Settings/Language/Language.page";
import BackgroundAtmosphere from "./Settings/BackgroundAtmosphere/BackgroundAtmosphere.page";
import { getDefaultColour, getInitialsFromName } from "utils/utils";
import { logOut } from "pages/Authentication/actions";

import Resizer from "react-image-file-resizer";
import AddEmailSuccess from "./PersonalDetails/AddEmail/AddEmailSuccess/AddEmailSuccess.page";
import StorageService from "services/storage.service";
import TwoFactorAuth from "./Settings/TwoFactorAuth/TwoFactorAuth.page";
import { clearCartItems } from "pages/Purchasing/actions";
import ChangePasscode from "./ChangePasscode/ChangePasscode.page";
import CredentialsChangeSuccess from "./ChangePassword/CredentialsChangeSuccess/CredentialsChangeSuccess.page";
import ChangePassword from "./ChangePassword/ChangePassword.page";

export default function Profile() {
  const { leftPartShownItem, middlePartShownItem, rightPartShownItem } =
    useAppSelector((state: GlobalIState) => state.globalReducer);

  const {
    client,
    defaultColour,
    action: { isLoading },
  } = useAppSelector((state: GlobalIState) => state.ProfilePageReducer);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const inputFile = useRef(null);
  const [hasNotifications, setHasNotifications] = useState<boolean>(
    client?.notifications_enabled ?? false
  );
  const [hasTwoFactorAuth, setHasTwoFactorAuth] = useState<boolean>(
    client?.two_factor_auth_enabled ?? false
  );
  const isMobile = useCheckMobileScreen();
  const [image, setImage] = useState<any>(client?.profile_picture);
  const stepParam = searchParams.get("step");
  const tokenParam = searchParams.get("token");
  const emailParam = searchParams.get("email");
  const emailChangedSuccess = searchParams.get("email-changed");

  const profileOperations = {
    loginMethod: [
      {
        id: 1,
        label: "label.login_method",
        component: (
          <ArrowRightBlackIcon color={getDefaultColour(defaultColour)} />
        ),
        onClick: () => {
          if (isMobile) {
            dispatch(setLeftPartItem(<ChangeLoginCredentials />));
          } else {
            dispatch(setMiddlepartItem(<ChangeLoginCredentials />));
            dispatch(setRightPartItem(null));
          }
        },
      },
      ["phone", "gmail"].includes(client?.login_method)
        ? { id: 0, label: "", component: null }
        : {
            id: 2,
            label: client?.is_passcode ? "label.passcode" : "label.password",
            component: (
              <ArrowRightBlackIcon color={getDefaultColour(defaultColour)} />
            ),
            onClick: () => {
              if (client?.is_passcode) {
                if (isMobile) navigate("/change-passcode");
                else {
                  dispatch(setMiddlepartItem(<ChangePasscode />));
                }
              } else {
                if (isMobile) dispatch(setLeftPartItem(<ChangePassword />));
                else {
                  dispatch(setMiddlepartItem(<ChangePassword />));
                }
              }
            },
          },
      client?.login_method === "gmail"
        ? { id: 0, label: "", component: null }
        : {
            id: 3,
            label: "label.two_factor_authentication",
            component: (
              <ArrowRightBlackIcon color={getDefaultColour(defaultColour)} />
              //   <CheckboxToggleComponent
              //   color={getDefaultColour(defaultColour)}
              //   checked={hasTwoFactorAuth}
              //   onChange={(e) => handleTwoFactorAuthChange(e)}
              // />
            ),
            onClick: () => {
              if (isMobile) {
                dispatch(setLeftPartItem(<TwoFactorAuth />));
              } else {
                dispatch(setMiddlepartItem(<TwoFactorAuth />));
                dispatch(setRightPartItem(null));
              }
            },
          },
    ],
    personalInformations: [
      {
        id: 2,
        label: "label.personal_details",
        component: (
          <ArrowRightBlackIcon color={getDefaultColour(defaultColour)} />
        ),
        onClick: () => {
          if (isMobile) {
            dispatch(setLeftPartItem(<PersonalDetails />));
          } else {
            dispatch(setMiddlepartItem(<PersonalDetails />));
            dispatch(setRightPartItem(null));
          }
        },
      },
      {
        id: 3,
        label: "label.address_details",
        component: (
          <ArrowRightBlackIcon color={getDefaultColour(defaultColour)} />
        ),
        onClick: () => {
          if (isMobile) {
            dispatch(setLeftPartItem(<AddressDetails />));
          } else {
            dispatch(setMiddlepartItem(<AddressDetails />));
            dispatch(setRightPartItem(null));
          }
        },
      },
      // {
      //   id: 4,
      //   label: "label.kyc_identification",
      //   component: (
      //     <ArrowRightBlackIcon color={getDefaultColour(defaultColour)} />
      //   ),
      //   onClick: () => {
      //     // if (isMobile) {
      //     //   dispatch(setLeftPartItem("4/1"));
      //     // } else dispatch(setRightPartItem("4/1"));
      //   },
      // },
    ],
    legalInformation: [
      {
        id: 1,
        label: "label.terms_and_conditions",
        component: (
          <ArrowRightBlackIcon color={getDefaultColour(defaultColour)} />
        ),
        onClick: () => {
          if (isMobile) {
            dispatch(setLeftPartItem(<TermsAndConditions />));
          } else {
            dispatch(setMiddlepartItem(<TermsAndConditions />));
            dispatch(setRightPartItem(null));
          }
        },
      },
      {
        id: 2,
        label: "label.privacy_policy",
        component: (
          <ArrowRightBlackIcon color={getDefaultColour(defaultColour)} />
        ),
        onClick: () => {
          if (isMobile) {
            dispatch(setLeftPartItem(<PrivacyPolicy />));
          } else {
            dispatch(setMiddlepartItem(<PrivacyPolicy />));
            dispatch(setRightPartItem(null));
          }
        },
      },
      {
        id: 3,
        label: "label.account_closure",
        component: (
          <ArrowRightBlackIcon color={getDefaultColour(defaultColour)} />
        ),
        onClick: () => {
          if (isMobile) {
            dispatch(setLeftPartItem(<AccountClosure />));
          } else {
            dispatch(setMiddlepartItem(<AccountClosure />));
            dispatch(setRightPartItem(null));
          }
        },
      },
    ],
    settings: [
      {
        id: 1,
        label: "label.language",
        component: (
          <ArrowRightBlackIcon color={getDefaultColour(defaultColour)} />
        ),
        onClick: () => {
          if (isMobile) {
            dispatch(setLeftPartItem(<Language />));
          } else {
            dispatch(setMiddlepartItem(<Language />));
            dispatch(setRightPartItem(null));
          }
        },
      },
      {
        id: 2,
        label: "label.notifications",
        component: (
          <CheckboxToggleComponent
            color={getDefaultColour(defaultColour)}
            checked={hasNotifications}
            onChange={(e) => handleNotificationsChange(e)}
          />
        ),
        onClick: () => {},
      },
      {
        id: 4,
        label: "label.background_atmosphere",
        component: (
          <ArrowRightBlackIcon color={getDefaultColour(defaultColour)} />
        ),
        onClick: () => {
          if (isMobile) {
            dispatch(setLeftPartItem(<BackgroundAtmosphere />));
          } else {
            dispatch(setMiddlepartItem(<BackgroundAtmosphere />));
            dispatch(setRightPartItem(null));
          }
        },
      },
    ],
  };

  const handleLogout = () => {
    dispatch(logOut());
    dispatch(clearClient());
    dispatch(clearCartItems());
    dispatch(openToaster("SUCCESS", translations("label.logout_success")));
    window.location.reload();
    navigate("/home");
  };

  const handleNotificationsChange = (e) => {
    setHasNotifications(e.target.checked);
    dispatch(
      updateNotificationIdentBegin({
        notifications_enabled: e.target.checked,
        face_id_enabled: false,
        two_factor_auth_enabled: hasTwoFactorAuth,
      })
    );
  };

  const handleTwoFactorAuthChange = (e) => {
    setHasTwoFactorAuth(e.target.checked);
    dispatch(
      updateNotificationIdentBegin({
        notifications_enabled: hasNotifications,
        face_id_enabled: false,
        two_factor_auth_enabled: e.target.checked,
      })
    );
  };

  const onChangeProfilePhotoClick = () => {
    inputFile.current.click();
  };

  const handleImageChange = (event) => {
    const selectedImage = event.target.files[0];

    if (selectedImage) {
      try {
        Resizer.imageFileResizer(
          event.target.files[0],
          300,
          300,
          "JPEG",
          50,
          0,
          (uri) => {
            dispatch(updateProfilePictureBegin({ image: uri }));
          },
          "base64",
          200,
          200
        );
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    if (isMobile) {
      if (rightPartShownItem !== "" && leftPartShownItem !== "1")
        dispatch(setLeftPartItem(null));
    }
    if (!isMobile && leftPartShownItem === null) dispatch(setLeftPartItem("1"));

    return () => {
      dispatch(setLeftPartItem("1"));
      dispatch(setMiddlepartItem(null));
      dispatch(setRightPartItem(null));
      dispatch(clearAction());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (client) {
      setImage(client?.profile_picture);
      if (!hasNotifications) setHasNotifications(client?.notifications_enabled);
      if (!hasTwoFactorAuth)
        setHasTwoFactorAuth(client?.two_factor_auth_enabled);
    } else {
      dispatch(getClientBegin());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  useEffect(() => {
    if (stepParam === "password-changed" || stepParam === "passcode-changed") {
      dispatch(confirmPasswordChangeBegin());
      if (isMobile) {
        dispatch(setLeftPartItem("1"));
      } else {
        dispatch(
          setMiddlepartItem(<CredentialsChangeSuccess type={stepParam} />)
        );
        // dispatch(setMiddlepartItem(<ChangeLoginCredentials />));
        // dispatch(setRightPartItem(<PasswordChangeSuccess />));
      }
      searchParams.delete("step");
      setSearchParams(searchParams);
    } else if (stepParam === "email-confirm") {
      StorageService.deleteCookie("shouldLoginByToken");
      dispatch(
        confirmChangeEmailBegin({
          token: tokenParam,
          email: decodeURI(emailParam.replace(" ", "+")),
        })
      );
      if (isMobile) {
        dispatch(setLeftPartItem("1"));
      } else {
        dispatch(setMiddlepartItem(<ChangeLoginCredentials />));
        dispatch(setRightPartItem(<AddEmailSuccess />));
      }
      searchParams.delete("step");
      setSearchParams(searchParams);
    } else if (stepParam === "confirm-add-email") {
      dispatch(confirmAddEmailBegin());
      if (isMobile) {
        dispatch(setLeftPartItem(<AddEmailSuccess />));
      } else {
        dispatch(setMiddlepartItem(<PersonalDetails />));
        dispatch(setRightPartItem(<AddEmailSuccess />));
      }
    } else if (stepParam) {
      if (isMobile) {
        dispatch(setLeftPartItem(<PersonalDetails />));
      } else dispatch(setMiddlepartItem(<PersonalDetails />));
    }
  }, [stepParam]);

  useEffect(() => {
    dispatch(getReferralCodeBegin());
  }, []);

  useEffect(() => {
    if (!leftPartShownItem) dispatch(setLeftPartItem("1"));
  }, [leftPartShownItem]);

  return (
    <>
      <div className={styles.profilePageContent}>
        <div
          className={styles.leftContainer}
          style={
            rightPartShownItem
              ? { width: "20%" }
              : { width: "35%", minWidth: 300 }
          }
        >
          {isMobile ? (
            leftPartShownItem === "1" ? (
              <div className={styles.upperContainer}>
                <div
                  className={styles.profilePic}
                  onClick={() => onChangeProfilePhotoClick()}
                >
                  <input
                    type="file"
                    id="file"
                    ref={inputFile}
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                  />
                  {client?.profile_picture ? (
                    <img src={image} className={styles.picture} alt="Profile" />
                  ) : (
                    <div
                      className={styles.picture}
                      style={{
                        backgroundColor: getDefaultColour(defaultColour),
                      }}
                    >
                      <span>
                        {getInitialsFromName(
                          client?.firstname + " " + client?.lastname
                        )}
                      </span>
                    </div>
                  )}
                  <div className={styles.cameraIcon}>
                    <CameraIcon color={getDefaultColour(defaultColour)} />
                  </div>
                </div>
                <div
                  className={styles.profileInfo}
                  style={{ color: getDefaultColour(defaultColour) }}
                >
                  <span className="reg-400">
                    {client?.firstname
                      ? client?.firstname + " " + client?.lastname
                      : client?.country_phone
                      ? translations("label.phone")
                      : translations("label.name")}
                  </span>
                  <span className="reg-400">
                    {client?.country_phone && client?.phone
                      ? client?.country_phone + " " + client?.phone
                      : ""}
                  </span>
                  <span className="reg-400">{client?.email} </span>
                </div>
              </div>
            ) : null
          ) : (
            <div className={styles.upperContainer}>
              {client?.profile_picture ? (
                <img src={image} className={styles.picture} alt="Profile" />
              ) : (
                <div
                  className={styles.picture}
                  style={{ backgroundColor: getDefaultColour(defaultColour) }}
                >
                  <span>
                    {getInitialsFromName(
                      client?.firstname + " " + client?.lastname
                    )}
                  </span>
                </div>
              )}
              <div>
                <div className={styles.name}>
                  <span className="reg-400">
                    {client?.firstname
                      ? client?.firstname + " " + client?.lastname
                      : client?.country_phone
                      ? client?.country_phone + " " + client?.phone
                      : translations("label.name")}
                  </span>
                </div>

                <div
                  onClick={() => onChangeProfilePhotoClick()}
                  style={{ maxWidth: 200 }}
                >
                  <input
                    type="file"
                    id="file"
                    accept="image/*"
                    ref={inputFile}
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                  />
                  <span
                    className={styles.btn}
                    style={{ color: getDefaultColour(defaultColour) }}
                  >
                    {translations("label.change_profile_photo")}
                  </span>
                </div>
              </div>
            </div>
          )}

          {leftPartShownItem === "1" ? (
            <>
              {/* Login Method */}
              <div className={styles.loginMethod}>
                <h1>{translations("label.credentials")}</h1>
                {profileOperations.loginMethod.map((item) => {
                  if (item.id !== 0) {
                    return (
                      <div
                        key={item.id}
                        className={styles.operation}
                        onClick={() => item.onClick()}
                      >
                        <div
                          className={styles.label}
                          style={{ color: getDefaultColour(defaultColour) }}
                        >
                          {translations(item.label)}
                        </div>
                        <div className={styles.clickable}>{item.component}</div>
                      </div>
                    );
                  }
                })}
              </div>

              {/* Personal Informations */}
              <div className={styles.personalInformation}>
                <h1>{translations("label.personal_information")}</h1>
                {profileOperations.personalInformations.map((item) => {
                  return (
                    <div
                      key={item.id}
                      className={styles.operation}
                      onClick={() => item.onClick()}
                    >
                      <div
                        className={styles.label}
                        style={{ color: getDefaultColour(defaultColour) }}
                      >
                        {translations(item.label)}
                      </div>
                      <div className={styles.clickable}>{item.component}</div>
                    </div>
                  );
                })}
              </div>

              {/* Settings */}
              <div className={styles.settings}>
                <h1>{translations("label.settings")}</h1>
                {profileOperations.settings.map((item) => {
                  return (
                    <div
                      key={item.id}
                      className={styles.operation}
                      onClick={() => item.onClick()}
                    >
                      <div
                        className={styles.label}
                        style={{ color: getDefaultColour(defaultColour) }}
                      >
                        {translations(item.label)}
                      </div>
                      <div className={styles.clickable}>{item.component}</div>
                    </div>
                  );
                })}
              </div>

              {/* Legal Informations */}
              <div className={styles.legalInformation}>
                <h1>{translations("label.legal_information")}</h1>
                {profileOperations.legalInformation.map((item) => {
                  return (
                    <div
                      key={item.id}
                      className={styles.operation}
                      onClick={() => item.onClick()}
                    >
                      <div
                        className={styles.label}
                        style={{ color: getDefaultColour(defaultColour) }}
                      >
                        {translations(item.label)}
                      </div>
                      <div className={styles.clickable}>{item.component}</div>
                    </div>
                  );
                })}
              </div>
              {/* {isMobile ? (
                <div
                  className={styles.logoutButton}
                  onClick={() => handleLogout()}
                >
                  <LogoutIcon color={getDefaultColour(defaultColour)} />
                </div>
              ) : null} */}
            </>
          ) : (
            leftPartShownItem
          )}
        </div>
        <div
          className={styles.middleContainer}
          style={!middlePartShownItem ? {} : { width: "40%", padding: 30 }}
        >
          {!rightPartShownItem ? (
            <div
              className={styles.previousBtn}
              onClick={() => dispatch(setMiddlepartItem(null))}
            >
              <PreviousIcon color={getDefaultColour(defaultColour)} />
            </div>
          ) : null}
          {middlePartShownItem}
        </div>
        <div
          className={styles.rightContainer}
          style={!rightPartShownItem ? {} : { width: "40%", padding: 20 }}
        >
          <div
            className={styles.previousBtn}
            onClick={() => dispatch(setRightPartItem(null))}
          >
            <PreviousIcon color={getDefaultColour(defaultColour)} />
          </div>
          {rightPartShownItem}
        </div>
      </div>
    </>
  );
}
