import {
  ILikeOrDislike,
  IVoteUnsavedChat,
} from "types/reducers/homefeed.reducer.type";
import api from "./api";

export const callAIAsisstant = (question: string) => {
  return api.post("/chat/ask-dino", { message: question });
};

export const getStreamFeed = (data: {
  isLoggedIn: boolean;
  lang: string;
  search: string;
  filters?: any[];
}) => {
  if (data.isLoggedIn) {
    return api.post(
      `/cards/${data.search.length > 1 ? data.search : ","}/${data.lang}`,
      { filters: data.filters }
    );
  } else return api.get(`/cards/offline-cards/${data.lang}`);
};

export const submitStreamPoll = (data: { card_id: number; answer: string }) => {
  return api.post("/cards/submit/education/poll", {
    card_id: data.card_id,
    answer: data.answer,
  });
};

export const saveAsisstantChatToStream = (data: {
  question: string;
  answer: string;
}) => {
  return api.post("/cards/dino/save/chat", {
    question: data.question,
    answer: data.answer,
  });
};

export const saveMomentsProfile = (
  data: {
    question: string;
    answer: string;
  }[]
) => {
  return api.post("/cards/taste/preferences/submit/moments", data);
};

export const saveGeneralTaste = (
  data: {
    question: string;
    answer: string;
  }[]
) => {
  return api.post("/cards/taste/preferences/submit/general/flavours", data);
};

export const getTastePreferences = () => {
  return api.get("/cards/moments/flavours");
};

export const gertCardCategories = () => {
  return api.get("/cards/content/categories");
};

export const likeOrDislikeChat = (data: ILikeOrDislike) => {
  return api.put("/cards/vote", data);
};

export const voteUnsavedChat = (data: IVoteUnsavedChat) => {
  return api.put("/cards/chat/vote", data);
};

export const getCardById = (id: number) => {
  return api.get(`/cards/chat-card/${id}`);
};

export const submitProsAndCons = (
  data: {
    yes: string[];
    no: string[];
  }[]
) => {
  return api.post("/cards/submit/client/taste/preferences/pros/cons", data);
};
