import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import styles from "./Journal.module.scss";
import { useEffect } from "react";
import { getDefaultColour, modifyHomeFiltersForDate } from "utils/utils";
import Orders from "./MyOrders/Orders.page";
import StorageService from "services/storage.service";
import { getJournalCategoriesBegin, getJournalDataBegin } from "./actions";
import { getTastePreferencesBegin } from "pages/Home/actions";

function Journal() {
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const { categories, filters } = useAppSelector(
    (state: GlobalIState) => state.PersonalLedgerReducer
  );

  const { tastePreferences } = useAppSelector(
    (state: GlobalIState) => state.HomeFeedReducer
  );
  const dispatch = useAppDispatch();
  const journalSearchCookie = () =>
    StorageService.getDataByKey("journalSearch");

  useEffect(() => {
    if (!tastePreferences) dispatch(getTastePreferencesBegin());
    if (!categories) dispatch(getJournalCategoriesBegin());
  }, [tastePreferences, categories]);

  useEffect(() => {
    dispatch(
      getJournalDataBegin({
        search: journalSearchCookie() ? journalSearchCookie() : "",
        filters:
          filters?.filter((item) => item?.value?.length >= 1)?.length === 0
            ? []
            : modifyHomeFiltersForDate(
                filters?.filter((item) => item?.value?.length >= 1)
              )?.filters,
      })
    );
  }, [filters]);

  return (
    <div
      className={styles.contentWrapper}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      <Orders />
    </div>
  );
}

export default Journal;
