import React, { useEffect, useState } from "react";
import styles from "./YesAndNoPreferences.module.scss";
import { useTranslation } from "react-i18next";
import { getDefaultColour } from "utils/utils";
import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import { useNavigate } from "react-router-dom";
import { CloseIconWhite, EditPencilIcon } from "assets/icons/icons";
import ButtonComponent from "components/Button/Button.component";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import {
  getTastePreferencesBegin,
  submitClientProsAndConsBegin,
} from "../actions";
import { SUBMIT_CLIENT_PROS_AND_CONS_SUCCCESS } from "../constants";
import { openToaster } from "App/actions";
import { Box, Modal } from "@mui/material";

const YesAndNoPreferences: React.FC = () => {
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const {
    action: { isLoading, actionName },
    tastePreferences,
  } = useAppSelector((state: GlobalIState) => state.HomeFeedReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();
  const isMobile = useCheckMobileScreen();
  const [activeStep, setActiveStep] = useState<"yes" | "no">("yes");
  const [storedSelections, setStoredSelections] = useState<string[]>([]);
  const [selectedYes, setSelectedYes] = useState<string[]>([]);
  const [selectedNo, setSelectedNo] = useState<string[]>([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [manualOptionValue, setManualOptionValue] = useState("");
  const [options, setOptions] = useState([
    translations("label.too_sour"),
    translations("label.too_light"),
    translations("label.too_sweet"),
    translations("label.too_tannic"),
    translations("label.too_alcoholic"),
    translations("label.too_watery"),
    translations("label.too_heavy"),
    translations("label.too_fruity"),
    translations("label.too_smoky"),
    translations("label.too_earthy"),
    translations("label.too_complex"),
    translations("label.lacks_freshness"),
  ]);

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: 24,
    outline: "none",
    width: isMobile ? "80%" : "30%",
    bgcolor: "transparent",
    borderRadius: 12,
  };

  const toggleSelection = (item: string) => {
    if (storedSelections.includes(item)) {
      setStoredSelections(storedSelections.filter((i) => i !== item));
    } else if (storedSelections.length < 3) {
      setStoredSelections([...storedSelections, item]);
    }
  };

  const handleNext = () => {
    if (activeStep === "yes") {
      setSelectedYes(storedSelections);
      setStoredSelections([]);
      setActiveStep("no");
    } else {
      setSelectedNo(storedSelections);
      dispatch(
        submitClientProsAndConsBegin({
          yes: selectedYes,
          no: storedSelections,
        })
      );
    }
  };

  const handleSaveManualField = () => {
    setOptions([...options, manualOptionValue]);

    setTimeout(() => {
      setManualOptionValue("");
      setIsPopupOpen(false);
    }, 100);
  };

  useEffect(() => {
    if (!tastePreferences) dispatch(getTastePreferencesBegin());
  }, [tastePreferences]);

  useEffect(() => {
    if (actionName === SUBMIT_CLIENT_PROS_AND_CONS_SUCCCESS) {
      dispatch(getTastePreferencesBegin());
      dispatch(
        openToaster(
          "SUCCESS",
          translations("label.your_statemens_stored_successfully")
        )
      );
      navigate("/taste-and-preferences/profile");
    }
  }, [actionName]);

  return (
    <div
      className={styles.wrapper}
      style={
        { "--color": getDefaultColour(defaultColour) } as React.CSSProperties
      }
    >
      <div className={styles.card}>
        <div className={styles.header}>
          <div
            className={styles.closeIcon}
            onClick={() => navigate("/taste-and-preferences/profile")}
          >
            <CloseIconWhite />
          </div>
          <div>
            <h3>{translations("label.choose_3_statements")}</h3>
          </div>
          <div></div>
        </div>
        <div className={styles.content}>
          <h2>
            {translations("label.tell_us_what_you")}{" "}
            <b style={{ color: activeStep === "yes" ? "#49bd36" : "#fe730d" }}>
              {activeStep === "yes"
                ? translations("label.really_like")
                : translations("label.dont_like")}
            </b>{" "}
            {translations("label.in_a_wine?")}
          </h2>

          <span className={styles.description}>
            {activeStep === "yes"
              ? translations("label.i_like_a_wine_that_is")
              : translations("label.i_dont_like_a_wine_that_is")}
          </span>

          <div className={styles.selectOptions}>
            {options.map((item, index) => (
              <div
                key={index}
                className={`${styles.option} ${
                  storedSelections.includes(item)
                    ? activeStep === "no"
                      ? styles.noSelected
                      : styles.yesSelected
                    : ""
                }`}
                onClick={() => toggleSelection(item)}
              >
                {item}

                {[12, 13].includes(index) ? (
                  <div
                    className={styles.removeOption}
                    onClick={() => {
                      setOptions((prevOptions) => {
                        const newOptions = [...prevOptions];
                        newOptions.splice(index, 1);
                        return newOptions;
                      });
                    }}
                  >
                    -
                  </div>
                ) : null}
              </div>
            ))}
            {options?.length !== 14 ? (
              <div
                className={styles.manualOption}
                onClick={() => setIsPopupOpen(true)}
              >
                <EditPencilIcon color="#fff" />
              </div>
            ) : null}
          </div>
        </div>

        <div className={styles.mainButton}>
          <ButtonComponent
            isLoading={isLoading}
            label={translations("button.next")}
            class={"btn btn-primary"}
            disabled={storedSelections?.length < 3}
            handleClick={handleNext}
          />
        </div>
      </div>
      <Modal
        open={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <div className={styles.addFieldContainer}>
            <div className={styles.popupHeader}>
              <div
                className={styles.closeIcon}
                onClick={() => setIsPopupOpen(false)}
              >
                <CloseIconWhite color="#000" />
              </div>
            </div>

            <div className={styles.content}>
              <div>
                <span>
                  {translations(
                    activeStep === "yes"
                      ? "label.i_like_a_wine_that_is"
                      : "label.i_dont_like_a_wine_that_is"
                  )}
                </span>
                <input
                  type="text"
                  value={manualOptionValue}
                  onChange={(e) => setManualOptionValue(e.target.value)}
                />
              </div>
              <ButtonComponent
                isLoading={isLoading}
                label={translations("button.save")}
                class={"btn btn-primary"}
                disabled={!manualOptionValue?.length}
                handleClick={handleSaveManualField}
              />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default YesAndNoPreferences;
