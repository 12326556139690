import { Navigate, Outlet, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import { confirmPasswordChangeBegin } from "pages/Profile/actions";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import {
  setLeftPartItem,
  setMiddlepartItem,
  setRightPartItem,
} from "App/actions";
import ChangeLoginCredentials from "pages/Profile/ChangeLoginCredentials/ChangeLoginCredentials.page";

import StorageService from "services/storage.service";
import { logInByToken } from "pages/Authentication/actions";
import CredentialsChangeSuccess from "pages/Profile/ChangePassword/CredentialsChangeSuccess/CredentialsChangeSuccess.page";

export default function ProtectedRoutesMiddleware() {
  const dispatch = useAppDispatch();
  const isMobile = useCheckMobileScreen();
  const { isLoggedIn } = useAppSelector(
    (state: GlobalIState) => state.authenticationReducer
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const stepParam = searchParams.get("step");
  const tokenParam = searchParams.get("token");
  const authParam = searchParams.get("auth");
  const shouldLoginByTokenCookie =
    StorageService.getDataByKey("shouldLoginByToken");

  useEffect(() => {
    if (stepParam === "password-changed" || stepParam === "passcode-changed") {
      dispatch(confirmPasswordChangeBegin());

      if (isMobile) {
        dispatch(setLeftPartItem("1"));
      } else {
        dispatch(
          setMiddlepartItem(<CredentialsChangeSuccess type={stepParam} />)
        );
        // dispatch(setMiddlepartItem(<ChangeLoginCredentials />));
        // dispatch(setRightPartItem(<PasswordChangeSuccess />));
      }
      searchParams.delete("step");
      setSearchParams(searchParams);
    }
  }, [stepParam]);

  useEffect(() => {
    if (stepParam === "email-confirm") {
      dispatch(logInByToken(authParam));
    }
  }, []);

  return (
    <>
      {isLoggedIn || shouldLoginByTokenCookie ? (
        <Outlet />
      ) : (
        <Navigate to="/home" state={{ protectedRoute: true }} />
      )}
    </>
  );
}
