import React, { useState } from "react";
import styles from "./TastePreferencesStep2.module.scss";
import { useTranslation } from "react-i18next";
import { getDefaultColour } from "utils/utils";
import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BackButtonIcon } from "assets/icons/icons";
import ButtonComponent from "components/Button/Button.component";

const TastePreferencesStep2: React.FC = () => {
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();
  const [searchParams] = useSearchParams();
  const redoParam = searchParams.get("redo");

  const handleContinue = () => {
    navigate(
      redoParam
        ? "/taste-and-preferences/moments?redo=y"
        : "/taste-and-preferences/moments"
    );
  };

  return (
    <div
      className={styles.wrapper}
      style={
        { "--color": getDefaultColour(defaultColour) } as React.CSSProperties
      }
    >
      <div className={styles.header}>
        <div className={styles.backButtonContainer}>
          <div onClick={() => navigate(-1)} className="pointer">
            <BackButtonIcon color={"#000"} />
          </div>
        </div>
      </div>

      <div className={styles.content}>
        <div className={styles.labels}>
          <h3>{translations("label.taste_preferences.step_2.title")}</h3>
          <span>
            {translations("label.taste_preferences.step_2.subtitle1")}
          </span>
          <br />
          <span>
            {translations("label.taste_preferences.step_2.subtitle2")}
          </span>
        </div>

        <ButtonComponent
          label={translations("label.continue")}
          class={"btn btn-primary"}
          handleClick={handleContinue}
        />
      </div>
    </div>
  );
};

export default TastePreferencesStep2;
