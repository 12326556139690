import dayjs from "dayjs";
import DesktopBg1 from "assets/images/bg-desktop1.png";
import DesktopBg2 from "assets/images/bg-desktop2.png";
import DesktopBg3 from "assets/images/bg-desktop3.jpg";
import properties from "properties";
import { IProduct } from "types/reducers/purchasing.reducer.type";
import { ITastings } from "types/reducers/personal-ledger.reducer.type";

export const env = properties.ENV;

export const toObject = (arr: any[]) =>
  arr.reduce(function (acc, cur, i) {
    acc[cur] = cur;
    return acc;
  }, {});

export const getCountrySelectOptions = (list: any) => {
  const result = list?.map((item: any) => {
    return {
      value: item.id,
      label: item.name,
    };
  });
  return result;
};

export const getCountriesSelectOptions = (list: any) => {
  const result = list?.map((item: any) => {
    return {
      value: item?.phone,
      label: `+${item?.phone} - ${item?.name}`,
    };
  });
  return result;
};

export const getSelectOptions = (list: any, addValueSameAsLabel?: boolean) => {
  let keys = Object.keys(list);
  const result = keys.map((item) => {
    return {
      value: addValueSameAsLabel ? list[item] : item,
      label: list[item],
    };
  });
  return result;
};

export const getTotalPrice = (array: any[]) =>
  array?.reduce((acc, val) => {
    acc += Number(val.price) * val.quantity;
    return acc;
  }, 0);

export const getTotalVatIncluded = (
  array: any[],
  deliveryPrice: number,
  membershipDiscount: number,
  availableCredits: number
) => {
  const totalPrice = array?.reduce((acc, val) => {
    return (
      acc + Number(val.unit_price ? val.unit_price : val.price) * val.quantity
    );
  }, 0);

  const finalAmount =
    ((totalPrice - membershipDiscount - availableCredits + deliveryPrice) /
      108.1) *
    8.1;

  return Math.max(finalAmount, 0);
};

export const getQuantity = (array: any[]) =>
  array?.reduce((acc, val) => {
    acc += val.quantity;
    return acc;
  }, 0);

export const hasUppercaseLetter = (str: string) => {
  return /[A-Z]/.test(str);
};

export const hasLowercaseLetter = (str: string) => {
  return /[a-z]/.test(str);
};

export const hasNumbers = (str: string) => {
  return /[0-9]/.test(str);
};

export const hasSymbols = (str: string) => {
  return /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(str);
};

export const hasEightCharacters = (str: string) => {
  return str.length >= 8;
};

export const isInvalidPassword = (pass1: string, pass2: string) => {
  if (
    pass1 !== pass2 ||
    !hasUppercaseLetter(pass1) ||
    !hasLowercaseLetter(pass1) ||
    !hasNumbers(pass1) ||
    !hasSymbols(pass1) ||
    !hasEightCharacters(pass1)
  )
    return true;
  else return false;
};

export const countUnmetConditions = (password: string) => {
  let count = 0;

  if (hasUppercaseLetter(password)) count++;
  if (hasLowercaseLetter(password)) count++;
  if (hasNumbers(password)) count++;
  if (hasSymbols(password)) count++;
  if (hasEightCharacters(password)) count++;

  return count.toString();
};

export const getDateForDatePicker = (input: string) => {
  let datePart = input.match(/\d+/g),
    year = datePart[0],
    month = datePart[1],
    day = datePart[2];
  let str = day + "/" + month + "/" + year;

  return dayjs(str)?.toString();
};

export const getDefaultBackground = (background_id: string) => {
  switch (background_id) {
    case "1":
      return DesktopBg1;
    case "2":
      return DesktopBg2;
    case "3":
      return DesktopBg3;
    default:
      return DesktopBg1;
  }
};

export const getDefaultColour = (colour: string) => {
  switch (colour) {
    case "#540A9D":
      return "#540A9D";
    case "#600F83":
      return "#600F83";
    case "#34054C":
      return "#34054C";
    default:
      return "#540A9D";
  }
};

export const getColorForSubMenu = (colour: string) => {
  switch (colour) {
    case "#600F83":
      return "#34054C";
    case "#3B6878":
      return "#203c46";
    case "#B3914C":
      return "#90753c";
    default:
      return "#34054C";
  }
};

export const getSalutation = (salutation: string) => {
  switch (salutation) {
    case "1":
      return "label.mr";
    case "2":
      return "label.mrs";
    case "3":
      return "label.ms";
    default:
      return null;
  }
};

export const checkSelectValueOnInit = (value) => {
  if (value === 0) return 1;
  else return value === null ? null : Number(value);
};

export const capitalizeFirstLetter = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1).toLocaleLowerCase();
};

export const getOnlyNumbersFromString = (value: string) =>
  Number(value.replace(/\D/g, ""));

export const getInitialsFromName = (name: string) =>
  name
    .split(" ")
    .map(function (item) {
      return item[0];
    })
    .join("");

export const getPageName = (url: string) => {
  switch (url) {
    case "/profile":
      return "Profile";
    case "/events":
      return "Events";
    case "/intvestments":
      return "Investments";
    case "/games":
      return "Gamification";
    case "/purchasing":
      return "Purchasing";
    case "/wallet":
      return "your_wallet";
    case "/cart":
      return "your_cart_details";
    case "/wallet/transactions":
      return "booked_transactions";
    case "/wallet/transactions/details":
      return "transaction_details";
    case "/bookmarks":
      return "your_bookmarks";
    default:
      return "";
  }
};

export const subtractHours = (date, hours) => {
  let newDate = new Date(date);
  newDate.setHours(newDate.getHours() - hours);

  return date;
};

export const getEndTime = (calendarEvent) => {
  return calendarEvent.endDate;
};

const MINUTE_IN_MS = 60 * 1000;

export const formatDateForCalendarUrl = (date: Date) => {
  return new Date(date).toISOString().replace(/-|:|\.\d+/g, "");
};
export const addMinutesToDate = (date: Date, minutes: number) => {
  return new Date(date.getTime() + minutes * MINUTE_IN_MS);
};
export const generateGoogleCalendarUrl = (calendarEvent) => {
  const startDate = formatDateForCalendarUrl(calendarEvent.startDate);
  const endDate = formatDateForCalendarUrl(getEndTime(calendarEvent));

  const encodedUrl = encodeURI(
    [
      "https://www.google.com/calendar/render",
      "?action=TEMPLATE",
      `&text=${calendarEvent.title || ""}`,
      `&dates=${startDate || ""}`,
      `/${endDate || ""}`,
      // TODO: append video appointment link to description
      `&details=${`\n` + `https://jointhecellar.com` || ""}`,
      `&location=${calendarEvent.location || ""}`,
      "&sprop=&sprop=name:",
    ].join("")
  );

  return encodedUrl;
};

// Generates ICS for Apple and Outlook calendars
export const generateIcsCalendarFile = (calendarEvent) => {
  const startDate = formatDateForCalendarUrl(calendarEvent.startDate);
  const endDate = formatDateForCalendarUrl(getEndTime(calendarEvent));

  const encodedUrl = encodeURI(
    `data:text/calendar;charset=utf8,${[
      "BEGIN:VCALENDAR",
      "VERSION:2.0",
      "BEGIN:VEVENT",
      `URL:${document.URL}`,
      `DTSTART:${startDate || ""}`,
      `DTEND:${endDate || ""}`,
      `SUMMARY:${calendarEvent.title || ""}`,
      // `DESCRIPTION:${calendarEvent.description || ""}`,
      `LOCATION:${calendarEvent.location || ""}`,
      "END:VEVENT",
      "END:VCALENDAR",
    ].join("\n")}`
  );

  return encodedUrl;
};

export const removeLastElementFromArray = (arr) => {
  return arr.slice(0, -1);
};

export const getNumberOfInvitations = (count: number) => {
  if (count > 0) {
    if (count >= 4) return 4;
    else if (count < 4) return count;
  } else return 0;
};

export const handleOnlyNumbersKeydown = (event) => {
  const keyCode = event.keyCode;

  if (
    (keyCode >= 48 && keyCode <= 57) || // Numeric keys
    (keyCode >= 96 && keyCode <= 105) || // Numpad keys
    keyCode === 8 || // Backspace
    keyCode === 9 || // Tab
    keyCode === 46 || // Delete
    keyCode === 37 || // Left arrow
    keyCode === 39 || // Right arrow
    keyCode === 190 || // Dot
    keyCode === 110 // Numpad dot
  ) {
  } else {
    event.preventDefault();
  }
};

export const checkPathsToHideMobileMenu = (pathname: string) =>
  [
    "/profile",
    "/auth",
    "/events",
    "/events/details",
    "/events/registered",
    "/investments/topup",
    "/investments/topup/bank-transfer",
    "/wallet",
    "/wallet/transactions",
    "/wallet/transactions/details",
    "/investments/wine-collection",
    "/investments/wine-collection/details",
    "/cart",
    "/cart/delivery",
    "/cart/user-info",
    "/purchasing/product",
    "/purchasing/payment",
    "/order/summary",
    "/blind-tasting",
    "/feedback-submitted",
    "/bookmarks",
    "/terms-of-use",
    "/privacy-policy",
    "/invite-referral",
    "/product/promotion",
    "/add-wine",
    "/bonus",
  ].includes(pathname);

export const checkPathsToShowTopBar = (pathname: string) =>
  [
    "/purchasing",
    "/games",
    "/investments",
    "/concierge",
    "/notifications",
  ].includes(pathname);

export const checkPathsToShowBalanceAndNotifications = (pathname: string) =>
  [
    "/purchasing",
    "/games",
    "/investments",
    "/investments/topup",
    "/investments/topup/bank-transfer",
    "/concierge",
    "/notifications",
    "/journal",
  ].includes(pathname);

export const checkPathsToShowBackButton = (pathname: string) =>
  [
    "/events",
    "/wallet",
    "/wallet/transactions",
    "/wallet/transactions/details",
    "/investments/wine-collection",
    "/investments/wine-collection/details",
    "/cart",
    "/cart/delivery",
    "/cart/user-info",
    "/bookmarks",
    "/terms-of-use",
    "/privacy-policy",
    "/invite-referral",
    "/add-wine",
  ].includes(pathname);

export const checkPathsToHideBackButton = (pathname: string) =>
  [
    "/profile",
    "/auth",
    "/events/details",
    "/events/registered",
    "/order/summary",
    "/purchasing/product",
    "/journal/ledger",
    "/journal/rate-and-taste",
    "/journal/product-details",
    "/blind-tasting",
    "/guest-signup",
    "/purchasing/payment",
    "/bonus",
    "/membership/how-it-works",
  ].includes(pathname);

export const checkPathsToShowPageNameNextToBackButton = (pathname: string) =>
  [
    "/events",
    "/wallet",
    "/wallet/transactions",
    "/wallet/transactions/details",
    "/bookmarks",
    "/cart",
  ].includes(pathname);

export const isMainPage = (pathname: string) =>
  [
    "/purchasing",
    "/investments",
    "/journal",
    "/wallet",
    "/profile",
    "/membership",
    "/games",
    "/games/info",
    "/games/question",
    "/games/fill-in-the-blank",
    "/games/true-or-false",
    "/games/audio-questions",
    "/games/pour-and-score",
    "/games/magic-match",
    "/games/match-the-pairs",
    "/home",
    "/chat-history",
  ].includes(pathname);

export const showCloseButton = (pathname: string) =>
  ["/forgot-passcode/email-sent", "/referral/share"].includes(pathname);

export const getOrdinalIndicator = (number) => {
  if (number >= 11) {
    return "th";
  } else {
    const lastDigit = number % 10;
    switch (lastDigit) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }
};

export const getArrayWithEachDayOfMonth = () => {
  const currentDate = new Date();
  const targetMonth = currentDate.getMonth();
  const targetYear = currentDate.getFullYear();

  const firstDayOfMonth = new Date(targetYear, targetMonth, 1);
  const lastDayOfMonth = new Date(targetYear, targetMonth + 1, 0);
  const resultArray = [];

  for (
    let date = firstDayOfMonth;
    date <= lastDayOfMonth;
    date.setDate(date.getDate() + 1)
  ) {
    const day = date.getDate();
    const ordinalIndicator = getOrdinalIndicator(day);

    const dayObject = {
      day: day,
      value: `${day}${ordinalIndicator}`,
    };

    resultArray.push(dayObject);
  }

  return resultArray;
};

export const formatPrice = (inputValue) => {
  if (inputValue) {
    inputValue = inputValue?.replace(/[^\d.]/g, "");

    const hasDot = inputValue?.includes(".");
    let [integerPart, decimalPart] = inputValue?.split(".");

    integerPart = integerPart.substring(0, integerPart?.length);
    decimalPart = decimalPart?.substring(0, 2) || "";

    const formattedIntegerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      "'"
    );

    const formattedValue = hasDot
      ? `${formattedIntegerPart}.${decimalPart}`
      : formattedIntegerPart;

    return formattedValue;
  } else return "0";
};

export const getCurrencyStr = (currency) => {
  switch (currency) {
    case "USD":
      return "$";
    case "EUR":
      return "€";
    case "GBP":
      return "£";
    case "CHF":
      return "CHF";
    default:
      return "CHF";
  }
};

export const orderEvents = (events) => {
  let highlightedEventIndex = 7;
  if (events <= 9) highlightedEventIndex = 3;
  const highlighted = events.filter((d) => d.is_highlighted);
  const notHighlighted = events.filter((d) => !d.is_highlighted);
  const allEventsInOrder = [];

  if (!highlighted.length) return events;

  for (let i = 0; i < events.length; i++) {
    if (i % highlightedEventIndex === 0 && highlighted.length) {
      allEventsInOrder.push(highlighted.shift());
    } else {
      allEventsInOrder.push(notHighlighted.shift());
    }
  }
  return allEventsInOrder;
};

export const getTransactionLabel = (type: string) => {
  if (type && (type === "TOP UP" || type === "PURCHASE")) {
    return ["label.payment_of", "label.successfully_proccessed"];
  } else if (type && type === "SUBSCRIPTION") {
    return ["label.payment_of", "label.successfully_set_up"];
  } else if (type && type === "REDUCTION") {
    return ["label.withdrawal_of", "label.successfully_submitted"];
  } else return [""];
};

export const updateProductQuantity = (
  id: string | number,
  action: string,
  cartItems: any[],
  allowZero?: boolean
) => {
  const specialIncrements = [
    6, 12, 24, 36, 48, 60, 84, 108, 132, 156, 180, 360, 540, 720, 900,
  ];

  return cartItems.map((el) => {
    if (el.id === id) {
      let quantity = el.quantity;
      let roseGlowId = env === "production" ? 729 : 9236;

      if (id == roseGlowId.toString()) {
        const currentIndex = specialIncrements.indexOf(quantity);

        if (action === "increment") {
          if (
            currentIndex !== -1 &&
            currentIndex < specialIncrements.length - 1
          ) {
            quantity = specialIncrements[currentIndex + 1];
          } else if (currentIndex === -1 && quantity < specialIncrements[0]) {
            quantity = specialIncrements[0];
          }
        } else if (action === "decrement") {
          if (currentIndex > 0) {
            quantity = specialIncrements[currentIndex - 1];
          } else if (allowZero && currentIndex === 0) {
            quantity = 0;
          }
        }
      } else {
        if (action === "increment") {
          quantity = quantity + 1;
        } else if (action === "decrement") {
          if (allowZero && quantity === 1) {
            quantity = 0;
          } else if (quantity > 1) {
            quantity = quantity - 1;
          }
        }
      }

      return {
        ...el,
        quantity: quantity,
        total: (el.price / 100) * quantity,
      };
    }
    return el;
  });
};

export const filterOutlookSimulationData = (data: any) => {
  const startMonth = 12;
  const increment = 12;
  const endMonth = 240;

  const filteredData = data?.filter((item) => {
    return (
      item.month >= startMonth &&
      item.month <= endMonth &&
      (item.month - startMonth) % increment === 0
    );
  });

  return filteredData;
};

export const getStakesLabel = (type) => {
  switch (type) {
    case "TOP UP":
      return "label.topup_stakes";
    case "SUBSCRIPTION":
      return "label.subscribe_stakes";
    case "PURCHASE":
      return "label.wine_purchase";
    case "MEDALS":
    case "BOOSTER":
      return "label.medals_assigned";
    default:
      return "label.topup_stakes";
  }
};

export const encodeDataToURL = (data) => {
  return Object.keys(data)
    .map((value) => `${value}=${encodeURIComponent(data[value])}`)
    .join("&");
};

export const getFilterValue = (name, data) => {
  return data?.filter((item) => item?.filter === name)[0]?.value;
};

export const getFilterOptions = (data, key) => {
  return data?.map((item) => item[key]);
};

export const extractNumbers = (str) => str?.match(/\d+/g)?.map(Number);

export const updateFiltersArray = (data) => {
  const priceFilterIndex = data?.findIndex((item) => item?.filter === "Price");

  if (priceFilterIndex !== -1) {
    const priceFilter = data[priceFilterIndex];
    const values = extractNumbers(priceFilter?.value?.join(","));
    const isBelow25 = priceFilter?.value.includes("Below 25 CHF");

    const minValues = Math.min(...values);
    const maxValues = Math.max(...values);

    const updatedPriceFilter = {
      ...priceFilter,
      value: [`${isBelow25 ? 0 : minValues}`, `${maxValues}`],
    };

    data[priceFilterIndex] =
      values.length === 1
        ? values[0] === 25
          ? { ...priceFilter, value: ["0", "25"] }
          : values[0] === 500
          ? { ...priceFilter, value: ["500", "1000"] }
          : updatedPriceFilter
        : updatedPriceFilter;
  }

  return data;
};

export const extractNumbersFromRange = (inputArray) => {
  if (inputArray.length !== 1) {
    console.error("Input array must have exactly one element.");
    return [];
  }

  const regexResult = inputArray[0].match(/(\d+)\s*-\s*(\d+)/);

  if (!regexResult || regexResult.length !== 3) {
    console.error("Invalid input format. Unable to extract numbers.");
    return [];
  }

  const numbersAsString = regexResult.slice(1);

  return numbersAsString;
};

export const isProductBookmarked = (array, id) => {
  for (let i = 0; i < array?.length; i++) {
    if (array[i]?.id === id) {
      return true;
    }
  }
  return false;
};

export const getFiltersSelectedCount = (filters) => {
  let totalCount = 0;

  filters?.forEach((item) => {
    totalCount += item.value.length;
  });

  return totalCount;
};

export const getGoogleUrl = (from: string) => {
  const rootUrl = `https://accounts.google.com/o/oauth2/auth`;

  const options = {
    redirect_uri: properties.GOOGLE_REDIRECT_URL as string,
    client_id: properties.GOOGLE_CLIENT_ID as string,
    access_type: "offline",
    response_type: "code",
    prompt: "consent",
    scope: [
      "https://www.googleapis.com/auth/userinfo.profile",
      "https://www.googleapis.com/auth/userinfo.email",
    ].join(" "),
    state: from,
  };

  const qs = new URLSearchParams(options);

  return `${rootUrl}?${qs.toString()}`;
};

export const getSortingFilters = () => {
  return [
    { value: "OrderByPriceLow", label: "label.lowest_price" },
    { value: "OrderByPriceHigh", label: "label.highest_price" },
    { value: "OrderByNameAZ", label: "label.name_az" },
    { value: "OrderByNameZA", label: "label.name_za" },
    { value: "OrderByYearLow", label: "label.year_asc" },
    { value: "OrderByYearHigh", label: "label.year_desc" },
  ];
};

export const reorderProducts = (products: IProduct[], isMobile: boolean) => {
  const normal_products = products?.filter((item) => !item?.is_special);
  const special_products = products?.filter((item) => item?.is_special);

  const allProducts: IProduct[] = [];

  if (special_products?.length === 0) return products;

  let specialIndex = 0;

  for (let i = 0; i < normal_products?.length + special_products?.length; i++) {
    if (i >= (isMobile ? 20 : 19)) {
      if ((i - (isMobile ? 20 : 19)) % (isMobile ? 21 : 20) === 0) {
        allProducts.push(special_products[specialIndex]);
        specialIndex = (specialIndex + 1) % special_products?.length;
      } else {
        const normalIndex =
          i - Math.floor(i / (isMobile ? 21 : 20)) - specialIndex;
        allProducts.push(normal_products[normalIndex]);
      }
    } else {
      const normalIndex = i;
      allProducts.push(normal_products[normalIndex]);
    }
  }

  return allProducts;
};

export const formatTextWithDots = (text: string, maxLength: number) =>
  text.length > maxLength ? text.substring(0, 25) + "..." : text;

export const getTastingColorOptions = () => {
  return [
    { order: 1, color: "#AD70D3" },
    { order: 2, color: "#7C45A8" },
    { order: 3, color: "#44186E" },
    { order: 4, color: "#270C4F" },
    { order: 5, color: "#130434" },
  ];
};

export const getClientTastingLabel = (tastings: ITastings[]) => {
  let labels = [];

  tastings?.forEach((obj) => {
    labels.push(`label.tasting_${obj.type}${obj.rating}`);
  });

  return labels;
};

export const checkIfHasItems = (obj: any) => {
  if (Object?.keys(obj)?.length === 0) return false;
  else return obj;
};

export const getValueByIndex = (index, state) => {
  const entries = Object.entries(state);
  if (index < 0 || index >= entries.length) {
    return undefined;
  }
  return entries[index][1];
};

export const checkZipCodesForExpressShipment = (code) => {
  const list = [
    "8700",
    "8702",
    "8703",
    "8704",
    "8706",
    "8707",
    "8708",
    "8712",
    "8634",
    "8618",
    "8126",
    "8132",
    "8117",
    "8606",
    "8124",
    "8617",
    "8610",
    "8604",
    "8344",
    "8625",
    "8627",
    "8340",
    "8630",
    "8620",
  ];

  return list.includes(code);
};

export const generateExpressShipmentTimeSlots = () => {
  const currentTime = dayjs();
  const twoHoursLater = currentTime.add(2, "hour");
  const startTime = dayjs().hour(15).minute(0);
  const endTime = dayjs().hour(20).minute(0);
  const slots: string[] = [];

  let slotTime = startTime;

  while (slotTime.isBefore(endTime)) {
    const nextSlot = slotTime.add(30, "minute");

    if (slotTime.isAfter(twoHoursLater)) {
      slots.push(`${slotTime.format("HH:mm")}-${nextSlot.format("HH:mm")}`);
    }

    slotTime = nextSlot;
  }

  slots.push("label.tomorrow_morning");

  return getSelectOptions(slots, true);
};

export const getNextTimeSlot = (): string => {
  const currentTime = dayjs();
  const twoHoursLater = currentTime.add(2, "hour");

  const roundedMinutes = Math.ceil(twoHoursLater.minute() / 30) * 30;
  const nextSlotStart = twoHoursLater.minute(roundedMinutes).second(0);

  if (nextSlotStart.minute() === 60) {
    nextSlotStart.add(1, "hour").minute(0);
  }

  const nextSlotEnd = nextSlotStart.add(30, "minute");

  return `${nextSlotStart.format("HH:mm")}-${nextSlotEnd.format("HH:mm")}`;
};

export const checkStringForNumbers = (str) => {
  return (str?.match(/\d/g) || [])?.length > 1;
};

export const isTimeBetweenShipment = () => {
  const currentTime = dayjs();
  const startTime = dayjs().hour(9).minute(0).second(0);
  const endTime = dayjs().hour(18).minute(0).second(0);

  // Check if the current day is a weekday (Monday to Friday)
  const isWeekday = currentTime.day() >= 1 && currentTime.day() <= 5;

  return (
    isWeekday && currentTime.isAfter(startTime) && currentTime.isBefore(endTime)
  );
};

export const shuffleArray = <T>(array: T[]): T[] => {
  const shuffled = [...array];
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
};

export const modifyHomeFiltersForDate = (data) => {
  const updatedFilters = data?.map((filter) => {
    if (filter.filter === "scheduleTime" && filter.value.length >= 2) {
      if (filter.value[1] === "--") {
        return {
          ...filter,
          value: [filter.value[0], filter.value[0]],
        };
      }
    }
    return filter;
  });

  return {
    filters: updatedFilters,
  };
};
