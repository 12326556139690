import { useTranslation } from "react-i18next";
import styles from "./SignupByPhone.module.scss";
import { PreviousIcon } from "assets/icons/icons";
import { useEffect, useState } from "react";
import CustomInput from "components/CustomInput/CustomInput.component";
import ButtonComponent from "components/Button/Button.component";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import { getCountriesSelectOptions, getDefaultColour } from "utils/utils";
import { openModal, openToaster } from "App/actions";
import { modalTypes } from "types/reducers/modal.reducer.type";
import StorageService from "services/storage.service";
import SelectComponent from "components/Select/Select.component";
import {
  fetchCountriesBegin,
  registerBegin,
} from "pages/Authentication/actions";
import { REGISTER_SUCCESS } from "pages/Authentication/constants";
import { LOGIN_DEVICE } from "utils/enums";

export default function SignupByPhone() {
  const { defaultColour, termsAndConditions, privacyPolicy } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const {
    action: { actionName, isLoading },
    countries,
  } = useAppSelector((state: GlobalIState) => state.authenticationReducer);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { t: translations } = useTranslation();
  const [state, setState] = useState({
    phoneNumber: "",
  });
  const [countryCode, setCountryCode] = useState("41");
  const referralCodeParam = searchParams.get("referral_code");
  const firstNameParam = searchParams.get("firstname");
  const lastNameParam = searchParams.get("lastname");
  const countriesFiltered = countries?.filter((item) => item?.is_visible);

  const handleChange = (e) => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handlePreviousButton = () => {
    navigate("/signup");
  };

  const getStoredLanguage = () => {
    return localStorage.getItem("lang");
  };

  const handleTermsAndConditionsPopup = () => {
    dispatch(
      openModal({
        content: (
          <div
            className={styles.shownText}
            dangerouslySetInnerHTML={{
              __html: termsAndConditions?.text,
            }}
          ></div>
        ),
        type: modalTypes.smInfo,
      })
    );
  };

  const handlePrivacyPolicyPopup = () => {
    dispatch(
      openModal({
        content: (
          <div
            className="shownText"
            dangerouslySetInnerHTML={{
              __html: privacyPolicy?.text,
            }}
          ></div>
        ),
        type: modalTypes.smInfo,
      })
    );
  };

  const handleSubmit = () => {
    if (state.phoneNumber.match(/^[0-9]+$/) === null) {
      dispatch(
        openToaster("ERROR", translations("error.phone_number.only_digits"))
      );
      return;
    }

    const data = {
      email: "",
      password: "",
      country_phone: state.phoneNumber !== "" ? "+" + countryCode : "",
      phone: state.phoneNumber,
      sign_up_method: "phone",
      language: getStoredLanguage(),
      referral_code: referralCodeParam ? referralCodeParam : "",
      is_passcode: true,
      signup_device: LOGIN_DEVICE.WEB_APP,
    };
    dispatch(registerBegin(data));
  };

  const onKeyDown = (event) => {
    if (event.code === "Space") event.preventDefault();
  };

  useEffect(() => {
    if (referralCodeParam) {
      StorageService.setKeyValue("referral_code", referralCodeParam);
    }

    if (actionName === REGISTER_SUCCESS) {
      navigate(
        `/signup/auth-sms?country=+${countryCode}&phone=${state.phoneNumber}`
      );
    }
  }, [referralCodeParam, actionName]);

  useEffect(() => {
    if (!countries) {
      dispatch(fetchCountriesBegin("withoutAuth"));
    }
  }, []);

  return (
    <div
      className={styles.container}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      <div className={styles.content}>
        <div className={styles.header}>
          <div
            className={styles.previousIcon}
            onClick={() => {
              handlePreviousButton();
            }}
          >
            <PreviousIcon color={getDefaultColour(defaultColour)} />
          </div>

          <div className={styles.link} onClick={() => navigate("/login/phone")}>
            <span>{translations("label.already_a_client")}</span>
            <a>{translations("button.log_in")}</a>
          </div>
        </div>
        <div className={styles.authContent}>
          <h1>{translations("label.signup")}</h1>

          {referralCodeParam ? (
            <span className={styles.invitedByLabel}>
              {translations(`label.invited_by`) +
                firstNameParam +
                " " +
                lastNameParam +
                translations("label.to_join_vinfinity")}
            </span>
          ) : null}

          <div>
            <div className={styles.phoneInputs}>
              <div
                className={styles.countryInput}
                style={
                  {
                    "--color": getDefaultColour(defaultColour),
                  } as React.CSSProperties
                }
                data-chosen-value={countryCode !== "" ? "+" + countryCode : ""}
              >
                <SelectComponent
                  name={"countryCode"}
                  options={getCountriesSelectOptions(countriesFiltered)}
                  value={countryCode}
                  onChange={(e) =>
                    setCountryCode(e.target.value.replace(/\D/g, ""))
                  }
                  label={translations("label.country")}
                />
              </div>
              <div className={styles.phoneInput}>
                <CustomInput
                  type="tel"
                  name="phoneNumber"
                  label={translations("label.phone_number")}
                  minLength={7}
                  maxLength={12}
                  value={state.phoneNumber}
                  onKeyDown={(e) => onKeyDown(e)}
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
          </div>

          <div className={styles.submitButton}>
            <ButtonComponent
              label={translations("button.sign_up")}
              class={"btn btn-primary"}
              isLoading={isLoading}
              handleClick={() => handleSubmit()}
            />
          </div>

          <div className={styles.colored}>
            <span>{translations("label.only_above_18")}</span>
          </div>

          <div className={styles.agreementHint}>
            <span>{translations("label.by_signing,you_agree_to_the")}</span>
            <span
              className={styles.link}
              style={
                {
                  "--color": getDefaultColour(defaultColour),
                } as React.CSSProperties
              }
              onClick={() => handleTermsAndConditionsPopup()}
            >
              {translations("label.terms_of_use")}
            </span>
            <span>{translations("label.and")}</span>
            <span
              className={styles.link}
              style={
                {
                  "--color": getDefaultColour(defaultColour),
                } as React.CSSProperties
              }
              onClick={() => handlePrivacyPolicyPopup()}
            >
              {translations("label.privacy_policy")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
